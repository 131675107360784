<template>
  <v-dialog
    v-model="confirmDialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    width="100%"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn text outlined color="primary" v-bind="attrs" v-on="on" style="margin-top: 5px;">
        Cash
        <v-chip
          v-if="booking.cashPayment.status === 'Pending'"
          class="ma-1"
          color="info"
          text-color="white"
          small
        >Pending</v-chip>
        <v-chip
          v-if="booking.cashPayment.status === 'Rejected'"
          class="ma-1"
          color="red"
          text-color="white"
          small
        >Rejected</v-chip>
      </v-btn>
    </template>
    <v-card style="padding:25px;">
      <v-alert
        outlined
        type="info"
        style="margin-bottom: 0px;"
        v-if="booking.cashPayment.id === 0"
      >You can request for cash payment option. Your booking will be confirmed once the request has been approved.</v-alert>
      <v-alert
        outlined
        type="info"
        style="margin-bottom: 0px;"
        v-if="booking.cashPayment.id !== 0 && booking.cashPayment.status === 'Pending'"
      >Request for cash payment option pending. Your booking will be confirmed once the request has been approved. You will be notified when the booking is confirmed.</v-alert>
      <v-alert
        outlined
        type="error"
        color="red"
        style="margin-bottom: 0px;"
        v-if="booking.cashPayment.id !== 0 && booking.cashPayment.status === 'Rejected'"
      >Request for cash payment option rejected. Please select a different payment option.</v-alert>
      <v-row
        v-if="booking.cashPayment.id === 0"
        style="margin-top: 24px; margin-bottom: 0px; justify-content:space-between"
      >
        <div></div>
        <div>
          <v-btn
            style="margin-right: 12px"
            color="primary"
            outlined
            :loading="loading"
            @click="request()"
          >Request</v-btn>
        </div>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  name: "CashPayment",
  props: {
    booking: Object,
    message: Function,
    errorMessage: Function,
    getBooking: Function,
  },
  data: () => ({
    confirmDialog: null,
    loading: false,
  }),
  methods: {
    decimalCurrency(number) {
      const small = number.toString()
      return (
        small.substring(0, small.length - 2) +
        "." +
        small.substring(small.length - 2)
      )
    },
    request() {
      this.loading = true
      const data = {
        bookingUUID: this.booking.uuid
      }
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/requestcashpayment`,
        method: "POST",
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message)
          this.getBooking()
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error)
          }
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>