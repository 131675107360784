<template>
  <v-dialog v-model="confirmDialog" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
    offset-y max-width="400px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="grey" icon v-bind="attrs" v-on="on" x-small>
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text style="padding-bottom:0px; padding-top:20px">Are you sure to delete the point
        {{ point.name }}?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text :loading="loading" @click="deletePoint()">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios"
export default {
  name: "DeletPoint",
  props: {
    point: Object,
    getLocations: Function,
    message: Function,
    errorMessage: Function,
  },
  data: () => ({
    confirmDialog: null,
    name: "",
    loading: false,
  }),
  methods: {
    deletePoint() {
      const data = {
        pointId: this.point.id,
      }
      this.loading = true
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/deletepoint`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message)
          this.getLocations()
          this.confirmDialog = false
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error)
          }
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>