<template>
  <v-dialog
    v-model="waitlistMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    width="100%"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" outlined small class="ml-2" v-bind="attrs" v-on="on">Waitlist</v-btn>
    </template>
    <v-card style="padding:30px">
      <v-row>
        <v-col class="noHorizontalPadding">
          <v-card width="100%" elevation="0" outlined>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-wrap">{{ from.name }} to {{ to.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ computedDate }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="noHorizontalPadding">
          <v-select
            outlined
            :items="['Morning', 'Noon', 'Afternoon']"
            label="Preferred Time"
            v-model="preferredTime"
            hide-details
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="noHorizontalPadding">
          <vue-tel-input
            :inputOptions="telInputOptions"
            defaultCountry="mv"
            @input="phoneInput"
            style="height:56px;font-size:16px;"
          ></vue-tel-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="noHorizontalPadding">
          <v-text-field outlined placeholder="Enter email address" v-model="email"></v-text-field>
        </v-col>
      </v-row>
      <v-row style="margin-top:-10px; justify-content:space-between">
        <div></div>
        <v-btn outlined color="primary" :loading="waitlisting" @click="waitlist">Waitlist</v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios"
import moment from "moment"

export default {
  name: "Waitlist",
  props: {
    from: Object,
    to: Object,
    date: String,
    computedDate: String,
    message: Function,
    errorMessage: Function,
  },
  data: () => ({
    waitlistMenu: null,
    telInputOptions: { placeholder: "Enter phone number" },
    preferredTime: null,
    phone: "",
    email: "",
    waitlisting: false,
  }),
  methods: {
    phoneInput(phone, phoneObject) {
      this.phone = phoneObject
    },
    waitlist() {
      if (!this.preferredTime) {
        this.errorMessage("No preferred time chosen.")
        return
      }
      if (!this.from || !this.to) {
        this.errorMessage("Both locations not selected.")
        return
      }
      const data = {
        preferredTime: this.preferredTime,
        fromLocationId: this.from.id,
        toLocationId: this.to.id,
        date: moment(this.date, "YYYY-MM-DD").startOf("day").toISOString(),
        phone: this.phone.number,
        phoneCountryCode: this.phone.countryCode,
        email: this.email,
      }
      this.waitlisting = true
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/createwaitlist`,
        method: "POST",
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message)
          this.preferredTime = null
          this.email = ""
          this.phone = ""
          this.waitlistMenu = false
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error)
          }
        })
        .finally(() => (this.waitlisting = false))
    },
  }
}
</script>

<style scoped>
.noHorizontalPadding {
  padding-left: 0px;
  padding-right: 0px;
}
.noVerticalPadding {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>