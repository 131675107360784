<template>
  <div>
    {{ from?.name }}
    <span v-if="pickupPoint" style="font-size: 80%"
      >({{ pickupPoint.name }})</span
    >
    to {{ to?.name }}
    <span v-if="dropoffPoint" style="font-size: 80%"
      >({{ dropoffPoint.name }})</span
    >
  </div>
</template>

<script>
export default {
  name: "ToFromLocation",
  props: {
    from: Object,
    to: Object,
    pickupPoint: Object,
    dropoffPoint: Object,
  },
};
</script>
