<template>
  <v-dialog
    v-model="confirmDialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    width="100%"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item-title v-bind="attrs" v-on="on" style="cursor: pointer"
        >Accept Request</v-list-item-title
      >
    </template>
    <v-card style="padding: 25px">
      <v-row>
        <v-col class="noHorizontalPadding">
          <v-select
            outlined
            :items="boats"
            label="Boat"
            item-text="name"
            v-model="boat"
            return-object
          >
            <template v-slot:item="{ item }">{{ item.name }}</template>
            <template v-slot:selection="{ item }">{{ item.name }}</template>
          </v-select>
        </v-col>
      </v-row>
      <v-row align="center" style="margin-top: -30px">
        <v-col cols="6" class="noHorizontalPadding">
          <v-text-field
            v-model="usd"
            label="USD Price"
            outlined
            type="number"
            suffix="USD"
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="noHorizontalPadding">
          <v-text-field
            v-model="mvr"
            label="MVR Price"
            outlined
            type="number"
            suffix="MVR"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row style="margin-top: -30px">
        <v-col cols="6" class="noHorizontalPadding">
          <!-- <v-dialog v-model="fromTimeModal" width="290px">
          <template v-slot:activator="{ on, attrs }">-->
          <v-text-field
            v-model="fromTime"
            label="Departure"
            prepend-inner-icon="mdi-clock-time-four-outline"
            readonly
            disabled
            outlined
            hide-details
          ></v-text-field>
          <!-- </template>
                                <v-time-picker
                                  v-if="fromTimeModal"
                                  v-model="fromTime"
                                  full-width
                                  format="24hr"
                                  @click:minute="fromTimeModal = false"
                                ></v-time-picker>
          </v-dialog>-->
        </v-col>
        <v-col cols="6" class="noHorizontalPadding">
          <v-dialog v-model="toTimeModal" width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="toTime"
                label="Arrival"
                prepend-inner-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                hide-details
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="toTimeModal"
              v-model="toTime"
              full-width
              format="24hr"
              @click:minute="toTimeModal = false"
            ></v-time-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          >Accepting this request will create a private trip and a booking. A
          notification will be sent to the customer with the booking link where
          they can complete the payment.</v-col
        >
      </v-row>
      <v-row
        style="
          margin-top: 24px;
          margin-bottom: 0px;
          justify-content: space-between;
        "
      >
        <div></div>
        <div>
          <v-btn
            style="margin-right: 12px"
            color="primary"
            outlined
            :loading="loading"
            @click="acceptPrivateTransfer()"
            >Accept Request</v-btn
          >
        </div>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "AcceptPrivateTransfer",
  props: {
    message: Function,
    errorMessage: Function,
    getPrivateTransfers: Function,
    getBookings: Function,
    getTrips: Function,
    boats: Array,
    privateTransfer: Object,
  },
  data: () => ({
    confirmDialog: null,
    loading: false,
    currency: "MVR",
    boat: null,
    fromTime: null,
    toTime: null,
    toTimeModal: null,
    usd: null,
    mvr: null,
  }),
  methods: {
    decimalCurrency(number) {
      const small = number.toString();
      return (
        small.substring(0, small.length - 2) +
        "." +
        small.substring(small.length - 2)
      );
    },
    acceptPrivateTransfer() {
      if (!this.mvr || !this.usd) {
        this.errorMessage("Please enter both MVR and USD prices.");
        return;
      }
      if (!this.boat) {
        this.errorMessage("No boat chosen.");
        return;
      }
      if (!this.fromTime || !this.toTime) {
        this.errorMessage("Both times not selected.");
        return;
      }
      if (
        moment(this.toTime, "HH:mm").isSameOrBefore(
          moment(this.fromTime, "HH:mm"),
          "minute"
        )
      ) {
        this.errorMessage("Arrival should be after departure.");
        return;
      }
      const day = moment(this.privateTransfer.fromTime).format("YYYY-MM-DD");
      const data = {
        privateTransferId: this.privateTransfer.id,
        boatId: this.boat.id,
        usd: Math.round(this.usd * 100) / 100,
        mvr: Math.round(this.mvr * 100) / 100,
        toTime: moment(
          `${day} ${this.toTime}`,
          "YYYY-MM-DD HH:mm"
        ).toISOString(),
      };
      this.loading = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/acceptprivatetransfer`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getPrivateTransfers();
          this.getBookings();
          this.getTrips();
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.fromTime = moment(this.privateTransfer.fromTime).format("HH:mm");
    this.toTime = moment(this.privateTransfer.fromTime)
      .add(30, "minute")
      .format("HH:mm");
  },
};
</script>
