<template>
  <div>
    <v-snackbar v-model="snackbar" top color="green">{{
      snackbarText
    }}</v-snackbar>
    <v-snackbar v-model="errorSnackbar" top color="red" outlined>{{
      errorSnackbarText
    }}</v-snackbar>
    <v-dialog max-width="400" :retain-focus="false" v-model="feebackDialog">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          v-bind="attrs"
          v-on="on"
          style="padding-left: 8px; padding-right: 8px"
          small
          class="hidden-md-and-up"
          color="primary"
          ><v-icon>mdi-reply</v-icon></v-btn
        >
        <v-btn
          text
          v-bind="attrs"
          v-on="on"
          style="padding-left: 8px; padding-right: 8px"
          class="hidden-sm-and-down"
        >
          Send Feedback</v-btn
        >
      </template>
      <v-card style="padding: 20px">
        <v-row>
          <v-col class="noHorizontalPadding">
            <v-textarea
              v-model="feedback"
              label="Feedback"
              outlined
              hide-details
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col></v-col>
          <v-col class="shrink">
            <v-btn
              outlined
              color="primary"
              :loading="sendingFeedback"
              @click="sendFeedback"
              >Send</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Dashboard",
  data: () => ({
    snackbar: false,
    snackbarText: "",
    errorSnackbar: false,
    errorSnackbarText: "",
    feedback: "",
    sendingFeedback: false,
    feebackDialog: null,
  }),
  methods: {
    message(message) {
      this.snackbarText = message;
      this.snackbar = true;
    },
    errorMessage(message) {
      this.errorSnackbarText = message;
      this.errorSnackbar = true;
      if (message === "Token expired.") {
        this.$store.dispatch("logout");
      }
    },
    sendFeedback() {
      if (this.feedback.trim() === "") {
        this.errorMessage("Please enter feedback before sending.");
        return;
      }
      this.sendingFeedback = true;
      const data = {
        feedback: this.feedback,
      };
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/sendfeedback`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.feebackDialog = false;
          this.feedback = "";
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.sendingFeedback = false;
        });
    },
  },
};
</script>
