<template>
  <v-dialog
    v-model="newUserMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    width="100%"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        outlined
        block
        large
        class="mb-2"
        v-bind="attrs"
        v-on="on"
      >Register User</v-btn>
    </template>
    <v-card style="padding:30px">
      <v-text-field v-model="username" label="Username" outlined></v-text-field>
      <v-text-field v-model="password" label="Password" type="password" outlined></v-text-field>
      <v-text-field v-model="passwordAgain" label="Reenter password" type="password" outlined></v-text-field>
      <v-text-field
        :value="phone"
        label="Phone number"
        outlined
        type="number"
        @input="onlyDigits"
        @keypress.enter="register()"
        autocomplete="off"
      ></v-text-field>
      <v-row style="margin-top:-10px; justify-content:space-between">
        <div></div>
        <v-btn outlined color="primary" :loading="loading" @click="register()">Register</v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios"
export default {
  name: "RegisterNewUser",
  props: {
    message: Function,
    errorMessage: Function,
    getUsers: Function,
  },
  data: () => ({
    newUserMenu: null,
    username: "",
    password: "",
    passwordAgain: "",
    phone: "",
    snackbar: false,
    snackbarText: "",
    errorSnackbar: false,
    errorSnackbarText: "",
    loading: false,
  }),
  methods: {
    onlyDigits(e) {
      this.phone = e.replace(/\D/g, "")
    },
    register() {
      this.phone = this.phone.replace(/\D/g, "")
      if (this.password !== this.passwordAgain) {
        this.errorMessage("Passwords do not match.")
        return
      }
      this.loading = true
      const data = {
        username: this.username,
        password: this.password,
        phone: this.phone,
      }
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/register`,
        method: "POST",
        data,
      })
        .then((resp) => {
          this.message(resp.data.message)
          this.username = ""
          this.password = ""
          this.passwordAgain = ""
          this.phone = ""
          this.newUserMenu = null
          this.getUsers()
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>