import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#3c8e92", // #E53935
        secondary: "#6200ea", // #FFCDD2
        accent: "#f0e7e5", // #3F51B5
        error: "#53b8d4",
      },
    },
  },
});

// Customers
// 3c8e92
// 2a291a
// 4e5335
// aeab9f
// Captain
// 53b8d4
// b9dfee
// 273a4a
// 575d62
// Admin
// f0e7e5
// 866146
// 9a7c75
// 94847c
