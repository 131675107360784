<template>
  <v-dialog
    v-model="confirmDialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    width="100%"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-if="inList">
        <v-list-item-title v-bind="attrs" v-on="on" style="cursor: pointer">
          Upload Payment Slip
        </v-list-item-title>
      </v-list-item>
      <v-btn
        v-else
        text
        outlined
        color="primary"
        v-bind="attrs"
        v-on="on"
        style="margin-top: 5px"
      >
        Bank Transfer
        <v-chip
          v-if="booking.transfer.status === 'Pending'"
          class="ma-1"
          color="info"
          text-color="white"
          small
        >
          Verifying</v-chip
        >
        <v-chip
          v-if="booking.transfer.status === 'Rejected'"
          class="ma-1"
          color="red"
          text-color="white"
          small
          >Rejected
        </v-chip>
      </v-btn>
    </template>
    <div style="max-height: 75vh">
      <v-card style="padding: 25px; padding-bottom: 1px">
        <v-alert
          outlined
          type="warning"
          color="orange"
          v-if="booking.isCashAllowed"
          >Cash payment currently selected for this booking. Uploading a new
          slip will change payment method to bank transfer and cancel the cash
          payment.</v-alert
        >
        <v-alert
          outlined
          type="info"
          v-if="
            booking.transfer.id !== 0 && booking.transfer.status === 'Pending'
          "
          >Payment verification in progress. You can change the attached payment
          slip in the meantime.</v-alert
        >
        <v-alert
          outlined
          type="error"
          color="red"
          v-if="
            booking.transfer.id !== 0 && booking.transfer.status === 'Rejected'
          "
          >Payment verification failed. Please upload a correct payment
          slip.</v-alert
        >
        <v-card elevation="0" outlined>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    min-width: 150px;
                  "
                >
                  <span style="font-weight: 600">Payment</span>
                  <span>{{ decimalCurrency(booking.priceInLaari) }} MVR</span>
                  <span>or</span>
                  <span>{{ decimalCurrency(booking.priceInCent) }} USD</span>
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <div style="width: 50px">MVR</div>
          <div>{{ getMVRAccount() }}</div>
          <v-btn @click="copyToClipboard('MVR')" color="primary" icon>
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </div>
        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <div style="width: 50px">USD</div>
          <div>{{ getUSDAccount() }}</div>
          <v-btn @click="copyToClipboard('USD')" color="primary" icon>
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </div>
        <div
          v-if="booking.transfer.id !== 0"
          style="display: flex; justify-content: center"
        >
          <a :href="imageUrl" target="_blank">
            <img
              :src="`${imageUrl}?${booking.transfer.updated_at}`"
              style="max-width: 100%; max-height: 300px; margin-top: 10px"
            />
          </a>
        </div>
        <v-file-input
          style="margin-top: 10px"
          label="Payment slip"
          outlined
          v-model="file"
          accept="image/*"
          placeholder="Select file"
          @change="upload()"
          :disabled="uploading"
          :loading="uploading"
        ></v-file-input>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "BankTransfer",
  props: {
    booking: Object,
    message: Function,
    errorMessage: Function,
    getBooking: Function,
    inList: Boolean,
  },
  data: () => ({
    confirmDialog: null,
    changeLoading: false,
    file: null,
    uploading: false,
    imageUrl: null,
    loadingTransfer: false,
  }),
  methods: {
    async copyToClipboard(currency) {
      const account =
        currency === "MVR"
          ? process.env.VUE_APP_ACCOUNT_MVR
          : process.env.VUE_APP_ACCOUNT_USD;
      try {
        await navigator.clipboard.writeText(account);
        this.message(`${currency} account number copied to clipboard.`);
      } catch ($e) {
        console.log("Cannot copy");
      }
    },
    getMVRAccount() {
      return process.env.VUE_APP_ACCOUNT_MVR;
    },
    getUSDAccount() {
      return process.env.VUE_APP_ACCOUNT_USD;
    },
    decimalCurrency(number) {
      const small = number.toString();
      return (
        small.substring(0, small.length - 2) +
        "." +
        small.substring(small.length - 2)
      );
    },
    upload() {
      if (!this.file) return;
      if (this.file.size > 2000000) {
        this.errorMessage("File size must be less than 2 MB.");
        this.file = null;
        return;
      }
      if (!this.file.type || this.file.type.substring(0, 5) !== "image") {
        this.errorMessage("Payment slip must be an image file.");
        this.file = null;
        return;
      }
      this.uploading = true;
      let formData = new FormData();
      formData.append("bookingUUID", this.booking.uuid);
      formData.append("file", this.file);
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/uploadpaymentslip`,
        headers: { Authorization: localStorage.getItem("Authorization") },
        method: "POST",
        data: formData,
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getBooking();
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
          this.file = null;
        })
        .finally(() => (this.uploading = false));
    },
  },
  mounted() {
    if (this.booking.transfer) {
      this.imageUrl = `${process.env.VUE_APP_BASE_URL ?? ""}/img/paymentslip/${
        this.booking.uuid
      }`;
    }
  },
  watch: {},
};
</script>
