<template>
  <v-container fluid fill-height>
    <v-row justify="center" align="center">
      <v-snackbar v-model="snackbar" top color="green">{{
        snackbarText
      }}</v-snackbar>
      <v-snackbar v-model="errorSnackbar" top color="red" outlined>{{
        errorSnackbarText
      }}</v-snackbar>
      <v-col sm="6" md="4" lg="3" xl="3">
        <Header></Header>
        <v-card>
          <div>
            <v-card-text style="padding-top: 20px; margin-top: 20px">
              <v-form>
                <v-text-field
                  v-model="username"
                  label="Username"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  label="Password"
                  type="password"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="passwordAgain"
                  label="Re-enter password"
                  type="password"
                  outlined
                ></v-text-field>
                <v-text-field
                  :value="phone"
                  label="Phone number"
                  outlined
                  type="number"
                  @input="onlyDigits"
                  @keypress.enter="register()"
                  autocomplete="off"
                ></v-text-field>
              </v-form>
              <v-btn
                block
                color="primary"
                class="py-6"
                depressed
                @click="register()"
                :loading="loading"
                style="margin-bottom: 20px"
                >Register</v-btn
              >
              <v-btn block color="primary" class="py-6" outlined to="/login"
                >Log in</v-btn
              >
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import Header from "../components/Header.vue";
export default {
  name: "Register",
  components: { Header },
  data: () => ({
    username: "",
    password: "",
    passwordAgain: "",
    phone: "",
    snackbar: false,
    snackbarText: "",
    errorSnackbar: false,
    errorSnackbarText: "",
    loading: false,
  }),
  methods: {
    message(message) {
      this.snackbarText = message;
      this.snackbar = true;
    },
    errorMessage(message) {
      this.errorSnackbarText = message;
      this.errorSnackbar = true;
      if (message === "Token expired.") {
        this.$store.dispatch("logout");
      }
    },
    onlyDigits(e) {
      this.phone = e.replace(/\D/g, "");
    },
    register() {
      this.phone = this.phone.replace(/\D/g, "");
      if (this.password !== this.passwordAgain) {
        this.errorMessage("Passwords do not match.");
        return;
      }
      this.loading = true;
      const data = {
        username: this.username,
        password: this.password,
        phone: this.phone,
      };
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/register`,
        method: "POST",
        data,
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.$router.push("/login");
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    if (this.$store.getters.isLoggedIn) {
      this.$router.push("/login");
    }
  },
};
</script>
