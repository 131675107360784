<template>
  <div>
    <div v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
        :size="25"
        :width="2"
      ></v-progress-circular>
    </div>
    <div v-if="booking" style="padding: 0.5rem">
      <v-btn
        outlined
        color="primary"
        style="width: 100%; flex-wrap: wrap; height: 50px"
        :href="`/booking/${uuid}`"
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          "
        >
          <div>{{ text }} Booking #{{ booking.refNo }}</div>
          <div>
            <v-chip small v-if="booking.isPaid" color="green">Paid</v-chip>
            <v-chip small v-if="booking.isAllowed" color="cyan"
              >Allowed{{
                booking.allowedType ? `(${booking.allowedType})` : ""
              }}</v-chip
            >
            <v-chip
              small
              v-if="
                booking.isCashAllowed && !booking.isPaid && !booking.isAllowed
              "
              color="pink lighten-3"
              >Cash On Arrival</v-chip
            >
            <div
              v-if="
                !booking.isPaid && !booking.isAllowed && !booking.isCashAllowed
              "
            >
              <v-chip
                small
                v-if="
                  booking.transfer.id !== 0 &&
                  booking.transfer.status === 'Pending'
                "
                color="orange"
                >Pending Verification</v-chip
              >
              <v-chip
                small
                v-else-if="
                  booking.cashPayment.id !== 0 &&
                  booking.cashPayment.status === 'Pending'
                "
                color="orange"
                >Pending Cash Option</v-chip
              >
              <v-chip small v-else-if="booking.isCancelled" color="white"
                >Cancelled</v-chip
              >
              <v-chip small v-else>Pending</v-chip>
            </div>
          </div>
        </div>
      </v-btn>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "LinkedBooking",
  props: {
    uuid: String,
    return: Boolean,
    message: Function,
    errorMessage: Function,
  },
  data: () => ({
    booking: null,
    loading: false,
  }),
  computed: {
    text() {
      return this.return ? "Return" : "Outbound";
    },
  },
  methods: {
    bookingSummary(allowedType) {
      this.loading = true;
      this.allowedType = allowedType;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/bookingsummary?uuid=${
          this.uuid
        }`,
        method: "GET",
      })
        .then((resp) => {
          this.booking = resp.data.booking;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.bookingSummary();
  },
};
</script>
