<template>
  <v-dialog v-model="confirmDialog" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
    offset-y max-width="400px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" icon v-bind="attrs" v-on="on" title="Edit point" x-small>
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card style="padding:30px 30px 0px 30px">
      <v-row>
        <v-col class="noHorizontalPadding">
          <v-text-field v-model="name" label="Point name" outlined hide-details>
          </v-text-field>
        </v-col>
      </v-row>
      <v-card-actions style="margin-right:-20px;padding-right:0px">
        <v-spacer></v-spacer>
        <v-btn color="primary" text :loading="loading" @click="edit()">Edit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios"
export default {
  name: "CreatePoint",
  props: {
    point: Object,
    getLocations: Function,
    message: Function,
    errorMessage: Function,
  },
  data: () => ({
    confirmDialog: null,
    name: "",
    loading: false,
  }),
  methods: {
    edit() {
      const data = {
        pointId: this.point.id,
        name: this.name,
      }
      this.loading = true
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/editpoint`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message)
          this.getLocations()
          this.confirmDialog = false
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error)
          }
        })
        .finally(() => (this.loading = false))
    },
  },
  mounted() {
    this.name = this.point.name
  }
}
</script>