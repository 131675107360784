<template>
  <v-dialog v-model="dialog" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y
    width="100%" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" class="ml-2" outlined small v-bind="attrs" v-on="on">Request Private Transfer</v-btn>
    </template>
    <v-card style="padding:20px">
      <div v-if="step === 1">
        <div style="margin-top: 10px;padding-bottom:10px; display: flex; align-items: center;">
          <div style="width: 45%;">
            <v-select outlined :items="availableFromLocations" label="From" v-model="from" item-text="name"
              return-object></v-select>
          </div>
          <div style="width: 10%;">
            <v-row justify="center" style="margin-bottom: 20px">
              <v-icon @click="swapLocations">mdi-swap-horizontal</v-icon>
            </v-row>
          </div>
          <div style="width: 45%;">
            <v-select outlined :items="availableToLocations" label="To" v-model="to" item-text="name" return-object>
            </v-select>
          </div>
        </div>
        <v-row style="margin-top:-30px">
          <v-col>
            <v-dialog v-model="dateDialog" :close-on-content-click="false" :nudge-right="40"
              transition="scale-transition" offset-y width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined :value="computedDate" label="Date" prepend-inner-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on" hide-details></v-text-field>
              </template>
              <v-date-picker v-model="date" @input="dateDialog = false" :allowed-dates="allowedDates"></v-date-picker>
            </v-dialog>
          </v-col>
          <v-col class="noHorizontalPadding">
            <v-dialog v-model="fromTimeModal" width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="fromTime" label="Time" prepend-inner-icon="mdi-clock-time-four-outline" readonly
                  v-bind="attrs" v-on="on" outlined hide-details></v-text-field>
              </template>
              <v-time-picker v-if="fromTimeModal" v-model="fromTime" full-width format="24hr"
                @click:minute="fromTimeModal = false"></v-time-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="noHorizontalPadding">
            <v-text-field outlined v-model="notes" label="Other details (optional)"
              placeholder="e.g. guesthouse/hotel that you are staying in, special requests"></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div v-if="step === 2">
        <v-card-text style="margin-bottom: 40px; padding: 0px;">
          <v-row v-for="ageGroup in ageGroups" :key="ageGroup.id"
            style="margin-left:0px; margin-right:0px; margin-top:20px;">
            <v-card width="100%" elevation="0" outlined>
              <v-card-text>
                <v-row align="center">
                  <v-col cols="3" align="center">
                    <v-icon large @click="ageGroupMinus(ageGroup)">mdi-minus</v-icon>
                  </v-col>
                  <v-col cols="4" align="center">
                    <p class="text-md-h4 text-lg-h4 text-xl-h4 text--primary text-h5" style="margin:auto;">{{
                        ageGroup.name
                    }}</p>
                  </v-col>
                  <v-col cols="2" align="center">
                    <p class="text-md-h4 text-lg-h4 text-xl-h4 text--primary text-h5" style="margin:auto;">{{
                        ageGroup.quantity
                    }}</p>
                  </v-col>
                  <v-col cols="3" align="center">
                    <v-icon large @click="ageGroupPlus(ageGroup)">mdi-plus</v-icon>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-row>
        </v-card-text>
      </div>
      <div v-if="step === 3">
        <v-card-text>
          Enter full name of customers (as in passport or national ID card).
          <v-row style="margin-left:0px; margin-right:0px; margin-top:20px; margin-bottom:20px;">
            <v-card width="100%" elevation="0">
              <v-text-field v-for="(customer, index) in customers" :key="index" outlined
                :label="`${customer.ageGroup.name} ${customer.groupIndex}`" v-model="customer.name" hide-details
                style="margin-bottom: 10px"></v-text-field>
            </v-card>
          </v-row>
        </v-card-text>
      </div>
      <div v-if="step === 4">
        <v-card-text>
          Enter phone number or email address.
          <v-row style="margin-left:0px; margin-right:0px; margin-top:20px; margin-bottom:30px;">
            <v-card width="100%" elevation="0">
              <vue-tel-input :inputOptions="telInputOptions" defaultCountry="mv" @input="phoneInput"
                :value="phoneDefault" style="height:56px;font-size:16px; margin-bottom:10px"></vue-tel-input>
              <v-text-field outlined placeholder="Email address" hide-details v-model="email"></v-text-field>
            </v-card>
          </v-row>
        </v-card-text>
      </div>
      <div v-if="step === 5">
        <v-alert outlined type="info" style="margin-bottom: 0px;">Request for private transfer has been sent. You will
          be notified by email or sms once it has been approved. You can then proceed with payment.</v-alert>
      </div>
      <div v-if="step !== 5" style="margin-top:-10px; display: flex; justify-content:space-between">
        <div>
          <v-btn outlined color="primary" v-if="step > 1" @click="back()">Back</v-btn>
        </div>
        <div>
          <v-btn v-if="step === 4" outlined color="primary" @click="request()">Request</v-btn>
          <v-btn v-else outlined color="primary" @click="next()">Next</v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment"
import axios from "axios"

export default {
  name: "RequestPrivateTransfer",
  props: {
    locations: Array,
    message: Function,
    errorMessage: Function,
    ageGroupsOriginal: Array,
    requireCustomerNames: Boolean,
  },
  data: () => ({
    dialog: null,
    from: null,
    to: null,
    date: moment().format("YYYY-MM-DD"),
    dateDialog: null,
    fromTimeModal: null,
    fromTime: null,
    requesting: false,
    step: 1,
    ageGroups: [],
    customers: [],
    phoneDefault: "",
    phone: "",
    telInputOptions: { placeholder: "Phone number" },
    email: "",
    notes: ""
  }),
  computed: {
    computedDate() {
      return this.date ? moment(this.date).format("D MMMM YYYY") : ""
    },
    availableFromLocations() {
      return this.locations.filter((loc) => this.to && this.to.id !== loc.id)
    },
    availableToLocations() {
      return this.locations.filter((loc) => this.from && this.from.id !== loc.id)
    },
  },
  methods: {
    swapLocations() {
      const temp = this.from
      this.from = this.to
      this.to = temp
    },
    allowedDates(date) {
      return moment(date, "YYYY-MM-DD").isSameOrAfter(moment(), "day")
    },
    next() {
      if (this.step === 1) {
        if (!this.date || !this.fromTime) {
          this.errorMessage("Select date and time.")
          return
        }
      }
      else if (this.step === 2) {
        this.ageGroups.forEach((group) => {
          for (let i = 0; i < group.quantity; i++) {
            this.customers.push({
              name: null,
              ageGroup: group,
              groupIndex: i + 1,
            })
          }
        })
        if (this.customers.length === 0) {
          this.errorMessage("Select at least one customer.")
          return
        }
        if (
          this.customers.filter((cust) => cust.ageGroup.occupiesSeat).length ===
          0
        ) {
          this.errorMessage("Select a customer that occupies a seat.")
          this.customers = []
          return
        }
        if (
          this.customers.filter((cust) => cust.ageGroup.id === 3).length >
          this.customers.filter((cust) => cust.ageGroup.id === 1).length
        ) {
          this.errorMessage("Each infant must have an accompanying adult.")
          this.customers = []
          return
        }
      } else if (this.step === 3) {
        let unfilledNames = false
        this.customers.forEach((cust) => {
          if (!cust.name || cust.name.trim() === "") {
            if (this.requireCustomerNames === false) {
              cust.name = `${cust.ageGroup.name} ${cust.groupIndex}`
            } else {
              unfilledNames = true
            }
          }
        })
        if (unfilledNames) {
          this.errorMessage("Please enter all customer names.")
          return
        }
      } else if (this.step == 4) {
        if (
          this.email.trim() === "" &&
          (this.phone === "" || this.phone?.formatted?.trim() === "")
        ) {
          this.errorMessage("Please enter at least one.")
          return
        }
        if (
          this.phone &&
          this.phone !== "" &&
          this.phone?.formatted?.trim() !== "" &&
          !this.phone.valid
        ) {
          this.errorMessage("Invalid phone number.")
          return
        }
        if (this.email.trim() !== "") {
          const validEmailRe = new RegExp(
            `([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+`
          )
          if (!validEmailRe.test(this.email.trim())) {
            this.errorMessage("Invalid email address.")
            return
          }
        }
      }
      this.step++
    },
    back() {
      this.step--
      if (this.step === 1) {
        this.ageGroups.forEach(
          (group) => (group.quantity = group.name === "Adult" ? 1 : 0)
        )
      } else if (this.step === 2) {
        this.customers = []
      }
    },
    phoneInput(phone, phoneObject) {
      this.phone = phoneObject
    },
    ageGroupMinus(ageGroup) {
      if (ageGroup.quantity > 0) {
        ageGroup.quantity--
      }
    },
    ageGroupPlus(ageGroup) {
      if (ageGroup.quantity < 100) {
        ageGroup.quantity++
      }
    },
    request() {
      if (!this.from || !this.to) {
        this.errorMessage("Both locations not selected.")
        return
      }
      const data = {
        fromLocationId: this.from.id,
        toLocationId: this.to.id,
        fromTime: moment(
          `${this.date} ${this.fromTime}`,
          "YYYY-MM-DD HH:mm"
        ),
        email: this.email,
        phone: this.phone.number,
        phoneCountryCode: this.phone.countryCode,
        notes: this.notes,
        customers: this.customers.map((cust) => ({
          name: cust.name,
          ageGroupId: cust.ageGroup.id,
        })),
      }
      data.fromTime = data.fromTime.toISOString()
      this.requesting = true
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/createprivatetransfer`,
        method: "POST",
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message)
          this.next()
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error)
          }
        })
        .finally(() => (this.requesting = false))
    },
  },
  mounted() {
    if (this.locations.length >= 2) {
      this.from = this.locations[0]
      this.to = this.locations[1]
    }

  },
  watch: {
    fromTime: {
      handler: function () {
        this.toTime = moment(
          `${this.date} ${this.fromTime}`,
          "YYYY-MM-DD HH:mm"
        )
          .add(30, "minutes")
          .format("HH:mm")
      },
    },
    dialog: {
      handler: function (val) {
        if (val) {
          this.step = 1
          this.ageGroups = JSON.parse(JSON.stringify(this.ageGroupsOriginal))
          this.customers = []
        }

      },
    },
  },
}
</script>

<style scoped>
.v-snack__wrap .v-snack__wrapper {
  min-width: none;
  max-width: none;
}
.vue-tel-input {
  border-color: #9e9e9e;
}
.vue-tel-input:focus-within {
  box-shadow: none;
  border-width: 2px;
  border-color: "primary";
}
