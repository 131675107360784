<template>
  <v-dialog v-model="visible" max-width="600" :retain-focus="false">
    <template v-slot:activator="{ on, attrs }">
      <div>
        <span
          v-if="originalSelectedNames"
          style="margin-right: 1rem; opacity: 0.7"
          >Seats: {{ originalSelectedNames }}</span
        >
        <v-btn
          v-if="
            !booking.trip.departed &&
            !booking.trip.ended &&
            isBeforeNow(booking.trip.fromTime) &&
            !booking.isCancelled &&
            (booking.isPaid || booking.isAllowed || booking.isCashAllowed)
          "
          color="primary"
          outlined
          small
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-seat-passenger</v-icon> Select seats
        </v-btn>
      </div>
    </template>
    <v-card style="padding: 30px">
      Seat selection
      <div
        :style="{
          display: 'flex',
          flexDirection: $vuetify.breakpoint.smAndDown ? 'column' : 'row',
          gap: '1rem',
          marginTop: '1rem',
          marginBottom: '1rem',
        }"
      >
        <v-text-field
          :value="bookedCount"
          label="Booked Seats"
          disabled
          type="number"
          hide-details
        />
        <v-text-field
          :value="availableCount"
          label="Remaing Seats"
          disabled
          type="number"
          hide-details
        />
        <v-text-field
          :value="selectedNames"
          :label="selected.length > 0 ? 'Selected Seats' : 'No seats selected'"
          disabled
          hide-details
        />
      </div>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <div
        v-if="rows"
        style="
          display: flex;
          justify-content: center;
          opacity: 0.3;
          font-weight: bold;
        "
      >
        FRONT
      </div>
      <div
        v-if="rows"
        style="display: flex; flex-direction: column; gap: 5px; overflow: auto"
      >
        <div
          v-for="row in rows"
          :key="row.no"
          style="display: flex; justify-content: space-around; gap: 5px"
        >
          <div
            v-for="column in row.columns"
            :key="column.no"
            :style="{
              'border-radius': '5px',
              border: column.active ? 'solid 1px grey' : undefined,
              padding: `2px`,
              width: '100%',
              maxWidth: '100px',
              maxHeight: '100px',
              aspectRatio: '1 / 1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: backgroundColor(column),
              cursor: color(column),
              color: column.active ? undefined : 'white',
            }"
            @click="cellHandle(column)"
          >
            {{ column.name }}
          </div>
        </div>
      </div>
      <div
        v-if="rows"
        style="
          display: flex;
          justify-content: center;
          opacity: 0.3;
          font-weight: bold;
          margin-bottom: 1rem;
        "
      >
        BACK
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          margin-top: 1rem;
          gap: 1rem;
          flex-wrap: wrap;
        "
      >
        <div style="display: flex; gap: 1rem; flex-wrap: wrap" v-if="rows">
          <v-btn color="primary" outlined @click="clear()">Clear</v-btn>
          <v-btn color="primary" outlined @click="visible = false">Close</v-btn>
        </div>
        <div v-else></div>
        <v-btn
          color="primary"
          @click="update"
          :loading="updating"
          :disabled="!changed || loading"
          >Select</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import { apiRequest, numberToColumn } from "../../helpers";

export default {
  name: "SeatSelection",
  props: {
    booking: Object,
    trip: Object,
    message: Function,
    errorMessage: Function,
    getBooking: Function,
  },
  data: () => ({
    visible: false,
    rows: null,
    loading: false,
    updating: false,
    selected: [],
    originalSelected: [],
  }),
  computed: {
    bookedCount() {
      let count = 0;
      for (const customer of this.booking.customers) {
        if (customer.ageGroup.occupiesSeat) count++;
      }
      return count;
    },
    availableCount() {
      return this.bookedCount - this.selected.length;
    },
    changed() {
      return JSON.stringify(this.rowsOriginal) !== JSON.stringify(this.rows);
    },
    selectedNames() {
      if (!this.selected || !this.rows) return "";
      const names = [];
      for (const s of this.selected) {
        for (const row of this.rows) {
          for (const col of row.columns) {
            if (s === col.id) {
              names.push(col.name);
            }
          }
        }
      }
      return names.join(", ");
    },
    originalSelectedNames() {
      if (!this.originalSelected || !this.rows) return "";
      const names = [];
      for (const s of this.originalSelected) {
        for (const row of this.rows) {
          for (const col of row.columns) {
            if (s === col.id) {
              names.push(col.name);
            }
          }
        }
      }
      return names.join(", ");
    },
  },
  methods: {
    backgroundColor(col) {
      if (col.active) {
        if (this.isOccupied(col)) {
          return "#FD8A8A";
        } else if (this.isSelected(col)) {
          return "#B4E4FF";
        } else return "#DFFFD8";
      } else return undefined;
    },
    color(col) {
      if (
        (col.active && !this.isOccupied(col) && this.availableCount > 0) ||
        this.isSelected(col)
      ) {
        return "pointer";
      }
      return undefined;
    },
    isBeforeNow(time) {
      return moment(moment()).isBefore(time);
    },
    numberToColumn(x) {
      return numberToColumn(x);
    },
    clear() {
      this.selected = [];
    },
    cellHandle(clickedCol) {
      if (!clickedCol.active || this.isOccupied(clickedCol)) return;
      for (const s of this.selected) {
        if (s === clickedCol.id) {
          this.selected = this.selected.filter((sel) => sel !== clickedCol.id);
          return;
        }
      }
      if (this.availableCount <= 0) return;
      this.selected.push(clickedCol.id);
    },
    isSelected(col) {
      for (const s of this.selected) {
        if (s === col.id) {
          return true;
        }
      }
      return false;
    },
    isOccupied(col) {
      for (const seat of this.trip.seats) {
        if (seat.boatLayoutColumnID === col.id) {
          if (seat.bookingId !== this.booking.id) return true;
        }
      }
      return false;
    },
    find() {
      const endpoint = `/api/gettripboatlayout?tripId=${this.trip.id}`;
      this.loading = true;
      const thenHandle = (resp) => {
        const rows = resp?.data?.rows;
        if (rows) {
          this.rows = rows;
        }
      };
      const catchHandle = (error) => {
        if (error.response) {
          this.errorMessage(error.response.data.error);
        }
      };
      const finallyHandle = () => {
        this.loading = false;
      };
      apiRequest({
        endpoint,
        method: "GET",
        thenHandle,
        catchHandle,
        finallyHandle,
      });
    },
    update() {
      const endpoint = `/api/selectseats`;
      const data = {
        bookingUuid: this.booking.uuid,
        tripId: this.trip.id,
        selected: this.selected,
      };
      this.updating = true;
      const thenHandle = (resp) => {
        this.message(resp.data.message);
        this.visible = false;
      };
      const catchHandle = (error) => {
        if (error.response) {
          this.errorMessage(error.response.data.error);
        }
      };
      const finallyHandle = () => {
        this.getBooking();
        this.updating = false;
        this.find();
      };
      apiRequest({
        endpoint,
        method: "POST",
        thenHandle,
        catchHandle,
        finallyHandle,
        data,
      });
    },
  },
  mounted() {
    this.find();
  },
  watch: {
    booking: {
      immediate: true,
      handler(value) {
        if (!value || !value.seats || value.seats.length === 0) return;
        const seatsOfTrip = value.seats.filter(
          (s) => s.tripId === this.trip.id
        );
        this.selected = seatsOfTrip.map((s) => s.boatLayoutColumnID);
        this.originalSelected = seatsOfTrip.map((s) => s.boatLayoutColumnID);
      },
    },
  },
};
</script>
