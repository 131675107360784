<template>
  <v-dialog
    v-model="confirmDialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    width="100%"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        outlined
        color="primary"
        v-bind="attrs"
        v-on="on"
        style="margin-top: 5px"
        >Card</v-btn
      >
    </template>
    <v-card style="padding: 25px">
      <v-list-item>
        <v-list-item-content style="padding-bottom: 10px">
          <v-list-item-title class="text-wrap"
            >Select currency</v-list-item-title
          >
          <v-radio-group
            v-model="paymentInUSD"
            style="margin-left: 10px; margin-bottom: 0px"
            hide-details
          >
            <v-radio
              v-if="stripeGatewayAllowed"
              key="1"
              :value="true"
              :label="`${decimalCurrency(booking.priceInCent)} USD`"
            ></v-radio>
            <v-radio
              v-if="bmlGatewayAllowed"
              key="2"
              :value="false"
              :label="`${decimalCurrency(booking.priceInLaari)} MVR`"
            ></v-radio>
          </v-radio-group>
        </v-list-item-content>
      </v-list-item>
      <v-alert outlined type="info" v-if="convenienceFee && paymentInUSD"
        >A processing fee of {{ decimalCurrency(convenienceFee) }} USD will be
        charged by our payment processor’.</v-alert
      >
      <v-row
        style="
          margin-top: 14px;
          margin-bottom: 0px;
          justify-content: space-between;
        "
      >
        <div></div>
        <div>
          <v-btn
            style="margin-right: 12px"
            color="primary"
            outlined
            @click="payment()"
            :loading="loading"
            >Proceed to {{ paymentInUSD ? "Stripe" : "BML" }} Gateway</v-btn
          >
        </div>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "CardPayment",
  props: {
    booking: Object,
    stripeFeeEstimate: Number,
    message: Function,
    errorMessage: Function,
    getBooking: Function,
  },
  data: () => ({
    confirmDialog: null,
    loading: false,
    paymentInUSD: false,
  }),
  computed: {
    bmlGatewayAllowed() {
      return process.env.VUE_APP_ALLOW_BML_GATEWAY === "true" ? true : false;
    },
    stripeGatewayAllowed() {
      return process.env.VUE_APP_ALLOW_STRIPE_GATEWAY === "true" ? true : false;
    },
    convenienceFee() {
      let f = 0;
      if (this.stripeFeeEstimate) {
        f = this.stripeFeeEstimate - this.booking.priceInCent;
      }
      return f;
    },
  },
  methods: {
    decimalCurrency(number) {
      return (number / 100).toLocaleString();
    },
    payment() {
      this.loading = true;
      const data = {
        uuid: this.booking.uuid,
        currency: this.paymentInUSD ? "USD" : "MVR",
      };
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/proceedpayment${
          this.paymentInUSD ? "stripe" : ""
        }`,
        method: "POST",
        data,
      })
        .then((resp) => {
          window.location.href = resp.data.shortUrl;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    if (this.stripeGatewayAllowed) {
      this.paymentInUSD = true;
    }
  },
};
</script>
