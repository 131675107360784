<template>
  <v-card>
    <v-card-text>
      <v-select outlined :items="['Current Month', 'Last 30 Days', 'All', 'Custom Range']" label="Date Range"
        v-model="datesSelection" hide-details style="margin-bottom: 10px;"></v-select>
      <v-row v-if="datesSelection === 'Custom Range'" style="margin-bottom: 10px;">
        <v-col style="padding-bottom: 0px; padding-right: 6px;">
          <v-dialog v-model="fromDialog" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
            offset-y width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field outlined :value="computedFrom" label="From" prepend-inner-icon="mdi-calendar" readonly
                v-bind="attrs" v-on="on" hide-details></v-text-field>
            </template>
            <v-date-picker v-model="from" @input="fromDialog = false" :allowed-dates="allowedFromDates"></v-date-picker>
          </v-dialog>
        </v-col>
        <v-col style="padding-bottom: 0px; padding-left: 6px;">
          <v-dialog v-model="toDialog" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
            offset-y width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field outlined :value="computedTo" label="To" prepend-inner-icon="mdi-calendar" readonly
                v-bind="attrs" v-on="on" hide-details></v-text-field>
            </template>
            <v-date-picker v-model="to" @input="toDialog = false" :allowed-dates="allowedToDates"></v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-progress-linear v-if="revenueReportLoading || tripReportLoading || bookingReportLoading" indeterminate
        color="primary"></v-progress-linear>
      <v-card v-if="revenueReport" flat outlined class="mb-2" ripple>
        <v-card-text>
          <v-row justify="center" align="center">
            <v-col style="padding-top: 0px; padding-bottom: 0px;">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left text-h6">Revenue</th>
                      <th class="text-left"></th>
                      <th class="text-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Card</td>
                      <td>MVR</td>
                      <td>{{ decimalCurrency(revenueReport.mvrtransaction) }}</td>
                    </tr>
                    <tr>
                      <td>Card</td>
                      <td>USD</td>
                      <td>{{ decimalCurrency(revenueReport.usdtransaction) }}</td>
                    </tr>
                    <tr>
                      <td>Transfer</td>
                      <td>MVR</td>
                      <td>{{ decimalCurrency(revenueReport.mvrtransfer) }}</td>
                    </tr>
                    <tr>
                      <td>Transfer</td>
                      <td>USD</td>
                      <td>{{ decimalCurrency(revenueReport.usdtransfer) }}</td>
                    </tr>
                    <tr>
                      <td>Cash</td>
                      <td>MVR</td>
                      <td>{{ decimalCurrency(revenueReport.mvrcash) }}</td>
                    </tr>
                    <tr>
                      <td>Cash</td>
                      <td>USD</td>
                      <td>{{ decimalCurrency(revenueReport.usdcash) }}</td>
                    </tr>
                    <tr>
                      <td>Credit</td>
                      <td>MVR</td>
                      <td>{{ decimalCurrency(revenueReport.mvrcredit) }}</td>
                    </tr>
                    <tr>
                      <td>Credit</td>
                      <td>USD</td>
                      <td>{{ decimalCurrency(revenueReport.usdcredit) }}</td>
                    </tr>
                    <tr>
                      <td>Pre-paid</td>
                      <td>MVR</td>
                      <td>{{ decimalCurrency(revenueReport.mvrprepaid) }}</td>
                    </tr>
                    <tr>
                      <td>Pre-paid</td>
                      <td>USD</td>
                      <td>{{ decimalCurrency(revenueReport.usdprepaid) }}</td>
                    </tr>
                    <tr class="font-weight-bold">
                      <td>Total</td>
                      <td>MVR</td>
                      <td>{{ decimalCurrency(revenueReport.mvrtotal) }}</td>
                    </tr>
                    <tr class="font-weight-bold">
                      <td>Total</td>
                      <td>USD</td>
                      <td>{{ decimalCurrency(revenueReport.usdtotal) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-if="tripReport" flat outlined class="mb-2" ripple>
        <!-- <v-progress-linear v-if="tripReportLoading" indeterminate color="primary"></v-progress-linear> -->
        <v-card-text>
          <v-row justify="center" align="center">
            <v-col style="padding-top: 0px; padding-bottom: 0px;">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left text-h6">Trips</th>
                      <th class="text-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Not departed</td>
                      <td>{{ tripReport.notdeparted }}</td>
                    </tr>
                    <tr>
                      <td>In transit</td>
                      <td>{{ tripReport.departed }}</td>
                    </tr>
                    <tr>
                      <td>Completed</td>
                      <td>{{ tripReport.ended }}</td>
                    </tr>
                    <tr class="font-weight-bold">
                      <td>Total</td>
                      <td>{{ tripReport.total }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-if="bookingReport" flat outlined class="mb-2" ripple>
        <!-- <v-progress-linear v-if="bookingReportLoading" indeterminate color="primary"></v-progress-linear> -->
        <v-card-text>
          <v-row justify="center" align="center">
            <v-col style="padding-top: 0px; padding-bottom: 0px;">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left text-h6">Bookings</th>
                      <th class="text-left"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Paid</td>
                      <td>{{ bookingReport.totalpaid }}</td>
                    </tr>
                    <tr>
                      <td>
                        <span style="margin-left: 10px;"></span>- Paid in MVR
                      </td>
                      <td>{{ bookingReport.paidmvr }}</td>
                    </tr>
                    <tr>
                      <td>
                        <span style="margin-left: 10px;"></span>- Paid in USD
                      </td>
                      <td>{{ bookingReport.paidusd }}</td>
                    </tr>
                    <tr>
                      <td>Allowed</td>
                      <td>{{ bookingReport.allowed }}</td>
                    </tr>
                    <tr>
                      <td>Pending</td>
                      <td>{{ bookingReport.pending }}</td>
                    </tr>
                    <tr class="font-weight-bold">
                      <td>Total</td>
                      <td>{{ bookingReport.total }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios"
import moment from "moment"
export default {
  name: "Reports",
  props: {
    message: Function,
    errorMessage: Function,
    decimalCurrency: Function,
  },
  data: () => ({
    datesSelection: 'Current Month',
    all: false,
    fromDialog: null,
    from: moment().startOf('month').format('YYYY-MM-DD'),
    toDialog: null,
    to: moment().endOf('month').format('YYYY-MM-DD'),
    tripReportLoading: false,
    tripReport: null,
    bookingReportLoading: false,
    bookingReport: null,
    revenueReportLoading: false,
    revenueReport: null,
  }),
  computed: {
    computedFrom() {
      return this.from ? moment(this.from).format("DD MMMM YYYY") : ""
    },
    computedTo() {
      return this.to ? moment(this.to).format("DD MMMM YYYY") : ""
    },
    allFromTo() {
      return `${this.all ? "true" : "false"}${this.from}${this.to}`
    }
  },
  methods: {
    allowedFromDates(date) {
      return moment(date, "YYYY-MM-DD").isSameOrBefore(moment(this.to), "day")
    },
    allowedToDates(date) {
      return moment(date, "YYYY-MM-DD").isSameOrAfter(moment(this.from), "day")
    },
    allReports() {
      this.tripReports()
      this.bookingReports()
      this.revenueReports()
    },
    tripReports() {
      this.tripReportLoading = true
      const fromISO = moment(this.from).toISOString()
      const toISO = moment(this.to).toISOString()
      const query = this.all ? '?all=true' : `?from=${fromISO}&to=${toISO}`
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/tripreports${query}`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          this.tripReport = resp.data.report
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error)
          }
        })
        .finally(() => {
          this.tripReportLoading = false
        })
    },
    bookingReports() {
      this.bookingReportLoading = true
      const fromISO = moment(this.from).toISOString()
      const toISO = moment(this.to).toISOString()
      const query = this.all ? '?all=true' : `?from=${fromISO}&to=${toISO}`
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/bookingreports${query}`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          this.bookingReport = resp.data.report
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error)
          }
        })
        .finally(() => {
          this.bookingReportLoading = false
        })
    },
    revenueReports() {
      this.revenueReportLoading = true
      const fromISO = moment(this.from).toISOString()
      const toISO = moment(this.to).endOf('day').toISOString()
      const query = this.all ? '?all=true' : `?from=${fromISO}&to=${toISO}`
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/revenuereports${query}`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          this.revenueReport = resp.data.report
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error)
          }
        })
        .finally(() => {
          this.revenueReportLoading = false
        })
    },
  },
  mounted() {
    this.allReports()
  },
  watch: {
    datesSelection: {
      handler: function (val) {
        if (val === 'All') {
          this.all = true
        } else {
          this.all = false
        }
        if (val === 'Current Month') {
          this.from = moment().startOf('month').format('YYYY-MM-DD')
          this.to = moment().endOf('month').format('YYYY-MM-DD')
        } else if (val === 'Last 30 Days') {
          this.from = moment().subtract(30, 'day').format('YYYY-MM-DD')
          this.to = moment().format('YYYY-MM-DD')
        }
      }
    },
    allFromTo: {
      handler: function () {
        this.allReports()
      }
    },
  }
}
</script>