<template>
  <div id="background">
    <Nav />
    <Header />
    <v-row justify="center" style="width: 100%; margin: 0px">
      <v-col
        align-self="center"
        xs="12"
        sm="12"
        md="8"
        lg="6"
        xl="4"
        style="padding: 0px"
      >
        <booking-card />
      </v-col>
    </v-row>
    <Footer />
  </div>
</template>

<script>
import BookingCard from "../components/BookingCard.vue";
import Footer from "../components/Footer.vue";
import Header from "../components/Header";
import Nav from "../components/Nav.vue";

export default {
  name: "Home",

  components: {
    Header,
    BookingCard,
    Footer,
    Nav,
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}

#app {
  background-color: #f2f3f6;
}
</style>
