<template>
  <v-dialog v-model="visible" max-width="600" :retain-focus="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" icon v-bind="attrs" v-on="on">
        <v-icon>mdi-seat-passenger</v-icon>
      </v-btn>
    </template>
    <v-card style="padding: 30px">
      {{ boat.name }} seat layout
      <div>
        <v-alert
          outlined
          type="warning"
          style="padding: 0.5rem; font-size: 0.8rem"
          >Updating boat layout will reset layout on all trips of this boat
          scheduled to depart from now onwards.
        </v-alert>
      </div>
      <div
        style="display: flex; gap: 1rem; margin-top: 1rem; margin-bottom: 1rem"
      >
        <v-text-field
          :value="boat.capacity"
          label="Capacity"
          disabled
          type="number"
          hide-details
        />
        <v-text-field
          :value="selectedCount"
          label="Selected Seats"
          disabled
          type="number"
          hide-details
        />
        <v-text-field
          :value="availableCount"
          label="Remaing Seats"
          disabled
          type="number"
          hide-details
        />
      </div>
      <div
        v-if="rows"
        style="display: flex; gap: 1rem; margin-top: 1rem; margin-bottom: 1rem"
      >
        <v-text-field
          v-model="rowCount"
          label="Rows"
          outlined
          type="number"
          :min="1"
          :max="MAX_SEAT_ROWS"
          hide-details
        />
        <v-text-field
          v-model="columnCount"
          label="Columns"
          outlined
          type="number"
          :min="1"
          :max="MAX_SEAT_COLS"
          hide-details
        />
      </div>
      <v-radio-group
        v-if="rows"
        v-model="mode"
        row
        hide-details
        style="margin-bottom: 1rem"
      >
        <v-radio
          key="Selection"
          label="Selection mode"
          value="Selection"
        ></v-radio>
        <v-radio key="Rename" label="Rename mode" value="Rename"></v-radio>
      </v-radio-group>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <div
        v-if="rows"
        style="
          display: flex;
          justify-content: center;
          opacity: 0.3;
          font-weight: bold;
        "
      >
        FRONT
      </div>
      <div
        v-if="rows"
        style="display: flex; flex-direction: column; gap: 5px; overflow: auto"
      >
        <div
          v-for="row in rows"
          :key="row.no"
          style="display: flex; justify-content: space-around; gap: 5px"
        >
          <div
            v-for="column in row.columns"
            :key="column.no"
            :style="{
              'border-radius': '4px',
              border: 'solid 1px grey',
              padding: `2px`,
              width: '100%',
              maxWidth: '100px',
              maxHeight: '100px',
              aspectRatio: '1 / 1',
              backgroundColor: column.active ? '#DFFFD8' : undefined,
              cursor: mode === 'Selection' ? 'pointer' : 'text',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }"
            @click="mode === 'Selection' ? cellHandle(row, column) : null"
            @blur="
              (e) => (mode === 'Selection' ? null : cellHandle(row, column, e))
            "
            :contenteditable="mode === 'Rename' ? true : false"
          >
            {{ column.name }}
          </div>
        </div>
      </div>
      <div
        v-if="rows"
        style="
          display: flex;
          justify-content: center;
          opacity: 0.3;
          font-weight: bold;
          margin-bottom: 1rem;
        "
      >
        BACK
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          gap: 1rem;
          flex-wrap: wrap;
          margin-top: 1rem;
        "
      >
        <div style="display: flex; gap: 1rem; flex-wrap: wrap" v-if="rows">
          <v-btn color="primary" outlined @click="applyAll(true)">All</v-btn>
          <v-btn color="primary" outlined @click="applyAll(false)">Clear</v-btn>
          <v-btn
            class="hidden-sm-and-up"
            color="primary"
            outlined
            @click="visible = false"
            >Close</v-btn
          >
        </div>
        <div v-else></div>
        <v-btn
          color="primary"
          @click="update"
          :loading="updating"
          :disabled="!changed || loading"
          >Update</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  MAX_SEAT_COLS,
  MAX_SEAT_ROWS,
  apiRequest,
  numberToColumn,
} from "../../../helpers";

export default {
  name: "UpdateLayout",
  props: { boat: Object, errorMessage: Function },
  data: () => ({
    visible: false,
    mode: "Selection",
    rowCount: 1,
    columnCount: 1,
    rowsOriginal: null,
    rows: null,
    loading: false,
    updating: false,
  }),
  computed: {
    MAX_SEAT_COLS: () => MAX_SEAT_COLS,
    MAX_SEAT_ROWS: () => MAX_SEAT_ROWS,
    selectedCount() {
      let count = 0;
      if (this.rows) {
        for (const row of this.rows) {
          for (const col of row.columns) {
            if (col.active) count++;
          }
        }
      }
      return count;
    },
    availableCount() {
      return this.boat.capacity - this.selectedCount;
    },
    changed() {
      return JSON.stringify(this.rowsOriginal) !== JSON.stringify(this.rows);
    },
  },
  methods: {
    numberToColumn(x) {
      return numberToColumn(x);
    },
    applyAll(active) {
      const rowsClone = JSON.parse(JSON.stringify(this.rows));
      for (const row of rowsClone) {
        for (const col of row.columns) {
          col.active = active;
        }
      }
      this.rows = rowsClone;
    },
    cellHandle(clickedRow, clickedCol, e) {
      const rowsClone = JSON.parse(JSON.stringify(this.rows));
      for (const row of rowsClone) {
        if (row.no !== clickedRow.no) continue;
        for (const col of row.columns) {
          if (col.no !== clickedCol.no) continue;
          if (e && this.mode === "Rename") {
            const input = e.target.innerHTML.trim();
            col.name = input;
          } else {
            const isActive = Boolean(clickedCol.active);
            if (!isActive && this.availableCount <= 0) return;
            col.active = !isActive;
          }
        }
      }
      this.rows = rowsClone;
    },
    find() {
      const endpoint = `/api/getboatlayout?boatId=${this.boat.id}`;
      this.loading = true;
      const thenHandle = (resp) => {
        const rows = resp?.data?.rows;
        if (rows) {
          this.rowsOriginal = rows;
          this.rows = rows;
          this.rowCount = rows.length;
          this.columnCount = rows[0].columns.length;
        }
      };
      const catchHandle = (error) => {
        this.rows = [
          {
            no: 1,
            columns: [{ no: 1, name: "A1", active: true }],
          },
        ];
      };
      const finallyHandle = () => {
        this.loading = false;
      };
      apiRequest({
        endpoint,
        method: "GET",
        thenHandle,
        catchHandle,
        finallyHandle,
      });
    },
    update() {
      const endpoint = `/api/createboatlayout`;
      const data = {
        boatId: this.boat.id,
        rows: this.rows,
      };
      this.updating = true;
      const thenHandle = (resp) => {
        this.trip = resp.data.trip ?? null;
        this.find();
      };
      const catchHandle = (error) => {
        if (error.response) {
          this.errorMessage(error.response.data.error);
        }
      };
      const finallyHandle = () => {
        this.updating = false;
      };
      apiRequest({
        endpoint,
        method: "POST",
        thenHandle,
        catchHandle,
        finallyHandle,
        data,
      });
    },
  },
  watch: {
    rowCount(newCount) {
      newCount = parseInt(newCount);
      if (newCount <= 0 || newCount > MAX_SEAT_ROWS || newCount === NaN) return;
      if (this.rows.length > newCount) {
        this.rows = this.rows.slice(0, newCount);
      } else if (this.rows.length < newCount) {
        for (let i = this.rows.length + 1; i <= newCount; i++) {
          let columns = [];
          for (let j = 1; j <= this.columnCount; j++) {
            columns.push({
              no: j,
              name: `${numberToColumn(j)}${i}`,
              active: this.availableCount > 0 ? true : false,
            });
          }
          this.rows = [
            ...this.rows,
            {
              no: i,
              columns,
            },
          ];
        }
      }
    },
    columnCount(newCount) {
      newCount = parseInt(newCount);
      if (newCount <= 0 || newCount > MAX_SEAT_COLS) return;
      const oldCount = this.rows[0].columns.length;
      if (oldCount > newCount) {
        for (const row of this.rows) {
          row.columns = row.columns.slice(0, newCount);
        }
      } else if (oldCount < newCount) {
        for (const row of this.rows) {
          for (let j = oldCount + 1; j <= newCount; j++) {
            row.columns.push({
              no: j,
              name: `${numberToColumn(j)}${row.no}`,
              active: this.availableCount > 0 ? true : false,
            });
          }
        }
      }
    },
    visible(value) {
      if (value) {
        this.find();
      }
    },
  },
};
</script>
