<template>
  <v-dialog
    v-model="confirmDialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    width="100%"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on" icon>
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card style="padding:30px">
      <v-row align="center">
        <v-col cols="6" style="padding-left: 0px">
          <v-text-field
            v-model="tripClone.usdPerSeat"
            label="Price per seat"
            outlined
            type="number"
            suffix="USD"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6" style="padding-right: 0px">
          <v-text-field
            v-model="tripClone.mvrPerSeat"
            label
            outlined
            type="number"
            suffix="MVR"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row style="margin-top:-10px; margin-bottom:-20px">
        <v-col class="noHorizontalPadding noVerticalPadding">
          <v-checkbox v-model="differentLocalPrice" label="Different price for locals"></v-checkbox>
        </v-col>
      </v-row>
      <v-row v-if="differentLocalPrice" align="center" style="margin-bottom: 20px;">
        <v-col cols="6" style="padding-left: 0px">
          <v-text-field
            v-model="tripClone.usdPerSeatLocal"
            label="Price per seat for locals"
            outlined
            type="number"
            suffix="USD"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6" style="padding-right: 0px">
          <v-text-field
            v-model="tripClone.mvrPerSeatLocal"
            label
            outlined
            type="number"
            suffix="MVR"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row style="margin-top:-10px; justify-content:space-between">
        <div></div>
        <v-btn color="primary" outlined :loading="editing" @click="edit()">Update</v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios"
export default {
  name: "EditTripPricing",
  props: {
    trip: Object,
    getTrips: Function,
    message: Function,
    errorMessage: Function,
  },
  data: () => ({
    confirmDialog: null,
    tripClone: null,
    differentLocalPrice: null,
    editing: false,
  }),
  methods: {
    edit() {
      const data = {
        tripId: this.trip.id,
        usdPerSeat: Math.round(this.tripClone.usdPerSeat * 100) / 100,
        mvrPerSeat: Math.round(this.tripClone.mvrPerSeat * 100) / 100
      }
      if (this.differentLocalPrice) {
        data.usdPerSeatLocal = Math.round(this.tripClone.usdPerSeatLocal * 100) / 100
        data.mvrPerSeatLocal = Math.round(this.tripClone.mvrPerSeatLocal * 100) / 100
      }
      this.editing = true
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/editseatpricing`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message)
          this.getTrips()
          this.confirmDialog = false
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error)
          }
        })
        .finally(() => (this.editing = false))
    },
  },
  created() {
    this.tripClone = {
      usdPerSeat: this.trip.centPerSeat / 100,
      usdPerSeatLocal: this.trip.centPerSeatLocal / 100,
      mvrPerSeat: this.trip.laariPerSeat / 100,
      mvrPerSeatLocal: this.trip.laariPerSeatLocal / 100,
    }
    if (this.tripClone.usdPerSeatLocal !== this.tripClone.usdPerSeat || this.tripClone.mvrPerSeatLocal !== this.tripClone.mvrPerSeat) {
      this.differentLocalPrice = true
    } else {
      this.differentLocalPrice = false
    }
  },
}
</script>

<style scoped>
.noHorizontalPadding {
  padding-left: 0px;
  padding-right: 0px;
}
.noVerticalPadding {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>