<template>
  <v-card>
    <v-card-text>
      <v-text-field
        v-model="search"
        label="Search"
        style="margin-bottom: 10px"
        outlined
        clearable
        hide-details
      ></v-text-field>
      <v-select
        outlined
        :items="possibleStatuses"
        label="Status"
        v-model="status"
        hide-details
        style="margin-bottom: 10px"
        clearable
      ></v-select>
      <v-select
        outlined
        :items="['Newest', 'Oldest']"
        label="Sort by"
        v-model="sort"
        hide-details
        style="margin-bottom: 10px"
      ></v-select>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <span v-if="privateTransfers.length === 0 && !loading"
        >No private transfers</span
      >
      <PrivateTransferDialog
        v-for="privateTransfer in privateTransfers"
        :key="privateTransfer.id"
        :privateTransfer="privateTransfer"
        :message="message"
        :errorMessage="errorMessage"
        :getBookings="getBookings"
        :getTrips="getTrips"
        :getPrivateTransfers="getPrivateTransfers"
        :boats="boats"
        :configs="configs"
      />
      <v-row v-if="privateTransfers.length > 0" align="center">
        <v-col class="shrink text-left" cols="4">
          <v-btn
            v-if="page > 0"
            @click="changePage(false)"
            outlined
            color="primary"
            >Prev</v-btn
          >
        </v-col>
        <v-col class="text-center" cols="4"
          >Page {{ page + 1 }} of {{ Math.ceil(count / pageLimit) }}</v-col
        >
        <v-col class="shrink text-right" cols="4">
          <v-btn
            v-if="count > (page + 1) * pageLimit"
            @click="changePage(true)"
            outlined
            color="primary"
            >Next</v-btn
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import moment from "moment";
import BookingDialog from "./BookingDialog.vue";
import PrivateTransferDialog from "./PrivateTransferDialog.vue";
export default {
  name: "PrivateTransfers",
  components: {
    BookingDialog,
    PrivateTransferDialog,
  },
  props: {
    message: Function,
    errorMessage: Function,
    getImageUrl: Function,
    getBookings: Function,
    getTrips: Function,
    boats: Array,
    configs: Array,
  },
  data: () => ({
    possibleStatuses: ["Pending", "Accepted", "Rejected"],
    status: "",
    search: "",
    searchTimeout: null,
    sort: "Newest",
    loading: false,
    page: 0,
    pageLimit: 5,
    privateTransfers: [],
    count: null,
  }),
  computed: {
    statusAndSort() {
      return `${this.status}-${this.sort}`;
    },
  },
  methods: {
    computeDate(date) {
      return moment(date).format("dddd, D MMMM YYYY");
    },
    decimalCurrency(number) {
      const small = number.toString();
      return (
        small.substring(0, small.length - 2) +
        "." +
        small.substring(small.length - 2)
      );
    },
    changePage(next) {
      if (next) this.page++;
      else this.page--;
      this.getPrivateTransfers();
    },
    getPrivateTransfers() {
      this.loading = true;
      const query = `?page=${this.page}&pageLimit=${this.pageLimit}&sort=${
        this.sort
      }&status=${this.status ? this.status : ""}&search=${
        this.search ? this.search : ""
      }`;
      axios({
        url: `${
          process.env.VUE_APP_BASE_URL ?? ""
        }/api/getprivatetransfers${query}`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          this.privateTransfers = [];
          this.privateTransfers = resp.data.privateTransfers ?? [];
          this.count = resp.data.count;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getPrivateTransfers();
    const queryStatus = this.$route.query.status;
    if (this.possibleStatuses.includes(queryStatus)) {
      this.status = queryStatus;
    }
  },
  watch: {
    statusAndSort: {
      handler: function () {
        this.page = 0;
        this.getPrivateTransfers();
      },
    },
    search: {
      handler: function () {
        if (this.searchTimeout) clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          this.page = 0;
          this.getPrivateTransfers();
        }, 250);
      },
    },
  },
};
</script>
