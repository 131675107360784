<template>
  <v-dialog
    v-model="newTripMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    width="100%"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        outlined
        block
        large
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        >Add Trip</v-btn
      >
    </template>
    <v-card style="padding: 30px">
      <v-row>
        <v-col class="noHorizontalPadding">
          <v-text-field
            :value="computedDate"
            label="Date"
            prepend-inner-icon="mdi-calendar"
            readonly
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row style="margin-top: -30px">
        <v-col class="noHorizontalPadding">
          <v-select
            outlined
            :items="boats"
            label="Boat"
            item-text="name"
            v-model="newTrip.boat"
            return-object
          >
            <template v-slot:item="{ item }">{{ item.name }}</template>
            <template v-slot:selection="{ item }">{{ item.name }}</template>
          </v-select>
        </v-col>
      </v-row>
      <div style="margin-top: -30px; display: flex; align-items: center">
        <div style="width: 45%" class="noHorizontalPadding">
          <v-select
            outlined
            :items="locations"
            label="From"
            v-model="newTrip.from"
            item-text="name"
            return-object
          ></v-select>
        </div>
        <div style="width: 10%">
          <v-row justify="center" style="margin-bottom: 20px">
            <v-icon large @click="swapLocations">mdi-swap-horizontal</v-icon>
          </v-row>
        </div>
        <div style="width: 45%" class="noHorizontalPadding">
          <v-select
            outlined
            :items="locations"
            label="To"
            v-model="newTrip.to"
            item-text="name"
            return-object
          ></v-select>
        </div>
      </div>
      <v-row align="center" style="margin-top: -30px">
        <v-col cols="6" class="noHorizontalPadding">
          <v-text-field
            v-model="newTrip.usdPerSeat"
            label="Price per seat"
            outlined
            type="number"
            suffix="USD"
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="noHorizontalPadding">
          <v-text-field
            v-model="newTrip.mvrPerSeat"
            label
            outlined
            type="number"
            suffix="MVR"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row style="margin-top: -60px">
        <v-col class="noHorizontalPadding">
          <v-checkbox
            v-model="newTrip.differentLocalPrice"
            label="Different price for locals"
          >
          </v-checkbox>
        </v-col>
      </v-row>
      <v-row
        v-if="newTrip.differentLocalPrice"
        align="center"
        style="margin-top: -30px"
      >
        <v-col cols="6" class="noHorizontalPadding">
          <v-text-field
            v-model="newTrip.usdPerSeatLocal"
            label="Price per seat for locals"
            outlined
            type="number"
            suffix="USD"
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="noHorizontalPadding">
          <v-text-field
            v-model="newTrip.mvrPerSeatLocal"
            label
            outlined
            type="number"
            suffix="MVR"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row style="margin-top: -30px">
        <v-col cols="6" class="noHorizontalPadding">
          <v-dialog v-model="newTrip.fromTimeModal" width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="newTrip.fromTime"
                label="Departure"
                prepend-inner-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="newTrip.fromTimeModal"
              v-model="newTrip.fromTime"
              full-width
              format="24hr"
              @click:minute="newTrip.fromTimeModal = false"
            ></v-time-picker>
          </v-dialog>
        </v-col>
        <v-col cols="6" class="noHorizontalPadding">
          <v-dialog v-model="newTrip.toTimeModal" width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="newTrip.toTime"
                label="Arrival"
                prepend-inner-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="newTrip.toTimeModal"
              v-model="newTrip.toTime"
              full-width
              format="24hr"
              @click:minute="newTrip.toTimeModal = false"
            ></v-time-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row style="margin-top: -60px; margin-bottom: 10px">
        <v-col class="noHorizontalPadding">
          <v-checkbox v-model="newTrip.repeat" label="Repeat"></v-checkbox>
        </v-col>
      </v-row>
      <v-row style="margin-top: -40px">
        <v-col class="noHorizontalPadding" v-if="newTrip.repeat">
          <v-switch
            v-model="newTrip.repeatDays.Sunday"
            label="Sunday"
            style="margin-top: 0px"
            dense
            hide-details
          ></v-switch>
          <v-switch
            v-model="newTrip.repeatDays.Monday"
            label="Monday"
            style="margin-top: 0px"
            dense
            hide-details
          ></v-switch>
          <v-switch
            v-model="newTrip.repeatDays.Tuesday"
            label="Tuesday"
            style="margin-top: 0px"
            dense
            hide-details
          ></v-switch>
          <v-switch
            v-model="newTrip.repeatDays.Wednesday"
            label="Wednesday"
            style="margin-top: 0px"
            dense
            hide-details
          ></v-switch>
          <v-switch
            v-model="newTrip.repeatDays.Thursday"
            label="Thursday"
            style="margin-top: 0px"
            dense
            hide-details
          ></v-switch>
          <v-switch
            v-model="newTrip.repeatDays.Friday"
            label="Friday"
            style="margin-top: 0px"
            dense
            hide-details
          ></v-switch>
          <v-switch
            v-model="newTrip.repeatDays.Saturday"
            label="Saturday"
            style="margin-top: 0px; margin-bottom: 20px"
            dense
            hide-details
          ></v-switch>
          <v-dialog
            v-model="newTrip.repeatModal"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                :value="computedRepeatDate"
                label="Repeat until"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="newTrip.repeatUntil"
              @input="newTrip.repeatModal = false"
              :allowed-dates="allowedRepeatDates"
            ></v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row style="margin-top: -10px; justify-content: space-between">
        <div></div>
        <v-btn outlined color="primary" :loading="addingTrip" @click="addTrip"
          >Add Trip{{ newTrip.repeat ? "s" : "" }}</v-btn
        >
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "AddTrip",
  props: {
    message: Function,
    errorMessage: Function,
    date: String,
    computedDate: String,
    boats: Array,
    locations: Array,
    getTrips: Function,
  },
  data: () => ({
    newTripMenu: null,
    addingTrip: false,
    newTrip: {
      boat: null,
      from: null,
      to: null,
      fromTime: null,
      fromTimeModal: null,
      toTime: null,
      toTimeModal: null,
      repeat: false,
      repeatModal: false,
      repeatUntil: moment().add(1, "day").format("YYYY-MM-DD"),
      repeatDays: {
        Sunday: true,
        Monday: true,
        Tuesday: true,
        Wednesday: true,
        Thursday: true,
        Friday: true,
        Saturday: true,
      },
      usdPerSeat: 20,
      mvrPerSeat: 20 * 15.42,
      usdPerSeatLocal: 20,
      mvrPerSeatLocal: 20 * 15.42,
      differentLocalPrice: false,
    },
  }),
  computed: {
    computedRepeatDate() {
      return this.newTrip.repeatUntil
        ? moment(this.newTrip.repeatUntil).format("dddd, D MMMM YYYY")
        : "";
    },
  },
  methods: {
    swapLocations() {
      const temp = this.newTrip.from;
      this.newTrip.from = this.newTrip.to;
      this.newTrip.to = temp;
    },
    allowedRepeatDates(date) {
      return moment(date, "YYYY-MM-DD").isAfter(moment(this.date), "day");
    },
    addTrip() {
      if (!this.newTrip.boat) {
        this.errorMessage("No boat chosen.");
        return;
      }
      if (!this.newTrip.from || !this.newTrip.to) {
        this.errorMessage("Both locations not selected.");
        return;
      }
      if (!this.newTrip.fromTime || !this.newTrip.toTime) {
        this.errorMessage("Both times not selected.");
        return;
      }
      let repeatDays = 0;
      let repeatWeekdays = [];
      if (this.newTrip.repeat) {
        repeatDays = moment(this.newTrip.repeatUntil).diff(
          moment(this.date),
          "days"
        );
        for (const key in this.newTrip.repeatDays) {
          if (this.newTrip.repeatDays[key] === true) {
            repeatWeekdays.push(key);
          }
        }
        if (repeatWeekdays.length === 0) {
          this.errorMessage(
            "Select at least one day of the week if trip is repeating."
          );
          return;
        }
      }
      const data = {
        boatId: this.newTrip.boat.id,
        fromLocationId: this.newTrip.from.id,
        toLocationId: this.newTrip.to.id,
        fromTime: moment(
          `${this.date} ${this.newTrip.fromTime}`,
          "YYYY-MM-DD HH:mm"
        ),
        toTime: moment(
          `${this.date} ${this.newTrip.toTime}`,
          "YYYY-MM-DD HH:mm"
        ),
        repeatDays,
        repeatWeekdays,
        usdPerSeat: Math.round(this.newTrip.usdPerSeat * 100) / 100,
        mvrPerSeat: Math.round(this.newTrip.mvrPerSeat * 100) / 100,
      };
      if (data.toTime.isSameOrBefore(data.fromTime, "minute")) {
        this.errorMessage("Arrival should be after departure.");
        return;
      }
      if (this.newTrip.differentLocalPrice) {
        data.usdPerSeatLocal =
          Math.round(this.newTrip.usdPerSeatLocal * 100) / 100;
        data.mvrPerSeatLocal =
          Math.round(this.newTrip.mvrPerSeatLocal * 100) / 100;
      }
      data.fromTime = data.fromTime.toISOString();
      data.toTime = data.toTime.toISOString();
      this.addingTrip = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/createtrip`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getTrips();
          this.newTripMenu = false;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.addingTrip = false));
    },
  },
  watch: {
    date: {
      handler: function () {
        this.newTrip.repeatUntil = moment(this.date)
          .add(1, "day")
          .format("YYYY-MM-DD");
      },
    },
    "newTrip.fromTime": {
      handler: function () {
        this.newTrip.toTime = moment(
          `${this.date} ${this.newTrip.fromTime}`,
          "YYYY-MM-DD HH:mm"
        )
          .add(30, "minutes")
          .format("HH:mm");
      },
    },
    "newTrip.usdPerSeat": {
      handler: function () {
        this.newTrip.usdPerSeat =
          Math.round(this.newTrip.usdPerSeat * 100) / 100;
      },
    },
  },
};
</script>
