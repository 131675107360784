<template>
  <div>
    <v-snackbar v-model="snackbar" top color="green">{{
      snackbarText
    }}</v-snackbar>
    <v-snackbar v-model="errorSnackbar" top color="red" outlined>{{
      errorSnackbarText
    }}</v-snackbar>
    <div v-if="locationsLoading || ageGroupsLoading" class="text-center">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
      <div style="margin-top: 20px">Loading</div>
    </div>
    <v-container v-else>
      <v-card v-if="step == 1">
        <v-card-text>
          <v-row v-if="checkLocal">
            <v-col>
              <div style="display: flex; align-items: center">
                <span
                  style="
                    color: black;
                    margin-right: 10px;
                    margin-top: 5px;
                    white-space: nowrap;
                  "
                  >I am a</span
                >
                <v-radio-group
                  v-model="isLocal"
                  style="margin-top: 0px"
                  row
                  hide-details
                >
                  <v-radio key="1" label="Tourist" :value="false"></v-radio>
                  <v-radio
                    key="2"
                    :label="getLocalName()"
                    :value="true"
                  ></v-radio>
                </v-radio-group>
              </div>
            </v-col>
          </v-row>
          <div
            style="
              margin-top: 20px;
              padding-bottom: 10px;
              display: flex;
              align-items: center;
            "
          >
            <div style="width: 45%">
              <v-select
                outlined
                :items="availableFromLocations"
                label="From"
                v-model="from"
                item-text="name"
                return-object
                :hide-details="
                  from &&
                  from.points.length > 0 &&
                  !configEnabled('Enable multi-stop feature')
                    ? true
                    : false
                "
              ></v-select>
              <v-select
                v-if="
                  from &&
                  from.points.length > 0 &&
                  !configEnabled('Enable multi-stop feature')
                "
                outlined
                :items="from.points"
                label="Pickup point"
                v-model="pickupPoint"
                item-text="name"
                return-object
                style="margin-top: 1rem"
              ></v-select>
            </div>
            <div style="width: 10%">
              <v-row justify="center" style="margin-bottom: 20px">
                <v-icon large @click="swapLocations"
                  >mdi-swap-horizontal</v-icon
                >
              </v-row>
            </div>
            <div style="width: 45%">
              <v-select
                outlined
                :items="availableToLocations"
                label="To"
                v-model="to"
                item-text="name"
                return-object
                :hide-details="
                  to &&
                  to.points.length > 0 &&
                  !configEnabled('Enable multi-stop feature')
                    ? true
                    : false
                "
              >
              </v-select>
              <v-select
                v-if="
                  to &&
                  to.points.length > 0 &&
                  !configEnabled('Enable multi-stop feature')
                "
                outlined
                :items="to.points"
                label="Dropoff point"
                v-model="dropoffPoint"
                item-text="name"
                return-object
                style="margin-top: 1rem"
              >
              </v-select>
            </div>
          </div>
          <v-row
            v-if="
              !configEnabled('Prevent customers from booking return trips') &&
              !configEnabled('Enable multi-stop feature')
            "
            style="margin-top: -40px"
          >
            <v-col style="padding-top: 0px">
              <v-radio-group v-model="isRoundTrip" row>
                <v-radio key="1" label="One Way" :value="false"></v-radio>
                <v-radio key="2" label="Round Trip" :value="true"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row style="margin-top: 0px">
            <v-col style="padding-top: 0px; padding-bottom: 0px">
              <v-dialog
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    :value="computedDate"
                    label="Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @input="menu = false"
                  :allowed-dates="allowedDates"
                ></v-date-picker>
              </v-dialog>
              <v-progress-linear
                v-if="tripsLoading"
                indeterminate
                color="primary"
              ></v-progress-linear>
            </v-col>
          </v-row>
          <div v-if="from && to" style="margin-bottom: 35px; margin-top: 18px">
            <v-row v-if="trips && trips.length === 0 && !tripsLoading">
              <div
                style="
                  margin-left: 16px;
                  margin-right: 16px;
                  margin-bottom: 20px;
                  display: flex;
                  align-items: center;
                "
              >
                No trips available for this date.
                <Waitlist
                  :from="from"
                  :to="to"
                  :date="date"
                  :computedDate="computedDate"
                  :message="message"
                  :errorMessage="errorMessage"
                />
              </div>
            </v-row>
            <v-row
              v-for="trip in trips"
              :key="trip.id"
              style="margin-left: 0px; margin-right: 0px; margin-bottom: 20px"
            >
              <v-card width="100%" elevation="0" outlined>
                <v-card-text>
                  <v-row align="center">
                    <v-col cols="6" align="center">
                      <p
                        class="text-md-h4 text-lg-h4 text-xl-h4 text--primary text-h6"
                        style="margin: auto; padding: auto"
                      >
                        {{ toFromString(trip) }}
                      </p>
                      <div v-if="isLocal">
                        <span v-if="showMVRAmount"
                          >{{ decimalCurrency(trip.laariPerSeatLocal) }} MVR
                          or</span
                        >
                        {{ decimalCurrency(trip.centPerSeatLocal) }} USD / seat
                      </div>
                      <div v-else>
                        <span v-if="showMVRAmount"
                          >{{ decimalCurrency(trip.laariPerSeat) }} MVR or</span
                        >
                        {{ decimalCurrency(trip.centPerSeat) }} USD / seat
                      </div>
                    </v-col>
                    <v-col cols="6" align="center">
                      <div style="display: flex; justify-content: center">
                        <div
                          :style="{
                            backgroundColor: seatsAvaialableColor(trip),
                            borderRadius: '5px',
                            paddingLeft: '5px',
                            paddingRight: '5px',
                            color: 'white',
                            marginRight: '5px',
                            marginBottom: '2px',
                          }"
                        >
                          {{ trip.capacity - trip.booked }}
                        </div>
                        {{ seatsLeft(trip) }}
                      </div>
                      <v-radio-group
                        v-if="isRoundTrip"
                        v-model="trip.selected"
                        @change="tripSelect(trip)"
                        style="
                          max-width: 24px;
                          max-height: 36px;
                          padding: 0px;
                          margin: 0px;
                        "
                      >
                        <v-radio
                          key="1"
                          :value="true"
                          :disabled="trip.capacity - trip.booked === 0"
                        ></v-radio>
                      </v-radio-group>
                      <v-btn
                        v-else
                        outlined
                        color="primary"
                        style="padding-left: 7px; padding-right: 7px"
                        @click="bookNowBtn(trip)"
                        :disabled="
                          tripsLoading || trip.capacity - trip.booked === 0
                        "
                        >Book Now</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row>
          </div>
          <v-row style="margin-top: 30px">
            <v-col
              v-if="isRoundTrip"
              style="padding-top: 0px; padding-bottom: 0px"
            >
              <v-dialog
                v-model="returnMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    :value="computedReturnDate"
                    label="Return Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="returnDate"
                  @input="returnMenu = false"
                  :allowed-dates="allowedReturnDates"
                >
                </v-date-picker>
              </v-dialog>
              <v-progress-linear
                v-if="returnTripsLoading"
                indeterminate
                color="primary"
              ></v-progress-linear>
            </v-col>
          </v-row>
          <div
            v-if="isRoundTrip && from && to && returnDate"
            style="margin-bottom: 15px; margin-top: 18px"
          >
            <v-row
              v-if="
                returnTrips && returnTrips.length === 0 && !returnTripsLoading
              "
            >
              <div
                style="
                  margin-left: 16px;
                  margin-right: 16px;
                  margin-bottom: 20px;
                  display: flex;
                  align-items: center;
                "
              >
                No trips available for this date.
                <Waitlist
                  :from="to"
                  :to="from"
                  :date="returnDate"
                  :computedDate="computedReturnDate"
                  :message="message"
                  :errorMessage="errorMessage"
                />
              </div>
            </v-row>
            <v-row
              v-for="trip in returnTrips"
              :key="trip.id"
              style="margin-left: 0px; margin-right: 0px; margin-bottom: 20px"
            >
              <v-card width="100%" elevation="0" outlined>
                <v-card-text>
                  <v-row align="center">
                    <v-col cols="6" align="center">
                      <p
                        class="text-md-h4 text-lg-h4 text-xl-h4 text--primary text-h6"
                        style="margin: auto; padding: auto"
                      >
                        {{ toFromString(trip) }}
                      </p>
                      <div v-if="isLocal">
                        <span v-if="showMVRAmount"
                          >{{
                            decimalCurrency(
                              trip.laariPerSeatLocal -
                                (sameDay ? sameDayReturnLaariDiscount : 0)
                            )
                          }}
                          MVR or</span
                        >
                        {{
                          decimalCurrency(
                            trip.centPerSeatLocal -
                              (sameDay ? sameDayReturnCentDiscount : 0)
                          )
                        }}
                        USD / seat
                      </div>
                      <div v-else>
                        <span v-if="showMVRAmount"
                          >{{
                            decimalCurrency(
                              trip.laariPerSeat -
                                (sameDay ? sameDayReturnLaariDiscount : 0)
                            )
                          }}
                          MVR or</span
                        >
                        {{
                          decimalCurrency(
                            trip.centPerSeat -
                              (sameDay ? sameDayReturnCentDiscount : 0)
                          )
                        }}
                        USD / seat
                      </div>
                    </v-col>
                    <v-col cols="6" align="center">
                      <div style="display: flex; justify-content: center">
                        <div
                          :style="{
                            backgroundColor: seatsAvaialableColor(trip),
                            borderRadius: '5px',
                            paddingLeft: '5px',
                            paddingRight: '5px',
                            color: 'white',
                            marginRight: '5px',
                            marginBottom: '2px',
                          }"
                        >
                          {{ trip.capacity - trip.booked }}
                        </div>
                        {{ seatsLeft(trip) }}
                      </div>
                      <v-radio-group
                        v-model="trip.selected"
                        @change="returnTripSelect(trip)"
                        style="
                          max-width: 24px;
                          max-height: 36px;
                          padding: 0px;
                          margin: 0px;
                        "
                      >
                        <v-radio
                          key="1"
                          :value="true"
                          :disabled="trip.capacity - trip.booked === 0"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions
          v-if="isRoundTrip"
          style="
            margin-top: 0px;
            padding-bottom: 16px;
            padding-left: 16px;
            padding-right: 16px;
            justify-content: space-between;
          "
        >
          <div></div>
          <v-btn
            outlined
            color="primary"
            @click="next()"
            :disabled="tripsLoading || returnTripsLoading"
            >Book Now
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="step == 2">
        <div>
          <TripInfo
            :isRoundTrip="isRoundTrip"
            :trip="trip"
            :returnTrip="returnTrip"
            :toFromString="toFromString"
            :from="from"
            :to="to"
            :computedDate="computedDate"
            :computedReturnDate="computedReturnDate"
            :decimalCurrency="decimalCurrency"
            :isLocal="isLocal"
            :showMVRAmount="showMVRAmount"
            :sameDay="sameDay"
            :sameDayReturnCentDiscount="sameDayReturnCentDiscount"
            :sameDayReturnLaariDiscount="sameDayReturnLaariDiscount"
            :pickupPoint="
              !configEnabled('Enable multi-stop feature') ? pickupPoint : null
            "
            :dropoffPoint="
              !configEnabled('Enable multi-stop feature') ? dropoffPoint : null
            "
          />
          <v-card-text>
            {{ seatsAvailable }}
            <v-row
              v-for="ageGroup in ageGroups"
              :key="ageGroup.id"
              style="margin-left: 0px; margin-right: 0px; margin-top: 30px"
            >
              <v-card width="100%" elevation="0" outlined>
                <v-card-text>
                  <v-row align="center">
                    <v-col cols="3" align="center">
                      <v-icon large @click="ageGroupMinus(ageGroup)"
                        >mdi-minus</v-icon
                      >
                    </v-col>
                    <v-col cols="4" align="center">
                      <p
                        class="text-md-h4 text-lg-h4 text-xl-h4 text--primary text-h5"
                        style="margin: auto"
                      >
                        {{ ageGroup.name }}
                      </p>
                    </v-col>
                    <v-col cols="2" align="center">
                      <p
                        class="text-md-h4 text-lg-h4 text-xl-h4 text--primary text-h5"
                        style="margin: auto"
                      >
                        {{ ageGroup.quantity }}
                      </p>
                    </v-col>
                    <v-col cols="3" align="center">
                      <v-icon large @click="ageGroupPlus(ageGroup)"
                        >mdi-plus</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row>
          </v-card-text>
          <v-card-actions
            style="
              margin-top: 20px;
              padding-bottom: 16px;
              padding-left: 16px;
              padding-right: 16px;
              justify-content: space-between;
            "
          >
            <v-btn text outlined color="primary" @click="back()">Back</v-btn>
            <v-btn text outlined color="primary" @click="next()"
              >Continue</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
      <v-card v-if="step == 3">
        <div>
          <TripInfo
            :isRoundTrip="isRoundTrip"
            :trip="trip"
            :returnTrip="returnTrip"
            :toFromString="toFromString"
            :from="from"
            :to="to"
            :computedDate="computedDate"
            :computedReturnDate="computedReturnDate"
            :decimalCurrency="decimalCurrency"
            :isLocal="isLocal"
            :showMVRAmount="showMVRAmount"
            :sameDay="sameDay"
            :sameDayReturnCentDiscount="sameDayReturnCentDiscount"
            :sameDayReturnLaariDiscount="sameDayReturnLaariDiscount"
            :pickupPoint="
              !configEnabled('Enable multi-stop feature') ? pickupPoint : null
            "
            :dropoffPoint="
              !configEnabled('Enable multi-stop feature') ? dropoffPoint : null
            "
          />
          <v-card-text>
            Enter full name of customers (as in passport or national ID card).
            <v-row
              style="margin-left: 0px; margin-right: 0px; margin-top: 30px"
            >
              <v-card
                width="100%"
                elevation="0"
                style="
                  display: flex;
                  flex-direction: column;
                  gap: 1rem;
                  margin-bottom: 1rem;
                "
              >
                <div
                  v-for="(customer, index) in customers"
                  :key="index"
                  style="display: flex; flex-direction: column; gap: 0.5rem"
                >
                  <v-text-field
                    outlined
                    :label="`${customer.ageGroup.name} ${customer.groupIndex}`"
                    v-model="customer.name"
                    :autofocus="index === 0 ? true : false"
                    hide-details
                  ></v-text-field>
                  <div
                    style="display: flex; gap: 0.5rem"
                    v-if="
                      configEnabled(
                        'Require customer national ID or passport'
                      ) || configEnabled('Require customer emergency contact')
                    "
                  >
                    <v-text-field
                      v-if="
                        configEnabled(
                          'Require customer national ID or passport'
                        )
                      "
                      outlined
                      :label="`${customer.ageGroup.name} ${customer.groupIndex} NID/Passport No`"
                      v-model="customer.nid"
                      hide-details
                      dense
                    ></v-text-field>
                    <v-text-field
                      v-if="configEnabled('Require customer emergency contact')"
                      outlined
                      :label="`${customer.ageGroup.name} ${customer.groupIndex} Emergency Contact`"
                      v-model="customer.emergencyContact"
                      hide-details
                      dense
                    ></v-text-field>
                  </div>
                </div>
              </v-card>
            </v-row>
          </v-card-text>
          <v-card-actions
            style="
              padding-bottom: 16px;
              padding-left: 16px;
              padding-right: 16px;
              justify-content: space-between;
            "
          >
            <v-btn text outlined color="primary" @click="back()">Back</v-btn>
            <v-btn text outlined color="primary" @click="next()"
              >Continue</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
      <v-card v-if="step == 4">
        <div>
          <TripInfo
            :isRoundTrip="isRoundTrip"
            :trip="trip"
            :returnTrip="returnTrip"
            :toFromString="toFromString"
            :from="from"
            :to="to"
            :computedDate="computedDate"
            :computedReturnDate="computedReturnDate"
            :decimalCurrency="decimalCurrency"
            :isLocal="isLocal"
            :showMVRAmount="showMVRAmount"
            :sameDay="sameDay"
            :sameDayReturnCentDiscount="sameDayReturnCentDiscount"
            :sameDayReturnLaariDiscount="sameDayReturnLaariDiscount"
            :pickupPoint="
              !configEnabled('Enable multi-stop feature') ? pickupPoint : null
            "
            :dropoffPoint="
              !configEnabled('Enable multi-stop feature') ? dropoffPoint : null
            "
          />
          <v-card-text>
            Enter phone number or email address.
            <v-row
              style="margin-left: 0px; margin-right: 0px; margin-top: 30px"
            >
              <v-card width="100%" elevation="0">
                <vue-tel-input
                  :inputOptions="telInputOptions"
                  defaultCountry="mv"
                  @input="phoneInput"
                  :value="phoneDefault"
                  style="height: 56px; font-size: 16px; margin-bottom: 30px"
                  @enter="next()"
                ></vue-tel-input>
                <v-text-field
                  outlined
                  placeholder="Email address"
                  v-model="email"
                  @keypress.enter="next()"
                ></v-text-field>
              </v-card>
            </v-row>
          </v-card-text>
          <v-card-actions
            style="
              padding-bottom: 16px;
              padding-left: 16px;
              padding-right: 16px;
              justify-content: space-between;
            "
          >
            <v-btn text outlined color="primary" @click="back()">Back</v-btn>
            <v-btn text outlined color="primary" @click="next()"
              >Continue</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
      <v-card v-if="step == 5">
        <div>
          <TripInfo
            :isRoundTrip="isRoundTrip"
            :trip="trip"
            :returnTrip="returnTrip"
            :toFromString="toFromString"
            :from="from"
            :to="to"
            :computedDate="computedDate"
            :computedReturnDate="computedReturnDate"
            :decimalCurrency="decimalCurrency"
            :isLocal="isLocal"
            :showMVRAmount="showMVRAmount"
            :sameDay="sameDay"
            :sameDayReturnCentDiscount="sameDayReturnCentDiscount"
            :sameDayReturnLaariDiscount="sameDayReturnLaariDiscount"
            :pickupPoint="
              !configEnabled('Enable multi-stop feature') ? pickupPoint : null
            "
            :dropoffPoint="
              !configEnabled('Enable multi-stop feature') ? dropoffPoint : null
            "
          />
          <v-card-text>
            <v-row
              style="margin-left: 0px; margin-right: 0px; margin-top: 20px"
            >
              <v-card width="100%" elevation="0" outlined>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-wrap"
                      >Customers</v-list-item-title
                    >
                    <v-list-item-subtitle
                      v-for="(customer, index) in customers"
                      :key="index"
                      >{{ customer.name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-row>
            <v-row
              style="margin-left: 0px; margin-right: 0px; margin-top: 20px"
            >
              <v-card width="100%" elevation="0" outlined>
                <v-list-item v-if="email">
                  <v-list-item-content>
                    <v-list-item-title class="text-wrap"
                      >Email address</v-list-item-title
                    >
                    <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="phone && phone.valid">
                  <v-list-item-content>
                    <v-list-item-title class="text-wrap"
                      >Phone number</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      phone.number
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-row>
            <v-text-field
              style="margin-top: 20px"
              outlined
              v-model="notes"
              label="Other details (optional)"
              placeholder="e.g. guesthouse/hotel that you are staying in, special requests"
              hide-details
              autofocus
            ></v-text-field>
          </v-card-text>
          <v-card-text v-if="showAboutAndTOC()" style="padding-bottom: 0px">
            By proceeding you are agreeing to the
            <a
              style="text-decoration: none"
              href="/terms-and-conditions"
              target="_blank"
              >Terms and Conditions</a
            >.
          </v-card-text>
          <v-card-actions
            style="
              padding-bottom: 16px;
              padding-left: 16px;
              padding-right: 16px;
              margin-top: 30px;
              justify-content: space-between;
            "
          >
            <v-btn text outlined color="primary" @click="back()">Back</v-btn>
            <v-btn text outlined color="primary" @click="confirm()"
              >Confirm</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
      <v-alert
        dense
        outlined
        type="info"
        style="margin-top: 10px; margin-bottom: 0px"
        >All times are shown in Maldives Time (GMT+5)</v-alert
      >
      <div style="display: flex; justify-content: center; margin-top: 10px">
        <RequestPrivateTransfer
          v-if="step == 1"
          :locations="locations"
          :message="message"
          :errorMessage="errorMessage"
          :ageGroupsOriginal="ageGroupsOriginal"
          :requireCustomerNames="requireCustomerNames"
        />
      </div>
      <div
        v-if="getAddress()"
        style="
          display: flex;
          justify-content: center;
          margin-top: 1rem;
          opacity: 0.8;
        "
      >
        {{ getAddress() }}
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import TripInfo from "../components/TripInfo.vue";
import Waitlist from "../components/Waitlist.vue";
import { DT_FORMATS, configEnabled, findTripStartTime } from "../helpers";
import ToFromLocation from "./Common/ToFromLocation.vue";
import RequestPrivateTransfer from "./RequestPrivateTransfer.vue";

export default {
  name: "BookingCard",
  components: {
    Waitlist,
    TripInfo,
    RequestPrivateTransfer,
    ToFromLocation,
  },
  data: () => ({
    snackbar: false,
    snackbarText: "",
    errorSnackbar: false,
    errorSnackbarText: "",
    telInputOptions: { placeholder: "Phone number", autofocus: true },
    step: 1,
    locations: [],
    locationsLoading: false,
    from: null,
    to: null,
    isRoundTrip: false,
    date: moment().format("YYYY-MM-DD"),
    returnDate: moment().add(1, "day").format("YYYY-MM-DD"),
    menu: null,
    returnMenu: null,
    phoneDefault: "",
    phone: "",
    email: "",
    trip: null,
    returnTrip: null,
    ageGroupsOriginal: [],
    ageGroups: [],
    ageGroupsLoading: false,
    trips: [],
    tripsLoading: false,
    returnTrips: [],
    returnTripsLoading: false,
    customers: [],
    notes: "",
    checkLocal: false,
    isLocal: true,
    nextTripTimeLoading: false,
    getTripsCounter: 0,
    getReturnTripsCounter: 0,
    showMVRAmount: true,
    sameDayReturnLaariDiscount: 0,
    sameDayReturnCentDiscount: 0,
    pickupPoint: null,
    dropoffPoint: null,
    requireCustomerNames: true,
    preventReturnTrip: false,
    configs: [],
  }),
  computed: {
    computedDate() {
      return this.date ? moment(this.date).format("dddd, D MMMM YYYY") : "";
    },
    computedReturnDate() {
      return this.date
        ? moment(this.returnDate).format("dddd, D MMMM YYYY")
        : "";
    },
    availableFromLocations() {
      return this.locations.filter((loc) => this.to.id !== loc.id);
    },
    availableToLocations() {
      return this.locations.filter((loc) => this.from.id !== loc.id);
    },
    seatsAvailable() {
      let booked = this.trip.booked;
      if (this.trip.route && this.trip.routeToStop) {
        booked = this.trip.routeToStop.booked;
      }
      const tripAvailable = this.trip.capacity - booked;
      const available = this.isRoundTrip
        ? Math.min(
            this.returnTrip.capacity - this.returnTrip.booked,
            tripAvailable
          )
        : tripAvailable;
      return `${available} seat${available === 1 ? "" : "s"} available.`;
    },
    fromAndToIds() {
      return `${this.from ? this.from.id : 0}-${this.to ? this.to.id : 0}`;
    },
    sameDay() {
      return (
        this.isRoundTrip === true &&
        moment(this.date).isSame(moment(this.returnDate), "date")
      );
    },
  },
  methods: {
    configEnabled(name) {
      return configEnabled(name, this.$store.getters.getConfigs);
    },
    getAddress() {
      return process.env.VUE_APP_ADDRESS ?? "";
    },
    getCompany() {
      return process.env.VUE_APP_COMPANY ?? "Techverin Ltd.";
    },
    getLocalName() {
      return process.env.VUE_APP_LOCAL_NAME ?? "Local";
    },
    showAboutAndTOC() {
      if (this.getCompany() === "Techverin Ltd.") return true;
      return false;
    },
    swapLocations() {
      const temp = this.from;
      this.from = this.to;
      this.to = temp;
      const tempPoint = this.pickupPoint;
      this.pickupPoint = this.dropoffPoint;
      this.dropoffPoint = tempPoint;
    },
    allowedDates(date) {
      return moment(date, "YYYY-MM-DD").isSameOrAfter(moment(), "day");
    },
    allowedReturnDates(date) {
      return moment(date, "YYYY-MM-DD").isSameOrAfter(moment(this.date), "day");
    },
    toFromString(trip) {
      let format = DT_FORMATS.time12hr;
      if (this.configEnabled("Use 24 hour clock")) format = DT_FORMATS.time24hr;
      return findTripStartTime(trip, trip.routeToStop).format(format);
    },
    seatsLeft(trip) {
      const left = trip.capacity - trip.booked;
      return `seat${left === 1 ? "" : "s"} left`;
    },
    seatsAvaialableColor(trip) {
      let color = "#37a7ad";
      if (trip.booked === 0) return color;
      const percent = trip.booked / trip.capacity;
      if (percent > 0.2) color = "#ff8721";
      if (percent > 0.8) color = "red";
      return color;
    },
    bookNowBtn(trip) {
      this.trip = trip;
      this.next();
    },
    ageGroupMinus(ageGroup) {
      if (ageGroup.quantity > 0) {
        ageGroup.quantity--;
      }
    },
    ageGroupPlus(ageGroup) {
      const total = this.ageGroups
        .filter((group) => group.occupiesSeat)
        .reduce((a, b) => a + b.quantity, 0);
      let booked = this.trip.booked;
      if (this.trip.route && this.trip.routeToStop) {
        booked = this.trip.routeToStop.booked;
      }
      const available = this.trip.capacity - booked;
      if (total < available || !ageGroup.occupiesSeat) {
        ageGroup.quantity++;
      }
    },
    decimalCurrency(number) {
      const small = number.toString();
      return (
        small.substring(0, small.length - 2) +
        "." +
        small.substring(small.length - 2)
      );
    },
    next() {
      if (this.step === 1 && this.isRoundTrip) {
        this.trip = this.trips.find((tr) => tr.selected);
        this.returnTrip = this.returnTrips.find((tr) => tr.selected);
        if (!this.trip || !this.returnTrip) {
          this.errorMessage("Please select both trips.");
          return;
        }
        if (
          this.trip.capacity - this.trip.booked === 0 ||
          this.returnTrip.capacity - this.returnTrip.booked === 0
        ) {
          this.errorMessage("Seats not available.");
          return;
        }
      } else if (this.step === 2) {
        this.ageGroups.forEach((group, i) => {
          for (let i = 0; i < group.quantity; i++) {
            this.customers.push({
              name: null,
              ageGroup: group,
              groupIndex: i + 1,
              nid: "",
              emergencyContact: "",
            });
          }
        });
        if (this.customers.length === 0) {
          this.errorMessage("Select at least one customer.");
          return;
        }
        if (
          this.customers.filter((cust) => cust.ageGroup.occupiesSeat).length ===
          0
        ) {
          this.errorMessage("Select a customer that occupies a seat.");
          this.customers = [];
          return;
        }
        if (
          this.customers.filter((cust) => cust.ageGroup.id === 3).length >
          this.customers.filter((cust) => cust.ageGroup.id === 1).length
        ) {
          this.errorMessage("Each infant must have an accompanying adult.");
          this.customers = [];
          return;
        }
      } else if (this.step === 3) {
        let unfilledNames = false;
        this.customers.forEach((cust) => {
          if (!cust.name || cust.name.trim() === "") {
            if (this.requireCustomerNames === false) {
              cust.name = `${cust.ageGroup.name} ${cust.groupIndex}`;
            } else {
              unfilledNames = true;
            }
          }
        });
        if (unfilledNames) {
          this.errorMessage("Please enter all customer names.");
          return;
        }
      } else if (this.step == 4) {
        if (
          this.email.trim() === "" &&
          (this.phone === "" || this.phone?.formatted?.trim() === "")
        ) {
          this.errorMessage("Please enter at least one.");
          return;
        }
        if (
          this.phone &&
          this.phone !== "" &&
          this.phone?.formatted?.trim() !== "" &&
          !this.phone.valid
        ) {
          this.errorMessage("Invalid phone number.");
          return;
        }
        if (this.email.trim() !== "") {
          const validEmailRe = new RegExp(
            `([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+`
          );
          if (!validEmailRe.test(this.email.trim())) {
            this.errorMessage("Invalid email address.");
            return;
          }
        }
      }
      this.step++;
    },
    back() {
      this.step--;
      if (this.step === 1) {
        this.ageGroups.forEach(
          (group) => (group.quantity = group.name === "Adult" ? 1 : 0)
        );
      } else if (this.step === 2) {
        this.customers = [];
      }
    },
    message(message) {
      this.snackbarText = message;
      this.snackbar = true;
    },
    errorMessage(message) {
      this.errorSnackbarText = message ?? "Unexpected error occurred";
      this.errorSnackbar = true;
      if (message === "Token expired.") {
        this.$store.dispatch("logout");
      }
    },
    phoneInput(phone, phoneObject) {
      this.phone = phoneObject;
    },
    tripSelect(trip) {
      this.trips.forEach((tr) => {
        if (trip.id !== tr.id) tr.selected = false;
      });
      this.trip = trip;
    },
    returnTripSelect(trip) {
      this.returnTrips.forEach((tr) => {
        if (trip.id !== tr.id) tr.selected = false;
      });
      this.returnTrip = trip;
    },
    getAgeGroups() {
      this.ageGroupsLoading = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/getagegroups`,
        method: "GET",
      })
        .then((resp) => {
          this.ageGroups = resp.data.ageGroups.map((group) => ({
            ...group,
            quantity: group.name === "Adult" ? 1 : 0,
          }));
          this.ageGroupsOriginal = this.ageGroups;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.ageGroupsLoading = false;
        });
    },
    getLocations() {
      this.locationsLoading = true;
      const user = this.$store.getters.getUser;
      const endPoint =
        this.$store.getters.isLoggedIn && (user.isAdmin || user.isCaptain)
          ? ""
          : "public";
      axios({
        url: `${
          process.env.VUE_APP_BASE_URL ?? ""
        }/api/get${endPoint}locations`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          const locations = resp.data.locations;
          this.locations = locations;
          const locAssigned = false;
          if (this.$route.query.from && this.$route.query.to) {
            const fromLoc = locations.find(
              (loc) => loc.id === parseInt(this.$route.query.from)
            );
            const toLoc = locations.find(
              (loc) => loc.id === parseInt(this.$route.query.to)
            );
            if (fromLoc && toLoc && fromLoc.id !== toLoc.id) {
              this.from = fromLoc;
              this.to = toLoc;
              locAssigned = true;
            }
          }
          if (!locAssigned && locations.length >= 2) {
            this.from = locations[0];
            this.to = locations[1];
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.locationsLoading = false;
        });
    },
    findRouteToStop(trip) {
      if (!trip || !trip.route) return;
      for (const stop of trip.route.stops) {
        if (stop.location.id !== this.from.id) continue;
        for (const toStop of stop.toStops) {
          if (toStop.location.id !== this.to.id) continue;
          trip.routeToStop = toStop;
          trip.laariPerSeat = toStop.laariPerSeat;
          trip.laariPerSeatLocal = toStop.laariPerSeatLocal;
          trip.centPerSeat = toStop.centPerSeat;
          trip.centPerSeatLocal = toStop.centPerSeatLocal;
        }
      }
    },
    getFromTrips() {
      this.tripsLoading = true;
      const date = moment(this.date).toISOString();
      axios({
        url: `${
          process.env.VUE_APP_BASE_URL ?? ""
        }/api/getcustomertrips?date=${date}&from=${this.from.id}&to=${
          this.to.id
        }`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          const trips = resp.data.trips ?? [];
          const firstNotFullTrip = trips.find(
            (t) => t.capacity - t.booked !== 0
          );
          if (trips.length > 0 && firstNotFullTrip)
            firstNotFullTrip.selected = true;
          this.trips = trips;
          if (this.$route.query.trip) {
            const selectedTrip = trips.find(
              (trip) => trip.id === parseInt(this.$route.query.trip)
            );
            if (selectedTrip) {
              this.trip = selectedTrip;
              this.step = 2;
            }
          }
          // if no trips on current date, find date of next available trip
          if (
            (!this.trips || this.trips.length === 0) &&
            !this.$route.query.date &&
            this.getTripsCounter === 0
          ) {
            this.getNextTripTime(false);
          }
          // Find RouteToStops of each point. Not applicable for return trips
          // as return trips can't be used together with routes
          for (const trip of this.trips) {
            this.findRouteToStop(trip);
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.tripsLoading = false;
          this.getTripsCounter = 1;
        });
    },
    getReturnTrips() {
      this.returnTripsLoading = true;
      const date = moment(this.returnDate).toISOString();
      axios({
        url: `${
          process.env.VUE_APP_BASE_URL ?? ""
        }/api/getcustomertrips?date=${date}&from=${this.to.id}&to=${
          this.from.id
        }`,
        method: "GET",
      })
        .then((resp) => {
          const trips = resp.data.trips ?? [];
          const firstNotFullTrip = trips.find(
            (t) => t.capacity - t.booked !== 0
          );
          if (trips.length > 0 && firstNotFullTrip)
            firstNotFullTrip.selected = true;
          this.returnTrips = trips;
          // if no trips on current date, find date of next available trip
          if (
            (!this.returnTrips || this.returnTrips.length === 0) &&
            !this.$route.query.date &&
            this.getReturnTripsCounter === 0
          ) {
            this.getNextTripTime(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.returnTripsLoading = false;
          this.getReturnTripsCounter = 1;
        });
    },
    getNextTripTime(isReturnTrip) {
      this.nextTripTimeLoading = true;
      let from = this.from;
      let to = this.to;
      if (isReturnTrip) {
        from = this.to;
        to = this.from;
      }
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/getnexttriptime?&from=${
          from.id
        }&to=${to.id}`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          const date = resp.data.nextTripTime;
          if (isReturnTrip) {
            this.returnDate = moment(date).format("YYYY-MM-DD");
          } else {
            this.date = moment(date).format("YYYY-MM-DD");
          }
        })
        .catch(() => {
          // do nothing
        })
        .finally(() => {
          this.nextTripTimeLoading = false;
        });
    },
    confirm() {
      const data = {
        tripId: this.trip.id,
        phone: this.phone.number,
        phoneCountryCode: this.phone.countryCode,
        email: this.email,
        isRoundTrip: this.isRoundTrip,
        returnTripId: this.isRoundTrip ? this.returnTrip.id : null,
        notes: this.notes,
        customers: this.customers.map((cust) => ({
          name: cust.name,
          ageGroupId: cust.ageGroup.id,
          nid: cust.nid,
          emergencyContact: cust.emergencyContact,
        })),
        isLocal: this.isLocal,
        pickupPointId:
          !this.configEnabled("Enable multi-stop feature") && this.pickupPoint
            ? this.pickupPoint.id
            : null,
        dropoffPointId:
          !this.configEnabled("Enable multi-stop feature") && this.dropoffPoint
            ? this.dropoffPoint.id
            : null,
        routeToStopId:
          this.trip.route && this.trip.routeToStop
            ? this.trip.routeToStop.id
            : null,
      };
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/createbooking`,
        method: "POST",
        data,
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.$router.push(`/booking/${resp.data.uuid}`);
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        });
    },
    getConfigs() {
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/getconfigs`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          const configs = resp.data.configs ?? [];
          this.configs = configs;
          configs.forEach((c) => {
            if (
              c.name === "Show MVR amount to customers" &&
              c.value !== "true"
            ) {
              this.showMVRAmount = false;
            } else if (c.name === "Cent discount for same day return trip") {
              this.sameDayReturnCentDiscount = parseInt(c.value);
            } else if (c.name === "Laari discount for same day return trip") {
              this.sameDayReturnLaariDiscount = parseInt(c.value);
            } else if (
              c.name ===
                "Require customer names to be entered when making booking" &&
              c.value === "false"
            ) {
              this.requireCustomerNames = false;
            } else if (
              c.name === "Prevent customers from booking return trips" &&
              c.value === "true"
            ) {
              this.preventReturnTrip = true;
            }
          });
          this.$store.dispatch("configure", {
            configs,
          });
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {});
    },
  },
  mounted() {
    this.getLocations();
    this.getAgeGroups();
    this.getConfigs();
    if (this.$route.query.date) {
      const date = moment(this.$route.query.date);
      if (date.isValid() && date.isSameOrAfter(moment(), "day")) {
        this.date = date.format("YYYY-MM-DD");
        this.returnDate = date.add(1, "day").format("YYYY-MM-DD");
      }
    }
    if (this.$route.query.email) {
      this.email = this.$route.query.email;
    }
    if (this.$route.query.phone) {
      this.phoneDefault = this.$route.query.phone;
    }
    if (process.env.VUE_APP_CHECK_LOCAL === "true") {
      this.checkLocal = true;
    }
  },
  watch: {
    fromAndToIds: {
      handler: function () {
        this.getTripsCounter = 0;
        this.getReturnTripsCounter = 0;
        this.getFromTrips();
        this.getReturnTrips();
      },
    },
    date: {
      handler: function () {
        if (this.from) this.getFromTrips();
        if (moment(this.returnDate).isBefore(this.date, "day")) {
          this.returnDate = this.date;
        }
      },
    },
    returnDate: {
      handler: function () {
        if (this.to) this.getReturnTrips();
      },
    },
    from: {
      handler: function () {
        const locationPointIds = this.from.points.map((p) => p.id) ?? [];
        if (
          this.from.points.length > 0 &&
          (!this.pickupPoint || !locationPointIds.includes(this.pickupPoint.id))
        ) {
          this.pickupPoint = this.from.points[0];
        }
      },
    },
    to: {
      handler: function () {
        const locationPointIds = this.from.points.map((p) => p.id) ?? [];
        if (
          this.to?.points.length > 0 &&
          (!this.dropoffPoint ||
            !locationPointIds.includes(this.dropoffPoint.id))
        ) {
          this.dropoffPoint = this.to.points[0];
        }
      },
    },
  },
};
</script>

<style scoped>
.v-snack__wrapper {
  min-width: none;
  max-width: none;
}

.vue-tel-input {
  border-color: #9e9e9e;
}

.vue-tel-input:focus-within {
  box-shadow: none;
  border-width: 2px;
  border-color: "primary";
}
</style>
