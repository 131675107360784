<template>
  <v-dialog v-model="confirmDialog" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
    offset-y width="100%" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item-title v-bind="attrs" v-on="on" style="cursor: pointer;">
        Allow Booking
      </v-list-item-title>
    </template>
    <v-card style="padding:25px">
      <v-card elevation="0" outlined>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              <div style="display:flex;justify-content:space-between;min-width: 150px">
                <span style="font-weight: 600;">Payment</span>
                <span>{{ decimalCurrency(booking.priceInLaari) }} MVR</span>
                <span>or</span>
                <span>{{ decimalCurrency(booking.priceInCent) }} USD</span>
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <v-divider style="margin-top: 1rem; margin-bottom: 1rem;"></v-divider>
      <div style="display: flex; justify-content: space-between;align-items: center;">
        <div>
          Free of charge
        </div>
        <v-btn style="margin-right: 12px" color="primary" outlined :loading="allowingBooking && allowedType === ''"
          @click="allowBooking('')">Allow</v-btn>
      </div>
      <v-divider style="margin-top: 1rem; margin-bottom: 1rem;"></v-divider>
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <div>
          Pre-paid
        </div>
        <v-radio-group v-model="currency" row>
          <v-radio key="1" label="MVR" value="MVR"></v-radio>
          <v-radio key="2" label="USD" value="USD"></v-radio>
        </v-radio-group>
        <v-btn style="margin-right: 12px" color="primary" outlined
          :loading="allowingBooking && allowedType === 'Pre-paid'" @click="allowBooking('Pre-paid')">Allow</v-btn>
      </div>
      <v-divider style="margin-top: 1rem; margin-bottom: 1rem;"></v-divider>
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <div style="width: 66px;">
          Credit
        </div>
        <v-radio-group v-model="currency" row>
          <v-radio key="1" label="MVR" value="MVR"></v-radio>
          <v-radio key="2" label="USD" value="USD"></v-radio>
        </v-radio-group>
        <v-btn style="margin-right: 12px" color="primary" outlined
          :loading="allowingBooking && allowedType === 'Credit'" @click="allowBooking('Credit')">Allow</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  name: "AllowBooking",
  props: {
    booking: Object,
    message: Function,
    errorMessage: Function,
    getBookings: Function,
    getBooking: Function,
    getBookingsList: Function,
    getTrips: Function,
  },
  data: () => ({
    confirmDialog: null,
    currency: "MVR",
    allowingBooking: false,
    allowedType: ""
  }),
  methods: {
    decimalCurrency(number) {
      const small = number.toString()
      return (
        small.substring(0, small.length - 2) +
        "." +
        small.substring(small.length - 2)
      )
    },
    allowBooking(allowedType) {
      this.allowingBooking = true
      this.allowedType = allowedType
      const data = {
        bookingId: this.booking.id,
        allowedType,
        currency: this.currency,
      }
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/allowbooking`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message)
          this.getBookings()
          this.getTrips()
          this.getBooking()
          if (this.getBookingsList) {
            this.getBookingsList()
          }
          this.confirmDialog = null
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error)
          }
        })
        .finally(() => {
          this.allowingBooking = false
        })
    },
  },
}
</script>