<template>
  <v-card>
    <v-card-text>
      <v-progress-linear v-if="loadingConfigs" indeterminate color="primary"></v-progress-linear>
      <v-card v-if="configs.length > 0" flat outlined class="mb-2 pa-2 pt-4">
        <v-card-text>
          <div v-for="config in newConfigs" :key="config.name">
            <div style="text-align: right;" v-if="config.updatedByUsername">
              Updated by {{ config.updatedByUsername }} on
              {{ date(config.updated_at) }}
            </div>
            <div>
              <v-text-field v-if="config.value !== true && config.value !== false" v-model="config.value" type="number"
                filled>
                <template v-slot:label>
                  <div>
                    <span style="display: inline-block; font-size: 20px;">

                      {{config.name}}
                    </span>
                    <!-- <span v-if="config.updatedByUsername" style="display: inline-block;">
                      Updated by {{config.updatedByUsername}} on
                      {{date(config.updated_at)}}
                    </span> -->
                  </div>
                </template>
              </v-text-field>
              <v-checkbox style=" margin-top:0px" v-else v-model="config.value" :label="config.name">
                <template v-slot:label>
                  <div>
                    {{config.name}}
                    <!-- <div v-if="config.updatedByUsername" style="font-size: 13px;">
                      Updated by {{config.updatedByUsername}} on
                      {{date(config.updated_at)}}
                    </div> -->
                  </div>
                </template>
              </v-checkbox>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <v-btn color="primary" outlined block large class="mb-2" :loading="loading" :disabled="!updated"
        @click="update()">Save</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios"
import moment from "moment"
export default {
  name: "Config",
  props: {
    message: Function,
    errorMessage: Function,
    configs: Array,
    getConfigs: Function,
    loadingConfigs: Boolean,
  },
  data: () => ({
    originalConfigs: [],
    newConfigs: [],
    loading: false,
  }),
  computed: {
    updated() {
      return (
        JSON.stringify(this.originalConfigs) !== JSON.stringify(this.newConfigs)
      )
    },
  },
  methods: {
    date(date) {
      return moment(date).format("YYYY-MM-DD HH:mm")
    },
    update() {
      this.loading = true
      const data = this.newConfigs.map((c) => {
        let value
        const int = parseFloat(c.value)
        if (int || int === 0) {
          value = `${Math.round(int * 100)}`
        } else if (c.value === true) {
          value = "true"
        } else if (c.value === false) {
          value = "false"
        }
        return { id: c.id, value }
      })
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/updateconfig`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message)
          this.newConfigs = []
          this.getConfigs()
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    init() {
      const conf = JSON.parse(JSON.stringify(this.configs))
      conf.forEach((c) => {
        const int = parseFloat(c.value)
        if (int || int === 0) {
          c.name = c.name.replace("Cent", "USD")
          c.name = c.name.replace("Laari", "MVR")
          c.value = int / 100
        } else if (c.value === "true") c.value = true
        else if (c.value === "false") c.value = false
      })
      const bools = []
      this.newConfigs = []
      conf.forEach((c) => {
        if (c.value === true || c.value === false) {
          bools.push(c)
        } else {
          this.newConfigs.push(c)
        }
      })
      bools.forEach((c) => {
        this.newConfigs.push(c)
      })
      this.originalConfigs = JSON.parse(JSON.stringify(this.newConfigs))
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    configs: {
      handler: function () {
        this.init()
      },
    },
  },
}
</script>
