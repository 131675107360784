<template>
  <v-dialog
    v-model="confirmDialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    width="100%"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        v-bind="attrs"
        v-on="on"
        small
        text
        style="padding: 5px"
      >
        <v-icon class="mr-0">mdi-history</v-icon>Change
      </v-btn>
    </template>
    <v-card style="padding: 25px; padding-bottom: 1px">
      <v-row style="margin-top: 0px">
        <v-card-text style="padding-top: 0px"
          >Transfer booking #{{ booking.refNo }} to another trip</v-card-text
        >
        <v-col style="padding-top: 0px; padding-bottom: 0px">
          <v-dialog
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                :value="computedDate"
                label="Date"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" @input="menu = false"></v-date-picker>
          </v-dialog>
          <v-progress-linear
            v-if="tripsLoading || changeLoading"
            indeterminate
            color="primary"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <div style="margin-bottom: 35px; margin-top: 18px">
        <v-row v-if="trips && trips.length === 0 && !tripsLoading">
          <div
            style="margin-left: 16px; margin-right: 16px; margin-bottom: 20px"
          >
            No trips available for this date.
          </div>
        </v-row>
        <v-row
          v-for="trip in trips"
          :key="trip.id"
          style="margin-left: 0px; margin-right: 0px; margin-bottom: 20px"
        >
          <v-card width="100%" elevation="0" outlined>
            <v-card-text>
              <v-row align="center">
                <v-col cols="7" align="center">
                  <div>
                    <v-icon class="mb-1">mdi-ferry</v-icon>
                    <span class="subtitle-1">{{ trip.boat.name }}</span>
                  </div>
                  <p
                    class="text-md-h4 text-lg-h4 text-xl-h4 text--primary text-h6"
                    style="margin: auto; padding: auto"
                  >
                    {{ toFromString(trip) }}
                  </p>
                </v-col>
                <v-col
                  v-if="trip.id === currentTrip.id"
                  cols="5"
                  align="center"
                >
                  <v-btn
                    text
                    color="primary"
                    style="padding-left: 3px; padding-right: 3px"
                    disabled
                    >Current</v-btn
                  >
                </v-col>
                <v-col v-else cols="5" align="center">
                  <div>{{ seatsLeft(trip) }}</div>
                  <v-btn
                    text
                    color="primary"
                    style="padding-left: 3px; padding-right: 3px"
                    :disabled="tripsLoading || changeLoading"
                    @click="changeBooking(trip)"
                    >Change</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { toFromString } from "../helpers";

export default {
  name: "ChangeBookingByAdmin",
  props: {
    booking: Object,
    message: Function,
    errorMessage: Function,
    getBooking: Function,
    loading: Boolean,
    selectedTrip: Object,
    getTripsParent: Function,
  },
  data: () => ({
    confirmDialog: null,
    menu: null,
    date: null,
    currentTrip: null,
    trips: [],
    tripsLoading: false,
    changeLoading: false,
  }),
  computed: {
    computedDate() {
      return this.date ? moment(this.date).format("dddd, D MMMM YYYY") : "";
    },
  },
  methods: {
    toFromString(trip) {
      return toFromString(trip, this.$store.getters.getConfigs);
    },
    seatsLeft(trip) {
      const left = trip.capacity - trip.booked;
      return `${left} seat${left === 1 ? "" : "s"} left`;
    },
    getTrips() {
      this.tripsLoading = true;
      const date = moment(this.date).toISOString();
      const current = moment().toISOString();
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/gettrips?date=${date}`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          const trips = resp.data.trips ?? [];
          this.trips = trips.filter(
            (trip) =>
              trip.fromLocationId === this.currentTrip.fromLocation.id &&
              trip.toLocationId === this.currentTrip.toLocation.id
          );
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.tripsLoading = false;
        });
    },
    changeBooking(trip) {
      const data = {
        bookingUUID: this.booking.uuid,
        isReturnTrip: this.isReturnTrip,
        changeToTripId: trip.id,
      };
      this.changeLoading = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/changebooking`,
        method: "POST",
        data,
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.confirmDialog = false;
          this.getTrips();
          this.getBooking();
          this.getTripsParent();
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.changeLoading = false));
    },
  },
  watch: {
    confirmDialog: {
      handler: function () {
        if (this.confirmDialog) {
          this.currentTrip = this.selectedTrip;
          this.date = moment(this.currentTrip.fromTime)
            .startOf("day")
            .format("YYYY-MM-DD");
        } else {
          this.getTrips();
        }
      },
    },
    date: {
      handler: function () {
        this.getTrips();
      },
    },
  },
};
</script>
