<template>
  <v-dialog
    v-model="newTripMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    width="100%"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        outlined
        block
        large
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        >Add Trip</v-btn
      >
    </template>
    <v-card
      style="padding: 30px; display: flex; flex-direction: column; gap: 1rem"
    >
      <div>
        <v-text-field
          :value="computedDate"
          label="Date"
          prepend-inner-icon="mdi-calendar"
          readonly
          disabled
          hide-details
        ></v-text-field>
      </div>
      <div>
        <v-select
          outlined
          :items="boats"
          label="Boat"
          item-text="name"
          v-model="newTrip.boat"
          return-object
          hide-details
        >
          <template v-slot:item="{ item }">{{ item.name }}</template>
          <template v-slot:selection="{ item }">{{ item.name }}</template>
        </v-select>
      </div>
      <div style="display: flex; align-items: center">
        <v-select
          outlined
          :items="routes"
          label="Route"
          v-model="newTrip.route"
          item-text="name"
          return-object
          hide-details
        ></v-select>
      </div>
      <div style="display: flex; gap: 1rem">
        <div>
          <v-dialog v-model="newTrip.fromTimeModal" width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="newTrip.fromTime"
                label="Departure"
                prepend-inner-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                hide-details
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="newTrip.fromTimeModal"
              v-model="newTrip.fromTime"
              full-width
              format="24hr"
              @click:minute="newTrip.fromTimeModal = false"
            ></v-time-picker>
          </v-dialog>
        </div>
        <div>
          <v-dialog v-model="newTrip.toTimeModal" width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="newTrip.toTime"
                label="Arrival"
                prepend-inner-icon="mdi-clock-time-four-outline"
                readonly
                outlined
                hide-details
                disabled
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="newTrip.toTimeModal"
              v-model="newTrip.toTime"
              full-width
              format="24hr"
              @click:minute="newTrip.toTimeModal = false"
            ></v-time-picker>
          </v-dialog>
        </div>
      </div>
      <div>
        <v-checkbox
          v-model="newTrip.repeat"
          label="Repeat"
          hide-details
        ></v-checkbox>
      </div>
      <div v-if="newTrip.repeat">
        <v-switch
          v-model="newTrip.repeatDays.Sunday"
          label="Sunday"
          style="margin-top: 0px"
          dense
          hide-details
        ></v-switch>
        <v-switch
          v-model="newTrip.repeatDays.Monday"
          label="Monday"
          style="margin-top: 0px"
          dense
          hide-details
        ></v-switch>
        <v-switch
          v-model="newTrip.repeatDays.Tuesday"
          label="Tuesday"
          style="margin-top: 0px"
          dense
          hide-details
        ></v-switch>
        <v-switch
          v-model="newTrip.repeatDays.Wednesday"
          label="Wednesday"
          style="margin-top: 0px"
          dense
          hide-details
        ></v-switch>
        <v-switch
          v-model="newTrip.repeatDays.Thursday"
          label="Thursday"
          style="margin-top: 0px"
          dense
          hide-details
        ></v-switch>
        <v-switch
          v-model="newTrip.repeatDays.Friday"
          label="Friday"
          style="margin-top: 0px"
          dense
          hide-details
        ></v-switch>
        <v-switch
          v-model="newTrip.repeatDays.Saturday"
          label="Saturday"
          style="margin-top: 0px; margin-bottom: 20px"
          dense
          hide-details
        ></v-switch>
        <v-dialog
          v-model="newTrip.repeatModal"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              outlined
              :value="computedRepeatDate"
              label="Repeat until"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="newTrip.repeatUntil"
            @input="newTrip.repeatModal = false"
            :allowed-dates="allowedRepeatDates"
          ></v-date-picker>
        </v-dialog>
      </div>
      <div style="justify-content: space-between">
        <div></div>
        <v-btn outlined color="primary" :loading="addingTrip" @click="addTrip"
          >Add Trip{{ newTrip.repeat ? "s" : "" }}</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "AddRouteTrip",
  props: {
    message: Function,
    errorMessage: Function,
    date: String,
    computedDate: String,
    boats: Array,
    routes: Array,
    getTrips: Function,
  },
  data: () => ({
    newTripMenu: null,
    addingTrip: false,
    newTrip: {
      boat: null,
      route: null,
      fromTime: null,
      fromTimeModal: null,
      toTime: null,
      toTimeModal: null,
      repeat: false,
      repeatModal: false,
      repeatUntil: moment().add(1, "day").format("YYYY-MM-DD"),
      repeatDays: {
        Sunday: true,
        Monday: true,
        Tuesday: true,
        Wednesday: true,
        Thursday: true,
        Friday: true,
        Saturday: true,
      },
    },
  }),
  computed: {
    computedRepeatDate() {
      return this.newTrip.repeatUntil
        ? moment(this.newTrip.repeatUntil).format("dddd, D MMMM YYYY")
        : "";
    },
  },
  methods: {
    swapLocations() {
      const temp = this.newTrip.from;
      this.newTrip.from = this.newTrip.to;
      this.newTrip.to = temp;
    },
    allowedRepeatDates(date) {
      return moment(date, "YYYY-MM-DD").isAfter(moment(this.date), "day");
    },
    addTrip() {
      if (!this.newTrip.boat) {
        this.errorMessage("No boat chosen.");
        return;
      }
      if (!this.newTrip.route) {
        this.errorMessage("Route not selected.");
        return;
      }
      if (!this.newTrip.fromTime) {
        this.errorMessage("Time not selected.");
        return;
      }
      let repeatDays = 0;
      let repeatWeekdays = [];
      if (this.newTrip.repeat) {
        repeatDays = moment(this.newTrip.repeatUntil).diff(
          moment(this.date),
          "days"
        );
        for (const key in this.newTrip.repeatDays) {
          if (this.newTrip.repeatDays[key] === true) {
            repeatWeekdays.push(key);
          }
        }
        if (repeatWeekdays.length === 0) {
          this.errorMessage(
            "Select at least one day of the week if trip is repeating."
          );
          return;
        }
      }
      const data = {
        boatId: this.newTrip.boat.id,
        routeId: this.newTrip.route.id,
        fromTime: moment(
          `${this.date} ${this.newTrip.fromTime}`,
          "YYYY-MM-DD HH:mm"
        ),
        toTime: moment(
          `${this.date} ${this.newTrip.toTime}`,
          "YYYY-MM-DD HH:mm"
        ),
        repeatDays,
        repeatWeekdays,
      };
      if (data.toTime.isSameOrBefore(data.fromTime, "minute")) {
        this.errorMessage("Arrival should be after departure.");
        return;
      }
      data.fromTime = data.fromTime.toISOString();
      data.toTime = data.toTime.toISOString();
      this.addingTrip = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/createrripforroute`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getTrips();
          this.newTripMenu = false;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.addingTrip = false));
    },
    calcToTime() {
      if (!this.newTrip.fromTime) return;
      const firstStop = this.newTrip?.route?.stops[0];
      if (!firstStop) return;
      const lastStop = firstStop.toStops[firstStop.toStops.length - 1];
      const minutesToAdd = lastStop.minutes;
      this.newTrip.toTime = moment(
        `${this.date} ${this.newTrip.fromTime}`,
        "YYYY-MM-DD HH:mm"
      )
        .add(minutesToAdd, "minutes")
        .format("HH:mm");
    },
  },
  watch: {
    date: {
      handler: function () {
        this.newTrip.repeatUntil = moment(this.date)
          .add(1, "day")
          .format("YYYY-MM-DD");
      },
    },
    "newTrip.fromTime": {
      handler: function () {
        this.calcToTime();
      },
    },
    "newTrip.route": {
      handler: function () {
        this.calcToTime();
      },
    },
  },
};
</script>
