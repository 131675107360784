<template>
  <div id="background">
    <Header />
    <v-row justify="center" style="width: 100%; margin: 0px">
      <v-col
        align-self="center"
        xs="12"
        sm="12"
        md="8"
        lg="6"
        xl="4"
        style="padding: 0px"
      >
        <v-container>
          <v-card>
            <div class="text-justify" style="padding: 1rem">
              <div v-html="getToc()"></div>
            </div>
          </v-card>
        </v-container>
      </v-col>
      <Footer />
    </v-row>
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
import Header from "../components/Header";

export default {
  name: "TOC",
  components: {
    Header,
    Footer,
  },
  methods: {
    getToc() {
      return process.env.VUE_APP_TOC ?? "";
    },
  },
};
</script>
