<template>
  <div v-if="items.length > 1" style="max-width: 130px">
    <v-select
      outlined
      :items="items"
      hide-details
      dense
      v-on:change="onChange"
      v-model="item"
    ></v-select>
  </div>
</template>
<script>
const routes = [
  { text: "Customer", value: "/" },
  { text: "Captain", value: "/captain" },
  { text: "Admin", value: "/admin" },
];
export default {
  name: "RouteSelector",
  data: () => ({
    item: null,
    items: [routes[0]],
  }),
  methods: {
    onChange(item) {
      this.$router.push(item);
    },
  },
  mounted() {
    const user = this.$store.getters.getUser;
    if (user.isCaptain) {
      this.items.push(routes[1]);
    }
    if (user.isAdmin) {
      this.items.push(routes[2]);
    }
    this.item = this.items.find((i) => i.value === this.$route.path);
  },
};
</script>
