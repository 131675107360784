<template>
  <v-dialog
    v-model="confirmDialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    width="100%"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item-title v-bind="attrs" v-on="on" style="cursor: pointer">
        Set as Cash Payment
      </v-list-item-title>
    </template>
    <v-card style="padding: 25px">
      <v-alert
        outlined
        type="warning"
        color="orange"
        v-if="this.booking.transfer.id !== 0"
        >Bank transfer is currently selected for this booking. Setting as cash
        payment will cancel the bank transfer.</v-alert
      >
      Set this booking's payment method as cash?
      <div style="display: flex; justify-content: end">
        <v-btn
          style="margin-right: 12px"
          color="primary"
          outlined
          :loading="loading"
          @click="setAsCash()"
          >Confirm</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "AllowBooking",
  props: {
    booking: Object,
    message: Function,
    errorMessage: Function,
    getBookings: Function,
    getBooking: Function,
    getBookingsList: Function,
    getTrips: Function,
  },
  data: () => ({
    confirmDialog: null,
    loading: false,
  }),
  methods: {
    setAsCash() {
      const data = {
        bookingId: this.booking.id,
      };
      this.loading = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/setascashpayment`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getBookings();
          this.getTrips();
          this.getBooking();
          if (this.getBookingsList) {
            this.getBookingsList();
          }
          this.confirmDialog = null;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
