<template>
  <v-dialog
    v-model="confirmDialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    width="100%"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="captain"
        outlined
        color="primary"
        v-bind="attrs"
        v-on="on"
        style="margin-top: 5px;"
      >Confirm Cash Payment</v-btn>
      <v-list-item-title
        v-else
        v-bind="attrs"
        v-on="on"
        style="cursor: pointer;"
      >Confirm Cash Payment</v-list-item-title>
    </template>
    <v-card style="padding:25px;">
      <v-card elevation="0" outlined>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              <div style="display:flex;justify-content:space-between;min-width: 150px">
                <span style="font-weight: 600;">Payment</span>
                <span>{{ decimalCurrency(booking.priceInLaari) }} MVR</span>
                <span>or</span>
                <span>{{ decimalCurrency(booking.priceInCent) }} USD</span>
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <v-radio-group v-model="currency" row>
        <v-radio key="1" label="MVR" value="MVR"></v-radio>
        <v-radio key="2" label="USD" value="USD"></v-radio>
      </v-radio-group>
      <v-row style="margin-top: 24px; margin-bottom: 0px; justify-content:space-between">
        <div></div>
        <div>
          <v-btn
            style="margin-right: 12px"
            color="primary"
            outlined
            :loading="loading"
            @click="request()"
          >Confirm Payment Recieved</v-btn>
        </div>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  name: "ConfirmCash",
  props: {
    captain: Boolean,
    booking: Object,
    message: Function,
    errorMessage: Function,
    getBooking: Function,
    getBookings: Function,
  },
  data: () => ({
    confirmDialog: null,
    loading: false,
    currency: "MVR",
  }),
  methods: {
    decimalCurrency(number) {
      const small = number.toString()
      return (
        small.substring(0, small.length - 2) +
        "." +
        small.substring(small.length - 2)
      )
    },
    request() {
      this.loading = true
      const data = {
        bookingId: this.booking.id,
        currency: this.currency
      }
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/confirmcashtransfer`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message)
          if (this.getBooking) this.getBooking()
          if (this.getBookings) this.getBookings()
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error)
          }
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>