<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <a href="/">
          <v-img
            v-if="includeTextLogo()"
            :src="require(`../assets/${getName()}-text.png`)"
            class="mt-2"
            contain
            height="125"
            style="cursor: pointer; padding-bottom: 5px;"
          />
          <v-img
            v-else
            :src="require(`../assets/${getName()}.png`)"
            class="mt-2"
            contain
            height="100"
            style="cursor: pointer;"
          />
        </a>
      </v-col>
      <v-col style="padding-top: 0px; padding-bottom: 0px;">
        <v-btn
          v-if="!includeTextLogo()"
          text
          x-large
          color="black"
          style="pointer-events: none;"
        >{{ getTitle() }}</v-btn>
        <div
          style="margin-top: -5px; margin-bottom: -10px;"
          class="text-body-2"
          v-if="phoneNumbers.length > 0"
        >
          <a
            v-for="phone in phoneNumbers"
            :href="'tel:' + phone"
            style="margin-right: 5px; text-decoration: none;"
          >
            <v-icon>mdi-phone</v-icon>
            {{ phone }}
          </a>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Header",
  data: () => ({
    phoneNumbers: []
  }),
  methods: {
    includeTextLogo() {
      return process.env.VUE_APP_TEXT_LOGO === "true" ? true : false
    },
    getName() {
      return process.env.VUE_APP_NAME ?? "bmt"
    },
    getTitle() {
      return process.env.VUE_APP_TITLE ?? "Book My Transfer"
    },
  },
  mounted() {
    let phone = process.env.VUE_APP_PHONE
    if (phone !== "") {
      phone = phone.split(",")
      phone = phone.map(p => p.trim())
      this.phoneNumbers = phone
    }
  }
}
</script>
