<template>
  <v-dialog v-model="visible" max-width="500" :retain-focus="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" outlined x-small v-bind="attrs" v-on="on"
        >Remove seats
      </v-btn>
    </template>
    <v-card style="padding: 30px">
      Are you sure to remove the selected seats from this booking for this trip?
      This will not cancel the booking.
      <div style="display: flex; justify-content: end; margin-top: 1rem">
        <v-btn color="primary" @click="update" :loading="updating"
          >Remove</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { apiRequest } from "../../helpers";

export default {
  name: "RemoveSeats",
  props: {
    booking: Object,
    trip: Object,
    message: Function,
    errorMessage: Function,
    getBooking: Function,
    getTripsParent: Function,
  },
  data: () => ({
    visible: false,
    updating: false,
  }),
  computed: {},
  methods: {
    update() {
      const endpoint = `/api/removeseats`;
      const data = {
        bookingId: this.booking.id,
        tripId: this.trip.id,
      };
      this.updating = true;
      const thenHandle = (resp) => {
        this.message(resp.data.message);
        this.visible = false;
        this.getBooking();
        this.getTripsParent();
      };
      const catchHandle = (error) => {
        if (error.response) {
          this.errorMessage(error.response.data.error);
        }
        this.updating = false;
      };
      apiRequest({
        endpoint,
        method: "POST",
        thenHandle,
        catchHandle,
        data,
      });
    },
  },
};
</script>
