<template>
  <v-dialog
    v-model="confirmDialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    width="100%"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item-title v-bind="attrs" v-on="on" style="cursor: pointer">
        <v-icon color="red" class="mb-1" style="margin-left: -11px"
          >mdi-exclamation</v-icon
        >Verify Transfer
      </v-list-item-title>
    </template>
    <v-card style="padding: 25px">
      <v-card elevation="0" outlined>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  min-width: 150px;
                "
              >
                <span style="font-weight: 600">Payment</span>
                <span>{{ decimalCurrency(booking.priceInLaari) }} MVR</span>
                <span>or</span>
                <span>{{ decimalCurrency(booking.priceInCent) }} USD</span>
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>
            <div
              style="
                display: flex;
                justify-content: space-between;
                min-width: 150px;
              "
            >
              <span style="font-weight: 600">Payment Slip</span>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <div
        v-if="booking.transfer.id !== 0"
        style="display: flex; justify-content: center"
      >
        <a :href="imageUrl" target="_blank">
          <img
            :src="`${imageUrl}?${booking.transfer.updated_at}`"
            style="max-width: 100%; max-height: 500px"
          />
        </a>
      </div>
      <v-radio-group v-model="currency" row>
        <v-radio key="1" label="MVR" value="MVR"></v-radio>
        <v-radio key="2" label="USD" value="USD"></v-radio>
      </v-radio-group>
      <v-text-field
        v-model="refNo"
        label="Reference number"
        outlined
        hide-details
      ></v-text-field>
      <v-row
        style="
          margin-top: 24px;
          margin-bottom: 0px;
          justify-content: space-between;
        "
      >
        <div></div>
        <div>
          <v-btn
            style="margin-right: 10px"
            color="red"
            outlined
            :loading="processing && type === 'Rejected'"
            @click="process('Rejected')"
            >Reject</v-btn
          >
          <v-btn
            style="margin-right: 12px"
            color="primary"
            outlined
            :loading="processing && type === 'Verified'"
            @click="process('Verified')"
            >Verify</v-btn
          >
        </div>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "BankTransfer",
  props: {
    booking: Object,
    message: Function,
    errorMessage: Function,
    getBookings: Function,
    getBooking: Function,
    getBookingsList: Function,
  },
  data: () => ({
    confirmDialog: null,
    processing: false,
    imageUrl: null,
    currency: "MVR",
    refNo: "",
    type: "",
  }),
  methods: {
    decimalCurrency(number) {
      const small = number.toString();
      return (
        small.substring(0, small.length - 2) +
        "." +
        small.substring(small.length - 2)
      );
    },
    process(status) {
      this.type = status;
      if (status !== "Verified" && status !== "Rejected") return;
      if (status === "Verified") {
        if (this.refNo.trim() === "") {
          this.errorMessage("Please enter a reference number.");
          return;
        }
      }
      this.processing = true;
      let data = {
        transferId: this.booking.transfer.id,
        status,
        currency: this.currency,
        refNo: this.refNo,
      };
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/processtransferslip`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getBookings();
          this.getBooking();
          if (this.getBookingsList) {
            this.getBookingsList();
          }
          this.confirmDialog = null;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.processing = false;
          this.type = "";
        });
    },
  },
  mounted() {
    if (this.booking.transfer) {
      this.imageUrl = `${process.env.VUE_APP_BASE_URL ?? ""}/img/paymentslip/${
        this.booking.uuid
      }`;
    }
  },
  watch: {},
};
</script>
