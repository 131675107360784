<template>
  <v-container fluid fill-height>
    <v-row justify="center" align="center">
      <v-snackbar v-model="errorSnackbar" top color="red" outlined>{{
        errorSnackbarText
      }}</v-snackbar>
      <v-col sm="6" md="4" lg="3" xl="3">
        <Header></Header>
        <div v-if="error || message">
          <v-alert v-if="message" style="margin-top: 1rem" type="info" outlined>
            {{ message }}
          </v-alert>
          <v-alert
            v-if="error"
            style="margin-top: 1rem"
            type="error"
            color="red"
            outlined
          >
            {{ error }}
          </v-alert>
          <v-btn block color="primary" class="py-6" depressed to="/login"
            >Go to Log In</v-btn
          >
        </div>
        <v-card v-else>
          <div>
            <v-card-text style="padding-top: 20px; margin-top: 20px">
              <v-form>
                <v-text-field
                  v-model="password"
                  label="New password"
                  type="password"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="passwordAgain"
                  label="Re-enter new password"
                  type="password"
                  outlined
                ></v-text-field>
              </v-form>
              <v-btn
                block
                color="primary"
                class="py-6"
                depressed
                @click="reset()"
                :loading="loading"
                style="margin-bottom: 20px"
                >Reset Password</v-btn
              >
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Header from "../components/Header.vue";
import { apiRequest } from "../helpers";
export default {
  name: "Reset",
  components: { Header },
  props: {
    uuid: String,
  },
  data: () => ({
    password: "",
    passwordAgain: "",
    errorSnackbar: false,
    errorSnackbarText: "",
    loading: false,
    error: "",
    message: "",
  }),
  methods: {
    errorMessage(message) {
      this.errorSnackbarText = message;
      this.errorSnackbar = true;
      if (message === "Token expired.") {
        this.$store.dispatch("logout");
      }
    },
    onlyDigits(e) {
      this.phone = e.replace(/\D/g, "");
    },
    getReset() {
      const endpoint = `/api/getpasswordreset`;
      const data = {
        uuid: this.uuid,
      };
      this.loading = true;
      const catchHandle = (error) => {
        if (error.response) {
          this.error = error.response.data.error;
        }
      };
      const finallyHandle = () => {
        this.loading = false;
      };
      apiRequest({
        endpoint,
        method: "POST",
        catchHandle,
        finallyHandle,
        data,
      });
    },
    reset() {
      if (this.password !== this.passwordAgain) {
        this.errorMessage("Passwords do not match.");
        return;
      }
      const endpoint = `/api/resetpassword`;
      const data = {
        uuid: this.uuid,
        password: this.password,
      };
      this.loading = true;
      const thenHandle = (resp) => {
        this.message = resp.data.message;
      };
      const catchHandle = (error) => {
        if (error.response) {
          this.errorMessage(error.response.data.error);
        }
      };
      const finallyHandle = () => {
        this.loading = false;
      };
      apiRequest({
        endpoint,
        method: "POST",
        thenHandle,
        catchHandle,
        finallyHandle,
        data,
      });
    },
  },
  mounted() {
    if (this.$store.getters.isLoggedIn) {
      this.$router.push("/login");
    }
    this.getReset();
  },
};
</script>

<style></style>
