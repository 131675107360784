<template>
    <div id="background">
        <Header />
        <v-row justify="center" style="width:100%;margin:0px">
            <v-col
                align-self="center"
                xs="12"
                sm="12"
                md="8"
                lg="6"
                xl="4"
                style="padding-right:0px; padding-left: 0px"
            >
                <v-container>
                    <v-card class="text-justify">
                        <v-card-title class="text-h5">About Us</v-card-title>
                        <v-card-text>Book My Transfer is an application designed by Techverin Private Limited.</v-card-text>
                        <v-card-text>Techverin Private Limited is a software company based in the Maldives.</v-card-text>
                        <v-card-text>
                            <div>
                                Website:
                                <a href="https://techverin.com" target="_blank">techverin.com</a>
                            </div>
                            <div>
                                Phone:
                                <a href="tel:+960 7568118">+960 7568118</a>
                            </div>
                            <div>
                                Email:
                                <a href="mailto:hello@techverin.com">hello@techverin.com</a>
                            </div>
                            <div>
                                <a
                                    href="/registration.jpg"
                                    target="_blank"
                                >Business Activity Registration</a>
                            </div>
                            <div>
                                <a href="/gst.pdf" target="_blank">GST Registration</a>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-container>
            </v-col>
        </v-row>
        <Footer />
    </div>
</template>

<script>
import Footer from "../components/Footer.vue"
import Header from "../components/Header"

export default {
    name: "About",

    components: {
        Header,
        Footer
    },
}
</script>

<style>
html,
html,
body {
    margin: 0;
    padding: 0;
}
#app {
    background-color: #f2f3f6;
}
