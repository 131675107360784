var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',[(_vm.originalSelectedNames)?_c('span',{staticStyle:{"margin-right":"1rem","opacity":"0.7"}},[_vm._v("Seats: "+_vm._s(_vm.originalSelectedNames))]):_vm._e(),(
          !_vm.booking.trip.departed &&
          !_vm.booking.trip.ended &&
          _vm.isBeforeNow(_vm.booking.trip.fromTime) &&
          !_vm.booking.isCancelled &&
          (_vm.booking.isPaid || _vm.booking.isAllowed || _vm.booking.isCashAllowed)
        )?_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","outlined":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-seat-passenger")]),_vm._v(" Select seats ")],1):_vm._e()],1)]}}]),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',{staticStyle:{"padding":"30px"}},[_vm._v(" Seat selection "),_c('div',{style:({
        display: 'flex',
        flexDirection: _vm.$vuetify.breakpoint.smAndDown ? 'column' : 'row',
        gap: '1rem',
        marginTop: '1rem',
        marginBottom: '1rem',
      })},[_c('v-text-field',{attrs:{"value":_vm.bookedCount,"label":"Booked Seats","disabled":"","type":"number","hide-details":""}}),_c('v-text-field',{attrs:{"value":_vm.availableCount,"label":"Remaing Seats","disabled":"","type":"number","hide-details":""}}),_c('v-text-field',{attrs:{"value":_vm.selectedNames,"label":_vm.selected.length > 0 ? 'Selected Seats' : 'No seats selected',"disabled":"","hide-details":""}})],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(_vm.rows)?_c('div',{staticStyle:{"display":"flex","justify-content":"center","opacity":"0.3","font-weight":"bold"}},[_vm._v(" FRONT ")]):_vm._e(),(_vm.rows)?_c('div',{staticStyle:{"display":"flex","flex-direction":"column","gap":"5px","overflow":"auto"}},_vm._l((_vm.rows),function(row){return _c('div',{key:row.no,staticStyle:{"display":"flex","justify-content":"space-around","gap":"5px"}},_vm._l((row.columns),function(column){return _c('div',{key:column.no,style:({
            'border-radius': '5px',
            border: column.active ? 'solid 1px grey' : undefined,
            padding: `2px`,
            width: '100%',
            maxWidth: '100px',
            maxHeight: '100px',
            aspectRatio: '1 / 1',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: _vm.backgroundColor(column),
            cursor: _vm.color(column),
            color: column.active ? undefined : 'white',
          }),on:{"click":function($event){return _vm.cellHandle(column)}}},[_vm._v(" "+_vm._s(column.name)+" ")])}),0)}),0):_vm._e(),(_vm.rows)?_c('div',{staticStyle:{"display":"flex","justify-content":"center","opacity":"0.3","font-weight":"bold","margin-bottom":"1rem"}},[_vm._v(" BACK ")]):_vm._e(),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","margin-top":"1rem","gap":"1rem","flex-wrap":"wrap"}},[(_vm.rows)?_c('div',{staticStyle:{"display":"flex","gap":"1rem","flex-wrap":"wrap"}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.clear()}}},[_vm._v("Clear")]),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.visible = false}}},[_vm._v("Close")])],1):_c('div'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.updating,"disabled":!_vm.changed || _vm.loading},on:{"click":_vm.update}},[_vm._v("Select")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }