<template>
  <v-card>
    <v-tabs
      show-arrows
      center-active
      v-model="tab"
      :style="{
        display: routesEnabled ? undefined : 'none',
      }"
    >
      <v-tab>Locations</v-tab>
      <v-tab>Routes</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="locations">
        <div style="padding: 1rem">
          <v-dialog
            v-model="newLocationMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            width="100%"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                outlined
                block
                large
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                >Add Location
              </v-btn>
            </template>
            <v-card style="padding: 30px">
              <v-row>
                <v-col class="noHorizontalPadding">
                  <v-text-field
                    v-model="newLocation.name"
                    label="Location name"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin-top: -10px; justify-content: space-between">
                <div></div>
                <v-btn
                  outlined
                  color="primary"
                  :loading="addingLocation"
                  @click="addLocation"
                  >Add Location
                </v-btn>
              </v-row>
            </v-card>
          </v-dialog>
          <v-progress-linear
            v-if="locationsLoading"
            indeterminate
            color="primary"
          ></v-progress-linear>
          <v-card
            flat
            color="grey lighten-4"
            class="mb-2"
            ripple
            v-for="(location, index) in locations"
            :key="location.id"
          >
            <v-card-text>
              <v-row justify="center" align="center">
                <v-col>
                  <div class="title text-center">
                    {{ location.name }}
                  </div>
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                    class="text-center"
                  >
                    <v-dialog max-width="400" :retain-focus="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="red" icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-text
                          style="padding-bottom: 0px; padding-top: 20px"
                          >Are you sure to delete
                          {{ location.name }}?</v-card-text
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            text
                            :loading="deletingLocation"
                            @click="deleteLocation(location)"
                            >Delete</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog max-width="400" :retain-focus="false">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <v-card style="padding: 30px 30px 0px 30px">
                        <v-row>
                          <v-col class="noHorizontalPadding">
                            <v-text-field
                              v-model="location.name"
                              label="Location name"
                              outlined
                              hide-details
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-card-actions
                          style="margin-right: -20px; padding-right: 0px"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            text
                            :loading="editingLocation"
                            @click="editLocation(location)"
                            >Edit</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <AddPoint
                      :location="location"
                      :getLocations="getLocations"
                      :message="message"
                      :errorMessage="errorMessage"
                    />
                    <v-switch
                      v-model="location.public"
                      label="Public"
                      @change="toggleLocationPublic(location)"
                      style="margin-top: 0px"
                      hide-details
                    >
                    </v-switch>
                  </div>
                  <div
                    v-if="location.points && location.points.length > 0"
                    class="text-center"
                    style="margin-top: 1rem"
                  >
                    <div
                      v-for="point in location.points"
                      style="margin-top: 0.5rem"
                    >
                      {{ point.name }}
                      <EditPoint
                        :point="point"
                        :getLocations="getLocations"
                        :message="message"
                        :errorMessage="errorMessage"
                      />
                      <DeletePoint
                        :point="point"
                        :getLocations="getLocations"
                        :message="message"
                        :errorMessage="errorMessage"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-tab-item>
      <v-tab-item key="routes">
        <Routes
          :locations="locations"
          :message="message"
          :errorMessage="errorMessage"
          :locationsLoading="locationsLoading"
          :routes="routes"
          :getRoutes="getRoutes"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import axios from "axios";
import { configEnabled } from "../../helpers";
import AddPoint from "../Admin/Point/AddPoint.vue";
import DeletePoint from "../Admin/Point/DeletePoint.vue";
import EditPoint from "../Admin/Point/EditPoint.vue";
import Routes from "./Routes/Routes.vue";

export default {
  name: "Locations",
  components: { DeletePoint, EditPoint, AddPoint, Routes },
  props: {
    locations: Array,
    getLocations: Function,
    locationsLoading: Boolean,
    message: Function,
    errorMessage: Function,
    configs: Array,
    routes: Array,
    getRoutes: Function,
  },
  data: () => ({
    tab: "locations",
    newLocationMenu: null,
    newLocation: {
      name: "",
    },
    addingLocation: false,
    editingLocation: false,
    deletingLocation: false,
    routesEnabled: false,
  }),
  methods: {
    configEnabled(name) {
      return configEnabled(name, this.configs);
    },
    addLocation() {
      if (this.newLocation.name.trim() === "") {
        this.errorMessage("Please enter a location name.");
        return;
      }
      const data = {
        name: this.newLocation.name.trim(),
      };
      this.addingLocation = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/createlocation`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getLocations();
          this.newLocationMenu = false;
          this.newLocation.name = "";
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.addingLocation = false));
    },
    deleteLocation(location, index) {
      const data = {
        locationId: location.id,
      };
      this.deletingLocation = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/deletelocation`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getLocations();
          this.deleteLocationDialogs[index] = false;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.deletingLocation = false));
    },
    editLocation(location) {
      if (location.name.trim() === "") {
        this.errorMessage("Please enter a location name.");
        return;
      }
      const data = {
        locationId: location.id,
        name: location.name,
      };
      this.editingLocation = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/editlocation`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.locations = [];
          this.getLocations();
          this.getTrips();
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.editingLocation = false));
    },
    toggleLocationPublic(location) {
      const data = {
        locationId: location.id,
        public: location.public,
      };
      this.editingLocation = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/togglelocationpublic`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.locations = [];
          this.getLocations();
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.editingLocation = false));
    },
  },
  mounted() {
    if (this.routesEnabled) return;
    const routesEnabled = configEnabled(
      "Enable multi-stop feature",
      this.configs
    );
    this.routesEnabled = routesEnabled;
  },
  watch: {
    configs(a) {
      if (this.routesEnabled) return;
      const routesEnabled = configEnabled("Enable multi-stop feature", a);
      this.routesEnabled = routesEnabled;
    },
  },
};
</script>
