import Vue from "vue";
import VueRouter from "vue-router";
import About from "../views/About.vue";
import Admin from "../views/Admin.vue";
import Booking from "../views/Booking.vue";
import Captain from "../views/Captain.vue";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import Reset from "../views/Reset.vue";
import TOC from "../views/TOC.vue";
import TripPayments from "../views/TripPayments.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/terms-and-conditions",
    name: "TOC",
    component: TOC,
  },
  {
    path: "/booking/:uuid",
    component: Booking,
    props: true,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/reset/:uuid",
    name: "Reset",
    component: Reset,
    props: true,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
  },
  {
    path: "/captain",
    name: "Captain",
    component: Captain,
  },
  {
    path: "/trip-payments/:tripId",
    name: "Trip Payments",
    component: TripPayments,
    props: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   // if (from.name != "Login" && from.name != "Register") {
//   //   localStorage.setItem("prevRoute", from.fullPath);
//   // }
//   // if (to.name == "Login" || to.name == "Register") {
//   //   if (store.getters.isLoggedIn) {
//   //     next("/");
//   //   }
//   // }
// });

export default router;
