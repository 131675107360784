<template>
  <div>
    <a style="text-decoration: none" href="/admin?tab=bookings&status=Pending Verification">
      <v-alert outlined type="info">{{ pendingText }}</v-alert>
    </a>
  </div>
</template>

<script>
export default {
  name: "PendingVerifications",
  props: {
    pendings: Array
  },
  computed: {
    pendingText() {
      return `${this.pendings.length} pending bank transfer verification${this.pendings.length === 1 ? "" : "s"}.`
    },
  }
}
</script>