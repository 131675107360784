<template>
  <v-dialog
    v-model="confirmDialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    width="100%"
    max-width="600px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        v-bind="attrs"
        v-on="on"
        x-small
        text
        style="padding: 5px"
        title="Edit customers"
      >
        <v-icon style="font-size: 18px">mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card style="padding: 25px">
      <v-row style="margin-top: 0px">
        <v-card-text style="padding-top: 0px"
          >Edit customers in booking #{{ booking.refNo }}</v-card-text
        >
      </v-row>
      <div style="display: flex; flex-direction: column; gap: 0.5rem">
        <div
          v-for="(customer, index) in customers"
          :key="customer.id"
          style="display: flex; gap: 0.5rem; align-items: center"
        >
          <v-select
            outlined
            :items="ageGroups"
            v-model="customer.ageGroupId"
            item-text="name"
            item-value="id"
            hide-details
            style="width: 15%"
          ></v-select>
          <v-text-field
            v-model="customer.name"
            outlined
            hide-details
          ></v-text-field>
          <v-btn
            color="red"
            @click="remove(index)"
            icon
            small
            title="Remove customer"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </div>
        <v-btn color="primary" @click="add()" text title="Add customer">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <div style="display: flex; justify-content: end">
          <v-btn
            color="primary"
            outlined
            :loading="editing"
            @click="editCustomers()"
            >Update</v-btn
          >
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "EditCustomers",
  props: {
    booking: Object,
    message: Function,
    errorMessage: Function,
    getBooking: Function,
  },
  data: () => ({
    confirmDialog: null,
    editing: false,
    ageGroupsLoading: false,
    customers: [],
    ageGroups: [],
  }),
  computed: {},
  methods: {
    editCustomers() {
      const data = {
        id: this.booking.id,
        customers: this.customers,
      };
      this.editing = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/editcustomers`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data,
      })
        .then((resp) => {
          // this.message(resp.data.message);
          this.getBooking();
          this.confirmDialog = false;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.editing = false));
    },
    getAgeGroups() {
      this.ageGroupsLoading = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/getagegroups`,
        method: "GET",
      })
        .then((resp) => {
          this.ageGroups = resp.data.ageGroups;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.ageGroupsLoading = false;
        });
    },
    add() {
      const firstType = this.ageGroups[0];
      const countOfType = this.customers.filter(
        (c) => c.ageGroupId === firstType.id
      ).length;
      this.customers.push({
        ageGroupId: firstType.id,
        name: `${firstType.name} ${countOfType + 1}`,
      });
    },
    remove(index) {
      this.customers.splice(index, 1);
    },
  },
  watch: {
    confirmDialog: {
      handler: function () {
        if (this.confirmDialog) {
          this.customers = [...this.booking.customers];
          this.getAgeGroups();
        }
      },
    },
  },
};
</script>
