<template>
  <v-dialog
    v-model="confirmDialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    width="100%"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on" icon>
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card style="padding: 30px">
      <v-select
        outlined
        :items="boats"
        label="Boat"
        item-text="name"
        v-model="newBoat"
        return-object
        hide-details
      >
        <template v-slot:item="{ item }">{{ item.name }}</template>
        <template v-slot:selection="{ item }">{{ item.name }}</template>
      </v-select>
      <v-alert
        v-if="seatConfig"
        outlined
        type="warning"
        color="orange"
        style="margin-top: 1rem"
        >Changing the boat will reset seats selected by customers.</v-alert
      >
      <div style="display: flex; justify-content: end">
        <v-btn color="primary" outlined :loading="editing" @click="edit()"
          >Update</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  name: "ChangeTripBoat",
  props: {
    trip: Object,
    getTrips: Function,
    boats: Array,
    message: Function,
    errorMessage: Function,
    seatConfig: Boolean,
  },
  data: () => ({
    confirmDialog: null,
    tripClone: null,
    differentLocalPrice: null,
    editing: false,
    newBoat: null,
  }),
  methods: {
    edit() {
      const data = {
        tripId: this.trip.id,
        boatId: this.newBoat.id,
      };
      this.editing = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/changetripboat`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getTrips();
          this.confirmDialog = false;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.editing = false));
    },
  },
  created() {
    this.newBoat = this.boats.find((boat) => boat.id === this.trip.boat.id);
  },
};
</script>

<style scoped>
.noHorizontalPadding {
  padding-left: 0px;
  padding-right: 0px;
}
.noVerticalPadding {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
