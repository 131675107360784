<template>
  <v-dialog
    v-model="newTripMenu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    width="100%"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" class="ml-2" outlined small v-bind="attrs" v-on="on">Add Trip</v-btn>
    </template>
    <v-card style="padding:30px">
      <v-row>
        <v-col class="noHorizontalPadding">
          <v-card width="100%" elevation="0" outlined>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-wrap">{{ from.name }} to {{ to.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ computedDate }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ preferredTime }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="noHorizontalPadding">
          <v-select
            outlined
            :items="boats"
            label="Boat"
            v-model="boat"
            item-text="name"
            return-object
          >
            <template v-slot:item="{ item }">{{ item.name }}</template>
            <template v-slot:selection="{ item }">{{ item.name }}</template>
          </v-select>
        </v-col>
      </v-row>
      <v-row align="center" style="margin-top:-30px">
        <v-col cols="6" class="noHorizontalPadding">
          <v-text-field
            v-model="usdPerSeat"
            label="Price per seat"
            outlined
            type="number"
            suffix="USD"
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="noHorizontalPadding">
          <v-text-field v-model="mvrPerSeat" label outlined type="number" suffix="MVR"></v-text-field>
        </v-col>
      </v-row>
      <v-row style="margin-top:-60px">
        <v-col class="noHorizontalPadding">
          <v-checkbox v-model="differentLocalPrice" label="Different price for locals"></v-checkbox>
        </v-col>
      </v-row>
      <v-row v-if="differentLocalPrice" align="center" style="margin-top:-30px">
        <v-col cols="6" class="noHorizontalPadding">
          <v-text-field
            v-model="usdPerSeatLocal"
            label="Price per seat for locals"
            outlined
            type="number"
            suffix="USD"
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="noHorizontalPadding">
          <v-text-field v-model="mvrPerSeatLocal" label outlined type="number" suffix="MVR"></v-text-field>
        </v-col>
      </v-row>
      <v-row style="margin-top:-30px">
        <v-col cols="5.5" class="noHorizontalPadding">
          <v-dialog v-model="fromTimeModal" width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="fromTime"
                label="Departure"
                prepend-inner-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="fromTimeModal"
              v-model="fromTime"
              full-width
              format="24hr"
              @click:minute="fromTimeModal = false"
            ></v-time-picker>
          </v-dialog>
        </v-col>
        <v-col cols="5.5" class="noHorizontalPadding">
          <v-dialog v-model="toTimeModal" width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="toTime"
                label="Arrival"
                prepend-inner-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="toTimeModal"
              v-model="toTime"
              full-width
              format="24hr"
              @click:minute="toTimeModal = false"
            ></v-time-picker>
          </v-dialog>
        </v-col>
      </v-row>Creating this trip will send a notification to the waitlisted customers in the time slot.
      <v-row style="margin-top:-10px; justify-content:space-between">
        <div></div>
        <v-btn outlined color="primary" :loading="addingTrip" @click="addTrip">Add Trip</v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment"
import axios from "axios"

export default {
  name: "AddTripForWaitlist",
  props: {
    boats: Array,
    from: Object,
    to: Object,
    date: String,
    computedDate: String,
    preferredTime: String,
    getTrips: Function,
    message: Function,
    errorMessage: Function,
    getWaitlists: Function,
    configs: Array,
  },
  data: () => ({
    newTripMenu: null,
    fromTimeModal: null,
    toTimeModal: null,
    boat: null,
    fromTime: null,
    toTime: null,
    addingTrip: false,
    usdPerSeat: 20,
    mvrPerSeat: 20 * 15.42,
    usdPerSeatLocal: 20,
    mvrPerSeatLocal: 20 * 15.42,
    differentLocalPrice: false,
  }),
  methods: {
    addTrip() {
      if (!this.boat) {
        this.errorMessage("No boat chosen.")
        return
      }
      if (!this.from || !this.to) {
        this.errorMessage("Both locations not selected.")
        return
      }
      if (!this.fromTime || !this.toTime) {
        this.errorMessage("Both times not selected.")
        return
      }
      const data = {
        preferredTime: this.preferredTime,
        date: moment(
          this.date,
          "YYYY-MM-DD").startOf("day"),
        boatId: this.boat.id,
        fromLocationId: this.from.id,
        toLocationId: this.to.id,
        fromTime: moment(
          `${this.date} ${this.fromTime}`,
          "YYYY-MM-DD HH:mm"
        ),
        toTime: moment(
          `${this.date} ${this.toTime}`,
          "YYYY-MM-DD HH:mm"
        ),
        usdPerSeat: Math.round(this.usdPerSeat * 100) / 100,
        mvrPerSeat: Math.round(this.mvrPerSeat * 100) / 100
      }
      if (data.toTime.isSameOrBefore(data.fromTime, "minute")) {
        this.errorMessage("Arrival should be before departure.")
        return
      }
      if (this.differentLocalPrice) {
        data.usdPerSeatLocal = Math.round(this.usdPerSeatLocal * 100) / 100
        data.mvrPerSeatLocal = Math.round(this.mvrPerSeatLocal * 100) / 100
      }
      data.date = data.date.toISOString()
      data.fromTime = data.fromTime.toISOString()
      data.toTime = data.toTime.toISOString()
      this.addingTrip = true
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/createtripforwaitlistslot`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message)
          this.getTrips()
          this.getWaitlists()
          this.newTripMenu = false
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error)
          }
        })
        .finally(() => (this.addingTrip = false))
    },
  },
  mounted() {
    this.configs.forEach(c => {
      if (c.name === "CentPerSeat") {
        this.usdPerSeat = parseInt(c.value) / 100
        this.usdPerSeatLocal = parseInt(c.value) / 100
      } else if (c.name === "LaariPerSeat") {
        this.mvrPerSeat = parseInt(c.value) / 100
        this.mvrPerSeatLocal = parseInt(c.value) / 100
      }
    })
  },
  watch: {
    fromTime: {
      handler: function () {
        this.toTime = moment(
          `${this.date} ${this.fromTime}`,
          "YYYY-MM-DD HH:mm"
        )
          .add(30, "minutes")
          .format("HH:mm")
      },
    },
    usdPerSeat: {
      handler: function () {
        this.usdPerSeat = Math.round(this.usdPerSeat * 100) / 100
      },
    },
  },
}
</script>