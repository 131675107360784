<template>
  <span>
    {{ text }}
  </span>
</template>

<script>
import axios from "axios";
export default {
  name: "SeatBreakdown",
  props: {
    bookings: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      confirmDialog: null,
      ageGroupsLoading: false,
      ageGroups: [],
      text: "",
    };
  },
  methods: {
    getAgeGroupCounts() {
      const ageGroupCounts = {};
      for (const ageGroup of this.ageGroups) {
        ageGroupCounts[ageGroup.name] = 0;
        for (const booking of this.bookings) {
          if (
            !booking.isCancelled &&
            (booking.isPaid || booking.isAllowed || booking.isCashAllowed)
          ) {
            for (const customer of booking.customers) {
              if (customer.ageGroupId === ageGroup.id) {
                ageGroupCounts[ageGroup.name]++;
              }
            }
          }
        }
      }
      const texts = [];
      for (const [key, value] of Object.entries(ageGroupCounts)) {
        texts.push(`${key}: ${value}`);
      }
      this.text += texts.join(", ");
      if (this.text !== "") {
        this.text = `(${this.text})`;
      }
    },
    getAgeGroups() {
      this.ageGroupsLoading = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/getagegroups`,
        method: "GET",
      })
        .then((resp) => {
          this.ageGroups = resp.data.ageGroups;
        })
        .finally(() => {
          this.ageGroupsLoading = false;
          this.getAgeGroupCounts();
        });
    },
  },
  mounted() {
    this.getAgeGroups();
  },
};
</script>
