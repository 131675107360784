import axios from "axios";
import moment from "moment";

export const MAX_SEAT_ROWS = 100;
export const MAX_SEAT_COLS = 100;

export const DT_FORMATS = {
  time12hr: "h:mm a",
  time24hr: "HH:mm",
};

export function decimalCurrency(number) {
  if (number === 0) return "0.00";
  const small = number.toString();
  return (
    small.substring(0, small.length - 2) +
    "." +
    small.substring(small.length - 2)
  );
}

export function apiRequest({
  endpoint,
  method,
  thenHandle,
  catchHandle,
  finallyHandle,
  data,
}) {
  axios({
    url: `${process.env.VUE_APP_BASE_URL ?? ""}${endpoint}`,
    method,
    headers: { Authorization: localStorage.getItem("Authorization") },
    data,
  })
    .then((resp) => (thenHandle ? thenHandle(resp) : null))
    .catch((error) => (catchHandle ? catchHandle(error) : null))
    .finally(() => (finallyHandle ? finallyHandle() : null));
}

export function toFromString(trip, configs) {
  let format = DT_FORMATS.time12hr;
  if (configEnabled("Use 24 hour clock", configs)) {
    format = DT_FORMATS.time24hr;
  }
  return `${moment(trip.fromTime).format(format)} - ${moment(
    trip.toTime
  ).format(format)}`;
}

export function getDatePortion(time) {
  return moment(time).format("dddd, D MMMM YYYY");
}

export function numberToColumn(number) {
  let result = "";
  while (number > 0) {
    const modulo = (number - 1) % 26;
    result = String.fromCharCode(65 + modulo) + result;
    number = Math.floor((number - modulo) / 26);
  }
  return result;
}

export function cellPadding(columnCount) {
  let multiplier = columnCount > 0 ? columnCount : 1;
  let c = 40 / multiplier ** 0.7;
  return c > 2 ? c : 2;
}

export const BOOKING_PAYMENT_TYPES = [
  "Free of charge",
  "Card",
  "Transfer",
  "Cash",
  "Credit",
  "Pre-paid",
];

export function filterBookings(currency, bookings, type, onlyPaid) {
  if (onlyPaid) {
    bookings = bookings.filter(
      (booking) => booking.isPaid || booking.isAllowed
    );
  }
  switch (type) {
    case "Card":
      return bookings.filter(
        (booking) =>
          booking.isPaid &&
          (!booking.transfer || booking.transfer.id === 0) &&
          (!booking.cashPayment || booking.cashPayment.id === 0) &&
          ((currency === "MVR" && booking.paidInMVR) ||
            (currency === "USD" && booking.paidInUSD))
      );
    case "Transfer":
      return bookings.filter(
        (booking) =>
          booking.transfer &&
          booking.transfer.status === "Verified" &&
          ((currency === "MVR" && booking.paidInMVR) ||
            (currency === "USD" && booking.paidInUSD))
      );
    case "Cash":
      return bookings.filter(
        (booking) =>
          booking.cashPayment &&
          booking.cashPayment.status === "Paid" &&
          ((currency === "MVR" && booking.paidInMVR) ||
            (currency === "USD" && booking.paidInUSD))
      );
    case "Credit":
      return bookings.filter(
        (booking) =>
          booking.isAllowed &&
          booking.allowedType === "Credit" &&
          ((currency === "MVR" && booking.paidInMVR) ||
            (currency === "USD" && booking.paidInUSD))
      );
    case "Pre-paid":
      return bookings.filter(
        (booking) =>
          booking.isAllowed &&
          booking.allowedType === "Pre-paid" &&
          ((currency === "MVR" && booking.paidInMVR) ||
            (currency === "USD" && booking.paidInUSD))
      );
    case "Free of charge":
      return bookings.filter(
        (booking) => booking.isAllowed && booking.allowedType === ""
      );
    default:
      return bookings;
  }
}

export function configEnabled(name, configs) {
  const found = configs?.find((c) => c.name === name);
  if (found?.value === "true") return true;
  return false;
}

export function findTripStartTime(trip, toStop) {
  const fromTime = moment(trip.fromTime);
  if (!trip || !trip.route || !toStop) return fromTime;
  if (toStop.routeStop.order !== 1) {
    for (const stop of trip.route.stops) {
      if (stop.order === 1) {
        for (const ts of stop.toStops) {
          if (ts.order !== toStop.routeStop.order) continue;
          fromTime.add(ts.minutes, "minutes");
        }
        break;
      }
    }
  }
  return fromTime;
}

export function bookedCount(trip) {
  let count;
  if (trip.routeId) {
    const net = [];
    let current = 0;
    for (const [i, stop] of trip.route.stops.entries()) {
      if (i === trip.route.stops.length - 1) break;
      current += stop.boarded - stop.left;
      net.push(current);
    }
    count = `(${net.join(",")})`;
  } else {
    count = trip.booked;
  }
  return count;
}
