<template>
  <v-dialog
    v-model="confirmDialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="400px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="red" icon v-bind="attrs" v-on="on">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text style="padding-bottom: 0px; padding-top: 20px"
        >Are you sure to delete?</v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text :loading="loading" @click="deleteRecord()"
          >Delete</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { apiRequest } from "../../helpers";
export default {
  name: "DeleteRecord",
  props: {
    data: Object,
    refetchQueries: Array,
    endpoint: String,
    message: Function,
    errorMessage: Function,
  },
  data: () => ({
    confirmDialog: null,
    loading: false,
  }),
  methods: {
    deleteRecord() {
      this.loading = true;
      const thenHandle = (resp) => {
        this.message(resp.data.message);
        this.confirmDialog = false;
        for (const query of this.refetchQueries) {
          query();
        }
      };
      const catchHandle = (error) => {
        if (error.response) {
          this.errorMessage(error.response.data.error);
        }
      };
      const finallyHandle = () => {
        this.loading = false;
      };
      apiRequest({
        endpoint: this.endpoint,
        method: "POST",
        thenHandle,
        catchHandle,
        finallyHandle,
        data: this.data,
      });
    },
  },
};
</script>
