<template>
  <div>
    <v-icon dense>mdi-gas-station</v-icon>
    <span
      v-if="trip.fuelConsumptionLitres"
      :title="
        trip.fuelConsumptionAddedBy
          ? `updated by ${trip.fuelConsumptionAddedBy.username}`
          : ''
      "
    >
      {{ trip.fuelConsumptionLitres }} litres
    </span>
    <v-dialog
      v-if="!hideButton"
      v-model="confirmDialog"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      width="100%"
      max-width="500px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" v-bind="attrs" v-on="on" icon>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card style="padding: 25px">
        <v-text-field
          v-model="fuelConsumpton"
          label="Fuel consumption"
          outlined
          type="number"
          suffix="litres"
          hide-details
        ></v-text-field>
        <div style="display: flex; justify-content: flex-end; margin-top: 1rem">
          <v-btn
            outlined
            color="primary"
            :loading="loading"
            @click="addFuelConsumption"
            >Update</v-btn
          >
        </div></v-card
      >
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "FuelConsumption",
  props: {
    trip: Object,
    message: Function,
    errorMessage: Function,
    getTrips: Function,
    hideButton: Boolean,
  },
  data: () => ({
    confirmDialog: null,
    fuelConsumpton: null,
    loading: false,
  }),
  methods: {
    addFuelConsumption() {
      const floated = parseFloat(this.fuelConsumpton);
      if (isNaN(floated)) return;
      this.loading = true;
      const data = {
        tripId: this.trip.id,
        fuelConsumption: floated,
      };
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/addfuelconsumption`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getTrips();
          this.confirmDialog = null;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    if (this.trip?.fuelConsumptionLitres) {
      this.fuelConsumpton = this.trip.fuelConsumptionLitres;
    }
  },
};
</script>
