<template>
  <div>
    <a style="text-decoration: none" href="/admin?tab=privateTransfers&status=Pending">
      <v-alert outlined type="info">{{ pendingText }}</v-alert>
    </a>
  </div>
</template>

<script>
export default {
  name: "PendingPrivateTransfers",
  props: {
    pendings: Array
  },
  computed: {
    pendingText() {
      return `${this.pendings.length} pending private transfer request${this.pendings.length === 1 ? "" : "s"}.`
    },
  },
}
</script>