<template>
  <div>
    <table style="width: 100%; border-collapse: collapse">
      <thead>
        <tr>
          <th></th>
          <th><v-icon>mdi-clock</v-icon></th>
          <th><v-icon>mdi-plus</v-icon></th>
          <th><v-icon>mdi-minus</v-icon></th>
          <th><v-icon>mdi-account</v-icon></th>
        </tr>
      </thead>
      <tbody v-for="(stop, i) in stopsComputed">
        <tr>
          <td>{{ stop.location.name }}</td>
        </tr>
        <tr
          v-if="i < stopsComputed.length - 1"
          style="background-color: rgb(235, 235, 235)"
        >
          <td></td>
          <td style="text-align: center">
            {{ stop.fromTime.format("h:mm a") }}
          </td>
          <td style="text-align: center">{{ stop.boarded }}</td>
          <td style="text-align: center">{{ stop.left }}</td>
          <td style="text-align: center">{{ stop.net }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "TripRouteCounts",
  props: {
    trip: Object,
  },
  data: () => ({}),
  computed: {
    stopsComputed() {
      const s = this.trip.route.stops;
      const order1ToStops = this.trip.route.stops[0].toStops;
      let current = 0;
      for (const stop of s) {
        current += stop.boarded - stop.left;
        stop.net = current;
        const fromTime = moment(this.trip.fromTime);
        if (stop.order !== 1) {
          const ts = order1ToStops.find((o) => o.order === stop.order);
          if (!ts) continue;
          fromTime.add(ts.minutes, "minutes");
        }
        stop.fromTime = fromTime;
      }
      return s;
    },
  },

  methods: {},
  watch: {},
};
</script>
