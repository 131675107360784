import axios from "axios";

const state = {
  token: localStorage.getItem("Authorization") || "",
  status: "",
  user: null,
  configs: [],
};
const getters = {
  isLoggedIn: (state) => !!state.token,
  authStatus: (state) => state.status,
  getToken: (state) => state.token,
  getUser: (state) => state.user,
  getConfigs: (state) => state.configs,
};
const actions = {
  login: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      commit("auth_request");
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/login`,
        data: payload,
        method: "POST",
      })
        .then((resp) => {
          const token = resp.headers.authorization;
          localStorage.setItem("Authorization", token);
          commit("auth_success", {
            token,
            user: resp.data,
          });
          resolve(resp);
        })
        .catch((err) => {
          commit("auth_error");
          localStorage.removeItem("Authorization");
          reject(err.response.data);
        });
    });
  },
  configure: ({ commit }, payload) => {
    commit("setConfig", {
      payload,
    });
  },
  logout({ commit }) {
    return new Promise((resolve) => {
      commit("logout");
      localStorage.removeItem("Authorization");
      resolve();
    });
  },
  authenticate: ({ commit }, payload) => {
    commit("auth_success", {
      token: payload.token,
      user: payload.user,
    });
  },
};
const mutations = {
  auth_success(state, data) {
    state.status = "success";
    state.token = data.token;
    state.user = data.user;
  },
  auth_error(state) {
    state.status = "error";
  },
  auth_request(state) {
    state.status = "loading";
  },
  logout(state) {
    state.status = "";
    state.token = "";
    state.user = null;
  },
  setConfig(state, data) {
    state.configs = data.payload.configs;
  },
};

export default { state, getters, actions, mutations };
