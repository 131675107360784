<template>
  <v-dialog v-model="visible" max-width="600" :retain-focus="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" icon v-bind="attrs" v-on="on">
        <v-icon>mdi-seat-passenger</v-icon>
      </v-btn>
    </template>
    <v-card style="padding: 30px">
      {{ trip.boat.name }} seat selection
      <div
        style="display: flex; gap: 1rem; margin-top: 1rem; margin-bottom: 1rem"
      >
        <v-text-field
          :value="trip.boat.capacity"
          label="Capacity"
          disabled
          type="number"
          hide-details
        />
        <v-text-field
          :value="trip.booked"
          label="Booked Seats"
          disabled
          type="number"
          hide-details
        />
        <v-text-field
          :value="occupiedCount"
          label="Occupied Seats"
          disabled
          type="number"
          hide-details
        />
      </div>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <div
        v-if="rows"
        style="
          display: flex;
          justify-content: center;
          opacity: 0.3;
          font-weight: bold;
        "
      >
        FRONT
      </div>
      <div
        v-if="rows"
        style="display: flex; flex-direction: column; gap: 5px; overflow: auto"
      >
        <div
          v-for="row in rows"
          :key="row.no"
          style="display: flex; justify-content: space-around; gap: 5px"
        >
          <div
            v-for="column in row.columns"
            :key="column.no"
            :style="{
              'border-radius': '4px',
              border: column.active ? 'solid 1px grey' : undefined,
              padding: `2px`,
              width: '100%',
              maxWidth: '100px',
              maxHeight: '100px',
              aspectRatio: '1 / 1',
              display: column.active ? 'flex' : 'hidden',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: backgroundColor(column),
              color: column.active ? undefined : 'white',
            }"
          >
            {{ column.name }}
          </div>
        </div>
      </div>
      <div
        v-if="rows"
        style="
          display: flex;
          justify-content: center;
          opacity: 0.3;
          font-weight: bold;
          margin-bottom: 1rem;
        "
      >
        BACK
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { apiRequest, cellPadding, numberToColumn } from "../../helpers";

export default {
  name: "ViewLayout",
  props: { trip: Object, errorMessage: Function },
  data: () => ({
    visible: false,
    rows: null,
    loading: false,
  }),
  computed: {
    cellPadding() {
      return cellPadding(this.rows[0].columns.length);
    },
    occupiedCount() {
      return this.trip.seats.length;
    },
  },
  methods: {
    backgroundColor(col) {
      if (col.active) {
        if (this.isOccupied(col)) {
          return "#FD8A8A";
        } else return "#DFFFD8";
      } else return undefined;
    },
    isOccupied(col) {
      for (const seat of this.trip.seats) {
        if (seat.boatLayoutColumnID === col.id) return true;
      }
      return false;
    },
    numberToColumn(x) {
      return numberToColumn(x);
    },
    find() {
      const endpoint = `/api/gettripboatlayout?tripId=${this.trip.id}`;
      this.loading = true;
      const thenHandle = (resp) => {
        const rows = resp?.data?.rows;
        if (rows) {
          this.rows = rows;
        }
      };
      const catchHandle = (error) => {
        if (error.response) {
          this.errorMessage(error.response.data.error);
        }
      };
      const finallyHandle = () => {
        this.loading = false;
      };
      apiRequest({
        endpoint,
        method: "GET",
        thenHandle,
        catchHandle,
        finallyHandle,
      });
    },
  },
  watch: {
    visible(value) {
      if (value) {
        this.find();
      }
    },
  },
};
</script>
