<template>
  <v-card-subtitle style="margin-left:0px; margin-right:0px; margin-top:30px;">
    <v-card width="100%" elevation="0" outlined>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-wrap">
            <ToFromLocation :from="from" :to="to" :pickupPoint="pickupPoint" :dropoffPoint="dropoffPoint" />
          </v-list-item-title>
          <v-list-item-subtitle>{{ computedDate }}</v-list-item-subtitle>
          <v-list-item-subtitle>{{ toFromString(trip) }}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="isLocal"><span v-if="showMVRAmount">{{ decimalCurrency(trip.laariPerSeatLocal) }}
              MVR or </span>{{ decimalCurrency(trip.centPerSeatLocal) }} USD per
            seat</v-list-item-subtitle>
          <v-list-item-subtitle v-else><span v-if="showMVRAmount">{{ decimalCurrency(trip.laariPerSeat) }} MVR or
            </span>{{ decimalCurrency(trip.centPerSeat) }} USD per
            seat</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="isRoundTrip">
        <v-list-item-content>
          <v-list-item-title class="text-wrap">
            <ToFromLocation :from="to" :to="from" :pickupPoint="dropoffPoint" :dropoffPoint="pickupPoint" />
          </v-list-item-title>
          <v-list-item-subtitle>{{ computedReturnDate }}</v-list-item-subtitle>
          <v-list-item-subtitle>{{
              toFromString(returnTrip)
          }}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="isLocal"><span v-if="showMVRAmount">{{
              decimalCurrency(
                returnTrip.laariPerSeatLocal -
                (sameDay ? sameDayReturnLaariDiscount : 0)
              )
          }}
              MVR or </span>{{
                  decimalCurrency(
                    returnTrip.centPerSeatLocal -
                    (sameDay ? sameDayReturnCentDiscount : 0)
                  )
              }}
            USD per seat</v-list-item-subtitle>
          <v-list-item-subtitle v-else><span v-if="showMVRAmount">{{
              decimalCurrency(
                returnTrip.laariPerSeat -
                (sameDay ? sameDayReturnLaariDiscount : 0)
              )
          }}
              MVR or </span>{{
      decimalCurrency(
        returnTrip.centPerSeat -
        (sameDay ? sameDayReturnCentDiscount : 0)
      )
              }}
            USD per seat</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-card-subtitle>
</template>

<script>
import ToFromLocation from './Common/ToFromLocation.vue'

export default {
  name: "TripInfo",
  props: {
    from: Object,
    to: Object,
    isRoundTrip: Boolean,
    trip: Object,
    returnTrip: Object,
    toFromString: Function,
    decimalCurrency: Function,
    computedDate: String,
    computedReturnDate: String,
    isLocal: Boolean,
    showMVRAmount: Boolean,
    sameDay: Boolean,
    sameDayReturnLaariDiscount: Number,
    sameDayReturnCentDiscount: Number,
    pickupPoint: Object,
    dropoffPoint: Object,
  },
  components: { ToFromLocation }
}
</script>
