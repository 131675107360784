<template>
  <v-container fluid fill-height>
    <v-row justify="center" align="center">
      <v-snackbar
        v-if="snackbar"
        v-model="snackbar"
        top
        color="blue"
        outlined
        >{{ snackbar }}</v-snackbar
      >
      <v-snackbar v-model="errorSnackbar" top color="red" outlined>{{
        errorSnackbarText
      }}</v-snackbar>
      <v-col sm="6" md="4" lg="3" xl="3">
        <Header></Header>
        <v-card>
          <div v-if="!$store.getters.isLoggedIn">
            <v-card-text style="padding-top: 20px; margin-top: 20px">
              <v-form>
                <v-text-field
                  v-model="username"
                  label="Username"
                  outlined
                  @keypress.enter="login()"
                ></v-text-field>
                <v-text-field
                  v-model="password"
                  label="Password"
                  type="password"
                  outlined
                  @keypress.enter="login()"
                  hide-details
                >
                </v-text-field>
                <ForgotPassword />
              </v-form>
              <v-btn
                block
                color="primary"
                class="py-6"
                depressed
                @click="login()"
                style="margin-bottom: 20px"
                >Log in
              </v-btn>
              <v-btn block color="primary" class="py-6" outlined to="/register"
                >Register</v-btn
              >
            </v-card-text>
          </div>
          <div v-else>
            <v-card-text style="padding-top: 20px; margin-top: 20px">
              <div style="margin-bottom: 20px">
                Logged in as {{ $store.getters.getUser.username }}
              </div>
              <v-btn
                v-if="$store.getters.getUser.isAdmin"
                block
                color="primary"
                class="py-6"
                depressed
                to="/admin"
                style="margin-bottom: 20px"
                >Admin Dashboard</v-btn
              >
              <v-btn
                v-if="$store.getters.getUser.isCaptain"
                block
                color="primary"
                class="py-6"
                depressed
                to="/captain"
                style="margin-bottom: 20px"
                >Captain Dashboard</v-btn
              >
              <v-btn
                block
                color="primary"
                class="py-6"
                outlined
                @click="logout()"
                >Log out</v-btn
              >
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Header from "../components/Header.vue";
import ForgotPassword from "../components/User/ForgotPassword.vue";
export default {
  name: "Login",
  components: {
    Header,
    ForgotPassword,
  },
  data: () => ({
    username: "",
    password: "",
    errorSnackbar: false,
    errorSnackbarText: "",
    snackbar: "",
  }),
  methods: {
    message(message) {
      this.snackbar = message;
    },
    errorMessage(message) {
      this.errorSnackbarText = message;
      this.errorSnackbar = true;
      if (message === "Token expired.") {
        this.$store.dispatch("logout");
      }
    },
    login() {
      if (this.username === "" || this.password === "") return;
      this.$store
        .dispatch("login", {
          username: this.username,
          password: this.password,
        })
        .then(() => {
          let prev = localStorage.getItem("prevRoute");
          if (prev) {
            this.$router.push(prev);
          }
        })
        .catch((error) => {
          this.errorMessage(error.error);
        });
    },
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style></style>
