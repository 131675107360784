<template>
  <v-app>
    <v-main>
      <div v-if="!ready" class="text-center">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
        <div style="margin-top: 20px">Authenticating</div>
      </div>
      <router-view v-else />
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";
import moment from "moment-timezone";

moment.tz.setDefault("Indian/Maldives");
export default {
  name: "App",
  data: () => ({
    ready: false,
  }),
  mounted() {
    const localToken = localStorage.getItem("Authorization");
    if (!localToken || localToken === "null" || localToken === "undefined") {
      localStorage.removeItem("Authorization");
      this.ready = true;
      return;
    }
    axios({
      url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/me`,
      method: "GET",
      headers: { Authorization: localToken },
    })
      .then((resp) => {
        const token = resp.headers.authorization;
        localStorage.setItem("Authorization", token);
        this.$store
          .dispatch("authenticate", {
            token,
            user: resp.data,
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.error === "Token expired.") {
          this.$store.dispatch("logout");
        }
        this.$router.push("/login");
      })
      .finally(() => {
        this.ready = true;
      });
  },
};
</script>
