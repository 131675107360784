var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"600","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-seat-passenger")])],1)]}}]),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',{staticStyle:{"padding":"30px"}},[_vm._v(" "+_vm._s(_vm.trip.boat.name)+" seat selection "),_c('div',{staticStyle:{"display":"flex","gap":"1rem","margin-top":"1rem","margin-bottom":"1rem"}},[_c('v-text-field',{attrs:{"value":_vm.trip.boat.capacity,"label":"Capacity","disabled":"","type":"number","hide-details":""}}),_c('v-text-field',{attrs:{"value":_vm.trip.booked,"label":"Booked Seats","disabled":"","type":"number","hide-details":""}}),_c('v-text-field',{attrs:{"value":_vm.occupiedCount,"label":"Occupied Seats","disabled":"","type":"number","hide-details":""}})],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(_vm.rows)?_c('div',{staticStyle:{"display":"flex","justify-content":"center","opacity":"0.3","font-weight":"bold"}},[_vm._v(" FRONT ")]):_vm._e(),(_vm.rows)?_c('div',{staticStyle:{"display":"flex","flex-direction":"column","gap":"5px","overflow":"auto"}},_vm._l((_vm.rows),function(row){return _c('div',{key:row.no,staticStyle:{"display":"flex","justify-content":"space-around","gap":"5px"}},_vm._l((row.columns),function(column){return _c('div',{key:column.no,style:({
            'border-radius': '4px',
            border: column.active ? 'solid 1px grey' : undefined,
            padding: `2px`,
            width: '100%',
            maxWidth: '100px',
            maxHeight: '100px',
            aspectRatio: '1 / 1',
            display: column.active ? 'flex' : 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: _vm.backgroundColor(column),
            color: column.active ? undefined : 'white',
          })},[_vm._v(" "+_vm._s(column.name)+" ")])}),0)}),0):_vm._e(),(_vm.rows)?_c('div',{staticStyle:{"display":"flex","justify-content":"center","opacity":"0.3","font-weight":"bold","margin-bottom":"1rem"}},[_vm._v(" BACK ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }