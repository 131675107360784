<template>
  <v-dialog
    v-model="visible"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    max-width="800px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="route" color="primary" icon v-bind="attrs" v-on="on">
        <v-icon>mdi-currency-usd</v-icon>
      </v-btn>
      <v-btn
        v-else
        color="primary"
        outlined
        block
        large
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        >Add Route
      </v-btn>
    </template>
    <v-card style="padding: 2rem 2rem 1rem 2rem">
      <div v-if="step === 1">
        <v-text-field v-model="name" label="Route name" outlined hide-details>
        </v-text-field>
        <div
          v-for="(stop, i) in stops"
          :key="i"
          style="
            margin-top: 0.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
          "
        >
          <div style="width: 150px" v-if="i !== 0">
            <v-text-field
              v-model="stop.mins"
              label="Duration"
              outlined
              hide-details
              type="number"
              suffix="mins"
              dense
              min="0"
            ></v-text-field>
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              gap: 0.5rem;
              width: 100%;
              margin-top: 0.5rem;
            "
          >
            <v-select
              style="flex: 1"
              dense
              outlined
              :items="locations"
              :label="`Stop ${i + 1}`"
              v-model="stop.location"
              item-text="name"
              return-object
              hide-details
            ></v-select>

            <v-btn
              color="primary"
              icon
              title="Remove stop"
              v-if="stops.length > 1"
              @click="removeStop(i)"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <v-btn
          color="primary"
          :loading="creating"
          @click="addStop()"
          outlined
          small
          style="margin-top: 0.5rem"
          >Add Stop</v-btn
        >
        <div v-if="totalDuration > 0">
          Total duration: {{ totalDuration }} mins
        </div>
      </div>
      <div v-if="step === 2">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div>Price per seat</div>
          <v-checkbox
            v-model="differentLocalPrice"
            label="Different price for locals"
          />
        </div>
        <div v-for="(stop, i) in stops">
          <div
            v-if="stop.toStops?.length > 0"
            style="display: flex; flex-direction: column"
          >
            From: Stop {{ stop.order }} - {{ stop.location.name }}
            <div
              v-for="toStop in stop.toStops"
              :style="{
                marginLeft: '1.5rem',
                marginBottom: '0.4rem',
                backgroundColor: '#f5f5f5',
                paddingLeft: '0.5rem',
              }"
            >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <div>
                  To: Stop {{ toStop.order }} - {{ toStop.location.name }}
                </div>
                <div style="display: flex; gap: 0.3rem; flex-wrap: wrap">
                  <v-text-field
                    dense
                    hide-details
                    outlined
                    label="MVR"
                    type="number"
                    style="max-width: 120px"
                    v-model="toStop.mvrPerSeat"
                  ></v-text-field>
                  <v-text-field
                    dense
                    hide-details
                    outlined
                    label="USD"
                    type="number"
                    style="max-width: 120px"
                    v-model="toStop.usdPerSeat"
                  ></v-text-field>
                  <v-text-field
                    v-if="differentLocalPrice"
                    dense
                    hide-details
                    outlined
                    label="MVR Local"
                    type="number"
                    style="max-width: 120px"
                    v-model="toStop.mvrPerSeatLocal"
                  ></v-text-field>
                  <v-text-field
                    v-if="differentLocalPrice"
                    dense
                    hide-details
                    outlined
                    label="USD Local"
                    type="number"
                    style="max-width: 120px"
                    v-model="toStop.usdPerSeatLocal"
                  ></v-text-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="display: flex; justify-content: space-between; margin-top: 1rem"
      >
        <v-btn
          color="primary"
          :loading="creating"
          @click="handleCancel"
          outlined
          >Close</v-btn
        >
        <div style="display: flex; gap: 1rem">
          <v-btn
            v-if="step === 2 && !route"
            color="primary"
            @click="step = 1"
            outlined
            >Back</v-btn
          >
          <v-btn color="primary" :loading="creating" @click="create()">{{
            step === 1 ? "Next" : route ? "Save" : "Create"
          }}</v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { apiRequest } from "../../../helpers";

export default {
  name: "AddRoute",
  props: {
    locations: Array,
    message: Function,
    errorMessage: Function,
    getRoutes: Function,
    route: Object,
  },
  data: () => ({
    visible: null,
    name: "",
    creating: false,
    stops: [{ location: null, duration: null }],
    differentLocalPrice: false,
    step: 1,
  }),
  computed: {
    totalDuration() {
      let d = 0;
      for (const stop of this.stops) {
        if (stop.mins) d += parseInt(stop.mins);
      }
      return d;
    },
  },
  methods: {
    handleCancel() {
      this.visible = null;
      this.stops = [{ location: null, duration: null }];
      this.step = 1;
      this.differentLocalPrice = false;
      this.name = "";
    },
    addStop() {
      this.stops.push({ location: null, mins: null });
    },
    removeStop(i) {
      const newStops = this.stops.filter((_, j) => i !== j);
      if (i === 0) {
        newStops[0].mins = null;
      }
      this.stops = newStops;
    },
    create() {
      if (this.step === 1) {
        if (this.name.trim() === "") {
          this.errorMessage("Route name required");
          return;
        }
        if (this.stops.length < 2) {
          this.errorMessage("At least 2 stops required");
          return;
        }
        for (const [i, stop] of this.stops.entries()) {
          if (!stop.location) {
            this.errorMessage("All stops not selected");
            return;
          }
          if (i !== 0 && !stop.mins) {
            this.errorMessage("All durations not entered");
            return;
          }
        }
        const uniqueLocations = [
          ...new Set(this.stops.map((s) => s.location.id)),
        ];
        if (uniqueLocations.length !== this.stops.length) {
          this.errorMessage("Each location can be used once within the trip");
          return;
        }
        this.step = 2;
        for (const [i, stop] of this.stops.entries()) {
          stop.order = i + 1;
          let oldToStops = null;
          if (stop.toStops) {
            oldToStops = JSON.parse(JSON.stringify(stop.toStops));
          }
          stop.toStops = [];
          if (i >= this.stops.length - 1) continue;
          let minutes = 0;
          for (const [j, toStop] of this.stops.entries()) {
            if (j <= i) continue;
            minutes += parseInt(toStop.mins);
            const foundOld = oldToStops?.find(
              (o) => o.location.id === toStop.location.id
            );
            stop.toStops.push({
              order: j + 1,
              location: toStop.location,
              mvrPerSeat: foundOld ? foundOld.mvrPerSeat : 0,
              usdPerSeat: foundOld ? foundOld.usdPerSeat : 0,
              mvrPerSeatLocal: foundOld ? foundOld.mvrPerSeatLocal : 0,
              usdPerSeatLocal: foundOld ? foundOld.usdPerSeatLocal : 0,
              minutes,
            });
          }
        }
        return;
      }
      const stopsClone = JSON.parse(JSON.stringify(this.stops));
      // change to cents, remove locations
      for (const stop of stopsClone) {
        stop.locationId = stop.location.id;
        stop.location = null;
        if (!stop.toStops || stop.toStops.length === 0) continue;
        for (const toStop of stop.toStops) {
          toStop.locationId = toStop.location.id;
          toStop.location = null;
          toStop.laariPerSeat = Math.round(toStop.mvrPerSeat * 100);
          toStop.centPerSeat = Math.round(toStop.usdPerSeat * 100);
          if (this.differentLocalPrice) {
            toStop.laariPerSeatLocal = Math.round(toStop.mvrPerSeatLocal * 100);
            toStop.centPerSeatLocal = Math.round(toStop.usdPerSeatLocal * 100);
          } else {
            toStop.laariPerSeatLocal = toStop.laariPerSeat;
            toStop.centPerSeatLocal = toStop.centPerSeat;
          }
        }
      }
      const endpoint = this.route
        ? "/api/updateroutepricing"
        : "/api/createroute";
      const data = {
        id: this.route ? this.route.id : undefined,
        name: this.name,
        stops: stopsClone,
      };
      this.creating = true;
      const thenHandle = (resp) => {
        this.message(resp.data.message);
        this.handleCancel();
        this.getRoutes();
      };
      const catchHandle = (error) => {
        if (error.response) {
          this.errorMessage(error.response.data.error);
        }
      };
      const finallyHandle = () => {
        this.creating = false;
      };
      apiRequest({
        endpoint,
        method: "POST",
        thenHandle,
        catchHandle,
        finallyHandle,
        data,
      });
    },
  },
  watch: {
    visible(value) {
      if (value) {
        if (this.route) {
          const routeClone = JSON.parse(JSON.stringify(this.route));
          this.name = routeClone.name;
          this.stops = routeClone.stops;
          this.differentLocalPrice = routeClone.differentLocalPrice;
          this.step = 2;
        }
      } else {
        this.handleCancel();
      }
    },
  },
};
</script>
