<template>
  <div>
    <v-dialog
      v-model="dialog"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      width="100%"
      max-width="500px"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
          style="margin-bottom: 1rem; margin-top: 0.5rem"
        >
          <a> Forgot password? </a>
        </div>
      </template>
      <v-card style="padding: 25px">
        <v-text-field
          v-model="phone"
          label="Phone number"
          outlined
          type="number"
          hide-details
          @keypress.enter="resetPassword()"
          autofocus
        ></v-text-field>
        <v-alert
          style="margin-top: 0.5rem"
          dense
          outlined
          :type="type"
          v-if="message"
          >{{ message }}</v-alert
        >
        <div style="display: flex; justify-content: flex-end; margin-top: 1rem">
          <v-btn
            outlined
            color="primary"
            :loading="loading"
            @click="resetPassword"
            >Send Link</v-btn
          >
        </div></v-card
      >
    </v-dialog>
  </div>
</template>

<script>
import { apiRequest } from "../../helpers";
export default {
  name: "ForgotPassword",
  props: {},
  data: () => ({
    dialog: false,
    phone: "",
    loading: false,
    message: "",
    type: "",
  }),
  methods: {
    resetPassword() {
      const endpoint = `/api/initiatepasswordreset`;
      this.loading = true;
      const data = {
        phone: this.phone.trim(),
      };
      const thenHandle = (resp) => {
        this.message = resp.data.message;
        this.type = "info";
      };
      const catchHandle = (error) => {
        if (error.response) {
          this.message = error.response.data.error;
          this.type = "error";
        }
      };
      const finallyHandle = () => {
        this.loading = false;
      };
      apiRequest({
        endpoint,
        method: "POST",
        thenHandle,
        catchHandle,
        finallyHandle,
        data,
      });
    },
  },
};
</script>
