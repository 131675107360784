<template>
  <div>
    <v-alert outlined type="info">
      {{ pendingText }}
      <a :href="firstWaitlistedDate">Go to date with next pending waitlist.</a>
    </v-alert>
  </div>
</template>

<script>
import moment from "moment"
export default {
  name: "PendingWaitlists",
  props: {
    pendingWaitlists: Array
  },
  computed: {
    pendingText() {
      return `${this.pendingWaitlists.length} pending waitlist${this.pendingWaitlists.length === 1 ? "" : "s"}.`
    },
    firstWaitlistedDate() {
      const date = moment(this.pendingWaitlists[0].date).format('YYYY-MM-DD')
      return `/admin?tab=waitlists&date=${date}`
    }
  },
}
</script>