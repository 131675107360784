<template>
  <v-footer app padless>
    <v-card class="flex" flat tile>
      <v-card-text class="pa-0">
        <div
          style="
            display: flex;
            justify-content: space-between;
            padding-left: 10px;
            padding-right: 10px;
          "
        >
          <span>
            {{ new Date().getFullYear() }}
            <strong>{{ getCompany() }}</strong>
          </span>
          <div style="display: flex; gap: 1rem">
            <span v-if="this.getToc()">
              <a style="text-decoration: none" href="/terms-and-conditions"
                >Terms</a
              >
            </span>
            <span v-if="showAbout()">
              <a style="text-decoration: none" href="/about">About Us</a>
            </span>
            <span v-else>
              Powered by
              <a href="https://techverin.com/" target="_blank">Techverin</a>
            </span>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    getCompany() {
      return process.env.VUE_APP_COMPANY ?? "Techverin Pvt. Ltd";
    },
    getToc() {
      return process.env.VUE_APP_TOC ?? "";
    },
    showAbout() {
      if (this.getCompany() === "Techverin Pvt. Ltd") return true;
      return false;
    },
    goToAbout() {
      this.$router.push("/about");
    },
  },
};
</script>
