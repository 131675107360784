<template>
  <v-dialog
    v-model="confirmDialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    width="100%"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-card-text style="padding: 0px">
        <v-btn color="orange" outlined v-bind="attrs" v-on="on" block large>
          <v-icon class="mr-2">mdi-history</v-icon>Reschedule Trip
        </v-btn>
      </v-card-text>
    </template>
    <v-card style="padding: 30px">
      <v-row>
        <v-col class="noHorizontalPadding">
          <v-dialog
            v-model="dateModal"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                :value="computedDate"
                label="Date"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="newDate"
              @input="dateModal = false"
              dark
            ></v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row style="margin-top: -30px">
        <v-col cols="5.5" class="noHorizontalPadding">
          <v-dialog v-model="fromTimeModal" width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="newFromTime"
                label="Departure"
                prepend-inner-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="fromTimeModal"
              v-model="newFromTime"
              full-width
              format="24hr"
              @click:minute="fromTimeModal = false"
            ></v-time-picker>
          </v-dialog>
        </v-col>
        <v-col cols="1"></v-col>
        <v-col cols="5.5" class="noHorizontalPadding">
          <v-dialog v-model="toTimeModal" width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="Boolean(trip.route)"
                v-model="newToTime"
                label="Arrival"
                prepend-inner-icon="mdi-clock-time-four-outline"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              v-if="toTimeModal"
              v-model="newToTime"
              full-width
              format="24hr"
              @click:minute="toTimeModal = false"
            ></v-time-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row style="margin-top: -10px; justify-content: space-between">
        <div></div>
        <v-btn
          color="primary"
          outlined
          :loading="rescheduling"
          @click="reschedule()"
          >Reschedule</v-btn
        >
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "RescheduleTrip",
  props: {
    date: String,
    trip: Object,
    getTrips: Function,
    message: Function,
    errorMessage: Function,
    updateDate: Function,
  },
  data: () => ({
    confirmDialog: null,
    rescheduling: false,
    newFromTime: null,
    fromTimeModal: null,
    toTimeModal: null,
    newToTime: null,
    toTimeModal: null,
    newDate: null,
    dateModal: null,
  }),
  computed: {
    computedDate() {
      return this.newDate
        ? moment(this.newDate).format("dddd, D MMMM YYYY")
        : "";
    },
  },
  methods: {
    reschedule() {
      if (!this.newFromTime || !this.newToTime) {
        this.errorMessage("Both times not selected.");
        return;
      }
      const data = {
        tripId: this.trip.id,
        fromTime: moment(
          `${this.newDate} ${this.newFromTime}`,
          "YYYY-MM-DD HH:mm"
        ),
        toTime: moment(`${this.newDate} ${this.newToTime}`, "YYYY-MM-DD HH:mm"),
      };
      data.fromTime = data.fromTime.toISOString();
      data.toTime = data.toTime.toISOString();
      this.rescheduling = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/rescheduletrip`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.updateDate(this.newDate);
          this.getTrips();
          this.confirmDialog = false;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.rescheduling = false));
    },
    calcToTime() {
      if (!this.newFromTime) return;
      if (this.trip.route) {
        const firstStop = this.trip?.route?.stops[0];
        if (!firstStop) return;
        const lastStop = firstStop.toStops[firstStop.toStops.length - 1];
        const minutesToAdd = lastStop.minutes;
        this.newToTime = moment(
          `${this.newDate} ${this.newFromTime}`,
          "YYYY-MM-DD HH:mm"
        )
          .add(minutesToAdd, "minutes")
          .format("HH:mm");
      } else {
        this.newToTime = moment(
          `${this.newDate} ${this.newFromTime}`,
          "YYYY-MM-DD HH:mm"
        )
          .add(30, "minutes")
          .format("HH:mm");
      }
    },
  },
  watch: {
    newFromTime: {
      handler: function () {
        this.calcToTime();
      },
    },
    confirmDialog: {
      handler: function () {
        if (this.confirmDialog) {
          this.newDate = moment(this.trip.fromTime)
            .startOf("day")
            .format("YYYY-MM-DD");
          this.newFromTime = moment(this.trip.fromTime).format("HH:mm");
          this.newToTime = moment(this.trip.toTime).format("HH:mm");
        }
      },
    },
  },
};
</script>

<style scoped>
.noHorizontalPadding {
  padding-left: 0px;
  padding-right: 0px;
}
.noVerticalPadding {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
