<template>
  <v-dialog
    v-model="dialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    width="100%"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" icon v-bind="attrs" v-on="on">
        <v-icon>mdi-format-list-checkbox</v-icon>
      </v-btn>
    </template>
    <v-card style="padding: 30px" id="tripPaymentsDiv">
      <div class="text-left text-h6">
        Trip #{{ trip.id }} Payments
        <a
          :href="`/trip-payments/${trip.id}`"
          target="_blank"
          style="text-decoration: none"
        >
          <v-btn color="primary" icon title="Print">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </a>
      </div>
      <v-card
        v-if="paidBookings.length > 0"
        class="text-subtitle-2 px-4 py-2"
        style="font-weight: 400"
      >
        <div v-for="(booking, index) in paidBookings" :key="booking.id">
          <v-divider v-if="index !== 0"></v-divider>
          <div style="display: flex; justify-content: space-between">
            <div style="display: flex">
              <div style="min-width: 50px">#{{ booking.refNo }}</div>
              <div>
                <div
                  v-for="(customer, index) in booking.customers"
                  :key="customer.id"
                >
                  {{ customer.name }}
                  <span
                    style="margin-left: 1rem; opacity: 70%"
                    v-if="index === 0 && booking.allowedType !== ''"
                    >{{ booking.allowedType }}</span
                  >
                  <span
                    style="margin-left: 1rem; opacity: 70%"
                    v-else-if="
                      index === 0 &&
                      booking.isAllowed &&
                      booking.allowedType === ''
                    "
                    >Free of charge</span
                  >
                </div>
              </div>
            </div>
            <div>
              <span v-if="booking.paidInUSD"
                >{{ decimalCurrency(booking.priceInCent) }} USD</span
              >
              <span v-if="booking.paidInMVR"
                >{{ decimalCurrency(booking.priceInLaari) }} MVR</span
              >
            </div>
          </div>
        </div>
      </v-card>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-simple-table v-if="report" style="white-space: nowrap">
        <template v-slot:default>
          <tbody>
            <tr
              v-if="
                bmlGatewayAllowed ||
                report.mvrtransaction > 0 ||
                report.usdtransaction > 0
              "
            >
              <td style="width: 100%">Card</td>
              <td style="text-align: right">
                {{ decimalCurrency(report.mvrtransaction) }} MVR
              </td>
            </tr>
            <tr
              v-if="
                bmlGatewayAllowed ||
                report.mvrtransaction > 0 ||
                report.usdtransaction > 0
              "
            >
              <td style="width: 100%">Card</td>
              <td style="text-align: right">
                {{ decimalCurrency(report.usdtransaction) }} USD
              </td>
            </tr>
            <tr>
              <td style="width: 100%">Transfer</td>
              <td style="text-align: right">
                {{ decimalCurrency(report.mvrtransfer) }} MVR
              </td>
            </tr>
            <tr>
              <td style="width: 100%">Transfer</td>
              <td style="text-align: right">
                {{ decimalCurrency(report.usdtransfer) }} USD
              </td>
            </tr>
            <tr>
              <td style="width: 100%">Cash</td>
              <td style="text-align: right">
                {{ decimalCurrency(report.mvrcash) }} MVR
              </td>
            </tr>
            <tr>
              <td style="width: 100%">Cash</td>
              <td style="text-align: right">
                {{ decimalCurrency(report.usdcash) }} USD
              </td>
            </tr>
            <tr>
              <td style="width: 100%">Credit</td>
              <td style="text-align: right">
                {{ decimalCurrency(report.mvrcredit) }} MVR
              </td>
            </tr>
            <tr>
              <td style="width: 100%">Credit</td>
              <td style="text-align: right">
                {{ decimalCurrency(report.usdcredit) }} USD
              </td>
            </tr>
            <tr>
              <td style="width: 100%">Pre-paid</td>
              <td style="text-align: right">
                {{ decimalCurrency(report.mvrprepaid) }} MVR
              </td>
            </tr>
            <tr>
              <td style="width: 100%">Pre-paid</td>
              <td style="text-align: right">
                {{ decimalCurrency(report.usdprepaid) }} USD
              </td>
            </tr>
            <tr class="font-weight-bold">
              <td style="width: 100%">Total</td>
              <td style="text-align: right">
                {{ decimalCurrency(report.mvrtotal) }} MVR
              </td>
            </tr>
            <tr class="font-weight-bold">
              <td style="width: 100%">Total</td>
              <td style="text-align: right">
                {{ decimalCurrency(report.usdtotal) }} USD
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "PaymentDetails",
  props: {
    trip: Object,
    errorMessage: Function,
    decimalCurrency: Function,
    bookings: Array,
  },
  data: () => ({
    dialog: null,
    report: null,
    loading: false,
  }),
  computed: {
    bmlGatewayAllowed() {
      return process.env.VUE_APP_ALLOW_BML_GATEWAY === "true" ? true : false;
    },
    stripeGatewayAllowed() {
      return process.env.VUE_APP_ALLOW_STRIPE_GATEWAY === "true" ? true : false;
    },
    paidBookings() {
      return this.bookings.filter(
        (booking) => booking.isPaid || booking.isAllowed
      );
    },
  },
  methods: {
    getPaymentDetails() {
      this.loading = true;
      const query = `?tripId=${this.trip.id}`;
      axios({
        url: `${
          process.env.VUE_APP_BASE_URL ?? ""
        }/api/trippaymentreport${query}`,
        method: "GET",
        headers: { Authorization: localStorage.getItem("Authorization") },
      })
        .then((resp) => {
          this.report = resp.data.report;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    print() {
      const rows = [];
      for (const booking of this.paidBookings) {
        let allowedType = booking.allowedType;
        if (booking.isAllowed && booking.allowedType === "") {
          allowedType = "Free of charge";
        }
        let amount = this.decimalCurrency(booking.priceInCent);
        let currency = "USD";
        if (booking.paidInMVR) {
          amount = this.decimalCurrency(booking.priceInLaari);
          currency = "MVR";
        }
        rows.push([
          booking.refNo,
          booking.customers[0].name,
          allowedType,
          amount,
          currency,
        ]);
        if (booking.customers.length > 1) {
          for (const customer of booking.customers.slice(1)) {
            rows.push(["", customer.name, "", "", "", ""]);
          }
        }
      }
      let csv = "Ref no,Customer,Type,Amount,Currency\n";
      rows.forEach((row, i) => {
        csv += row.join(",");
        if (i !== rows.length - 1) csv += "\n";
      });
      const anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csv);
      anchor.target = "_blank";
      anchor.download = "Template.csv";
      anchor.click();
    },
  },
  watch: {
    dialog: {
      handler: function () {
        if (this.dialog) {
          this.getPaymentDetails();
        }
      },
    },
  },
};
</script>
