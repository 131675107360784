<template>
  <v-dialog v-model="confirmDialog" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
    offset-y width="100%" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item-title v-bind="attrs" v-on="on" style="cursor: pointer;">
        Cancel Booking
      </v-list-item-title>
    </template>
    <v-card style="padding:25px">
      <v-text-field v-model="reason" label="Reason" outlined hide-details
        @keypress.enter="cancelBooking()"></v-text-field>
      <div style="display: flex; justify-content: flex-end; margin-top: 1rem;">
        <v-btn outlined color="primary" :loading="loading" @click="cancelBooking">Cancel Booking</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios"

export default {
  name: "CancelBooking",
  props: {
    booking: Object,
    message: Function,
    errorMessage: Function,
    getBookings: Function,
    getBooking: Function,
    getBookingsList: Function,
    getTrips: Function,
  },
  data: () => ({
    confirmDialog: null,
    loading: false,
    reason: ""
  }),
  methods: {
    decimalCurrency(number) {
      const small = number.toString()
      return (
        small.substring(0, small.length - 2) +
        "." +
        small.substring(small.length - 2)
      )
    },
    cancelBooking() {
      if (this.reason.trim() === "") {
        this.errorMessage("Please enter a reason for cancellation.")
        return
      }
      this.loading = true
      const data = {
        bookingId: this.booking.id,
        reason: this.reason
      }
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/cancelbooking`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message)
          this.getBookings()
          this.getTrips()
          this.getBooking()
          if (this.getBookingsList) {
            this.getBookingsList()
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>