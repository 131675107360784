var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":25,"width":2}})],1):_vm._e(),(_vm.booking)?_c('div',{staticStyle:{"padding":"0.5rem"}},[_c('v-btn',{staticStyle:{"width":"100%","flex-wrap":"wrap","height":"50px"},attrs:{"outlined":"","color":"primary","href":`/booking/${_vm.uuid}`}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","align-items":"center","justify-content":"center"}},[_c('div',[_vm._v(_vm._s(_vm.text)+" Booking #"+_vm._s(_vm.booking.refNo))]),_c('div',[(_vm.booking.isPaid)?_c('v-chip',{attrs:{"small":"","color":"green"}},[_vm._v("Paid")]):_vm._e(),(_vm.booking.isAllowed)?_c('v-chip',{attrs:{"small":"","color":"cyan"}},[_vm._v("Allowed"+_vm._s(_vm.booking.allowedType ? `(${_vm.booking.allowedType})` : ""))]):_vm._e(),(
              _vm.booking.isCashAllowed && !_vm.booking.isPaid && !_vm.booking.isAllowed
            )?_c('v-chip',{attrs:{"small":"","color":"pink lighten-3"}},[_vm._v("Cash On Arrival")]):_vm._e(),(
              !_vm.booking.isPaid && !_vm.booking.isAllowed && !_vm.booking.isCashAllowed
            )?_c('div',[(
                _vm.booking.transfer.id !== 0 &&
                _vm.booking.transfer.status === 'Pending'
              )?_c('v-chip',{attrs:{"small":"","color":"orange"}},[_vm._v("Pending Verification")]):(
                _vm.booking.cashPayment.id !== 0 &&
                _vm.booking.cashPayment.status === 'Pending'
              )?_c('v-chip',{attrs:{"small":"","color":"orange"}},[_vm._v("Pending Cash Option")]):(_vm.booking.isCancelled)?_c('v-chip',{attrs:{"small":"","color":"white"}},[_vm._v("Cancelled")]):_c('v-chip',{attrs:{"small":""}},[_vm._v("Pending")])],1):_vm._e()],1)])])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }