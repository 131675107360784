<template>
  <div style="display: flex; gap: 0.5rem">
    <v-icon dense>mdi-comment-outline</v-icon>
    <v-text-field
      v-model="remarks"
      label="Trip remarks"
      outlined
      hide-details
      :disabled="loading || disabled"
      @blur="addRemarks"
      @keypress.enter="addRemarks"
      dense
    ></v-text-field>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "TripRemarks",
  props: {
    trip: Object,
    message: Function,
    errorMessage: Function,
    getTrips: Function,
    disabled: Boolean,
  },
  data: () => ({
    remarks: "",
    loading: false,
  }),
  methods: {
    addRemarks() {
      if (this.remarks.trim() === "") return;
      if (this.disabled) return;
      this.loading = true;
      const data = {
        tripId: this.trip.id,
        remarks: this.remarks,
      };
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/addtripremarks`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then(() => {
          this.getTrips();
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    if (this.trip?.remarks) {
      this.remarks = this.trip.remarks;
    }
  },
};
</script>
