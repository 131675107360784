<template>
  <v-card>
    <div style="padding: 1rem">
      <AddRoute
        :locations="locations"
        :message="message"
        :errorMessage="errorMessage"
        :getRoutes="getRoutes"
      />
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-card
        flat
        color="grey lighten-4"
        class="mb-2"
        ripple
        v-for="(route, index) in routes"
        :key="route.id"
      >
        <v-card-text>
          <v-row justify="center" align="center">
            <v-col>
              <div class="title text-center">
                {{ route.name }}
              </div>
              <div
                style="
                  display: flex;
                  column-gap: 1rem;
                  justify-content: center;
                  flex-wrap: wrap;
                "
              >
                <div
                  v-for="(stop, i) in route.stops"
                  style="display: flex; align-items: center"
                >
                  <div
                    v-if="i !== 0"
                    style="
                      margin-right: 1rem;
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-end;
                      font-size: 80%;
                    "
                  >
                    {{ stop.mins }} min
                    <v-icon>mdi-arrow-right-thin</v-icon>
                  </div>
                  {{ stop.location.name }}
                </div>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
                class="text-center"
              >
                <DeleteRecord
                  :data="{ ...route }"
                  :refetchQueries="[getRoutes]"
                  :endpoint="'/api/deleteroute'"
                  :message="message"
                  :errorMessage="errorMessage"
                />
                <AddRoute
                  :route="route"
                  :locations="locations"
                  :message="message"
                  :errorMessage="errorMessage"
                  :getRoutes="getRoutes"
                />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </v-card>
</template>

<script>
import axios from "axios";
import DeleteRecord from "../../Common/DeleteRecord.vue";
import AddRoute from "./AddRoute.vue";

export default {
  name: "Routes",
  components: { AddRoute, DeleteRecord },
  props: {
    locations: Array,
    locationsLoading: Boolean,
    message: Function,
    errorMessage: Function,
    routes: Array,
    getRoutes: Function,
  },
  data: () => ({
    tab: "locations",
    dialog: null,
    newRoute: {
      name: "",
    },
    loading: false,
  }),
  methods: {
    deleteLocation(location, index) {
      const data = {
        locationId: location.id,
      };
      this.deletingLocation = true;
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/deletelocation`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getLocations();
          this.deleteLocationDialogs[index] = false;
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => (this.deletingLocation = false));
    },
  },
  mounted() {
    this.getRoutes();
  },
};
</script>
