<template>
  <div
    v-if="bookings.length > 0"
    style="padding-bottom: 1rem; padding-top: 0.5rem"
  >
    <v-card class="px-4 py-1" style="font-weight: 400" outlined>
      <div v-for="(booking, index) in bookings" :key="booking.id">
        <v-divider v-if="index !== 0"></v-divider>
        <div style="display: flex; justify-content: space-between">
          <div style="display: flex">
            <div style="min-width: 50px">#{{ booking.refNo }}</div>
            <div>
              <div
                v-for="(customer, index) in booking.customers"
                :key="customer.id"
              >
                <v-icon v-if="customer.hasBoarded">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
                {{ customer.name }}
              </div>
            </div>
          </div>
          <div>
            <span v-if="booking.paidInUSD"
              >{{ decimalCurrency(booking.priceInCent) }} USD</span
            >
            <span v-if="booking.paidInMVR"
              >{{ decimalCurrency(booking.priceInLaari) }} MVR</span
            >
          </div>
        </div>
        <div v-if="booking?.transfer?.refNo">
          Reference number: {{ booking.transfer.refNo }}
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { decimalCurrency } from "../../helpers";

export default {
  name: "TripPaymentBookings",
  props: {
    bookings: Array,
  },
  data: () => ({}),
  computed: {},
  methods: {
    decimalCurrency(number) {
      return decimalCurrency(number);
    },
  },
  mounted() {},
  watch: {},
};
</script>
