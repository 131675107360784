<template>
  <v-dialog
    v-model="dialog"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    width="100%"
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-card
        outlined
        color="grey lighten-4"
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        style="cursor: pointer"
      >
        <v-card-text class="pt-4">
          <v-row>
            <v-col style="padding-right: 0px">
              <span class="title">#{{ privateTransfer.id }}</span>
            </v-col>
            <v-spacer></v-spacer>
            <v-col class="shrink" style="padding-left: 0px">
              <v-chip v-if="privateTransfer.status === 'Pending'"
                >Pending</v-chip
              >
              <v-chip v-if="privateTransfer.status === 'Accepted'" color="cyan"
                >Accepted</v-chip
              >
              <v-chip
                v-if="privateTransfer.status === 'Rejected'"
                color="red lighten-2"
                >Rejected</v-chip
              >
            </v-col>
          </v-row>
          <v-row style="margin-bottom: 10px">
            <v-col style="padding-top: 0px; padding-bottom: 0px">
              <div class="subtitle-3" v-if="privateTransfer.email">
                <a :href="`mailto:${privateTransfer.email}`">{{
                  privateTransfer.email
                }}</a>
              </div>
              <div class="subtitle-3" v-if="privateTransfer.phone">
                <a :href="`tel:${privateTransfer.phone}`">{{
                  privateTransfer.phone
                }}</a>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
    <v-card>
      <v-progress-linear v-if="loading" indeterminate color="primary" />
      <v-card-text
        v-if="privateTransfer"
        style="padding-top: 16px; padding-bottom: 36px"
      >
        <v-row style="margin-bottom: 0px">
          <v-col class="grow"
            >Private transfer request #{{ privateTransfer.id }}</v-col
          >
          <v-col class="shrink" style="padding-right: 0px; padding-left: 0px">
            <v-chip v-if="privateTransfer.status === 'Pending'">Pending</v-chip>
            <v-chip
              v-if="privateTransfer.status === 'Accepted'"
              color="cyan"
              style="margin-right: 12px"
              >Accepted</v-chip
            >
            <v-chip
              v-if="privateTransfer.status === 'Rejected'"
              color="red lighten-2"
              style="margin-right: 12px"
              >Rejected</v-chip
            >
          </v-col>
          <v-col class="shrink" v-if="privateTransfer.status === 'Pending'">
            <v-menu left rounded="lg" close-on-content-click close-on-click>
              <template v-slot:activator="{ on, attrs }">
                <div>
                  <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    icon
                    v-on="on"
                    :loading="acceptingOrRejecting"
                  >
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </div>
              </template>
              <v-list>
                <v-list-item>
                  <AcceptPrivateTransfer
                    :message="message"
                    :errorMessage="errorMessage"
                    :getBookings="getBookings"
                    :getTrips="getTrips"
                    :getPrivateTransfers="getPrivateTransfers"
                    :boats="boats"
                    :privateTransfer="privateTransfer"
                  />
                </v-list-item>
                <v-list-item>
                  <v-list-item-title
                    style="cursor: pointer"
                    @click="rejectPrivateTransfer()"
                    >Reject Request</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
        <v-row
          v-if="privateTransfer.trip"
          style="margin-left: 0px; margin-right: 0px; margin-top: 0px"
        >
          <v-card width="100%" elevation="0" outlined>
            <v-row style="margin: 12px 4px 12px 16px">
              <v-col style="padding: 0px">
                <div class="text-subtitle-1">
                  {{ privateTransfer.trip.fromLocation.name }} to
                  {{ privateTransfer.trip.toLocation.name }}
                </div>
                <div class="text-body-2" style="color: rgba(0, 0, 0, 0.6)">
                  {{ computeDate(privateTransfer.trip.fromTime) }}
                </div>
                <div class="text-body-2" style="color: rgba(0, 0, 0, 0.6)">
                  {{ toFromString(privateTransfer.trip) }}
                </div>
              </v-col>
              <v-col class="shrink" style="padding: 0px">
                <div v-if="!privateTransfer.trip.deleted_at">
                  <a
                    :href="goToTrip(privateTransfer.trip)"
                    style="text-decoration: none"
                  >
                    <v-btn color="primary" small text style="padding: 5px">
                      <v-icon class="mr-0"
                        >mdi-arrow-right-circle-outline</v-icon
                      >View
                    </v-btn>
                  </a>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
        <v-row
          v-else
          style="margin-left: 0px; margin-right: 0px; margin-top: 0px"
        >
          <v-card width="100%" elevation="0" outlined>
            <v-row style="margin: 12px 4px 12px 16px">
              <v-col style="padding: 0px">
                <div class="text-subtitle-1">
                  {{ privateTransfer.fromLocation.name }} to
                  {{ privateTransfer.toLocation.name }}
                </div>
                <div class="text-body-2" style="color: rgba(0, 0, 0, 0.6)">
                  {{ computeDate(privateTransfer.fromTime) }}
                </div>
                <div class="text-body-2" style="color: rgba(0, 0, 0, 0.6)">
                  {{ timeFormat(privateTransfer.fromTime) }}
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
        <v-row style="margin-left: 0px; margin-right: 0px; margin-top: 20px">
          <v-card width="100%" elevation="0" outlined>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-wrap">
                  Customers
                  <span class="text-caption"
                    >({{ occupiesSeatCount(privateTransfer.customers) }} seat{{
                      occupiesSeatCount(privateTransfer.customers) > 1
                        ? "s"
                        : ""
                    }})</span
                  >
                </v-list-item-title>
                <v-list-item-subtitle
                  v-for="customer in privateTransfer.customers"
                  :key="customer.id"
                  >{{ customer.name }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-row>
        <v-row style="margin-left: 0px; margin-right: 0px; margin-top: 20px">
          <v-card width="100%" elevation="0" outlined>
            <v-list-item v-if="privateTransfer.email">
              <v-list-item-content>
                <v-list-item-title class="text-wrap"
                  >Email address</v-list-item-title
                >
                <v-list-item-subtitle>
                  <a :href="`mailto:${privateTransfer.email}`">{{
                    privateTransfer.email
                  }}</a>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="privateTransfer.phone">
              <v-list-item-content>
                <v-list-item-title class="text-wrap"
                  >Phone number</v-list-item-title
                >
                <v-list-item-subtitle>
                  <a :href="`tel:${privateTransfer.phone}`">{{
                    privateTransfer.phone
                  }}</a>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-row>
        <v-row
          v-if="privateTransfer.notes"
          style="margin-left: 0px; margin-right: 0px; margin-top: 20px"
        >
          <v-card width="100%" elevation="0" outlined>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-wrap"
                  >Other details</v-list-item-title
                >
                <v-list-item-subtitle>{{
                  privateTransfer.notes
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { DT_FORMATS, configEnabled, toFromString } from "../helpers";
import AcceptPrivateTransfer from "./AcceptPrivateTransfer.vue";

export default {
  name: "BookingDialog",
  components: { AcceptPrivateTransfer },
  props: {
    privateTransfer: Object,
    message: Function,
    errorMessage: Function,
    getBookings: Function,
    getTrips: Function,
    getPrivateTransfers: Function,
    boats: Array,
    configs: Array,
  },
  data: () => ({
    dialog: null,
    booking: null,
    loading: false,
    acceptingOrRejecting: false,
    allowingBooking: false,
    allowingBookingId: -1,
    cancellingAllow: false,
    cancellingBookingId: -1,
    proceedingCashPayment: false,
    proceedingCashPaymentId: -1,
  }),
  computed: {},
  methods: {
    computeDate(date) {
      return moment(date).format("dddd, D MMMM YYYY");
    },
    toFromString(trip) {
      return toFromString(trip, this.$store.getters.getConfigs);
    },
    timeFormat(time) {
      let format = DT_FORMATS.time12hr;
      if (configEnabled("Use 24 hour clock", this.$store.getters.getConfigs)) {
        format = DT_FORMATS.time24hr;
      }
      return moment(time).format(format);
    },
    decimalCurrency(number) {
      const small = number.toString();
      return (
        small.substring(0, small.length - 2) +
        "." +
        small.substring(small.length - 2)
      );
    },
    isBeforeNow(time) {
      return moment(moment()).isBefore(time);
    },
    goToTrip(trip) {
      const date = moment(trip.fromTime).format("YYYY-MM-DD");
      return `/admin?date=${date}&trip=${trip.id}`;
    },
    occupiesSeatCount(customers) {
      return customers.reduce(
        (a, b) => a + (b.ageGroup.occupiesSeat ? 1 : 0),
        0
      );
    },
    // allowBooking(booking) {
    //   this.allowingBooking = true
    //   this.allowingBookingId = booking.id
    //   const data = {
    //     bookingId: booking.id,
    //   }
    //   axios({
    //     url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/allowbooking`,
    //     method: "POST",
    //     headers: { Authorization: localStorage.getItem("Authorization") },
    //     data: JSON.stringify(data),
    //   })
    //     .then((resp) => {
    //       this.message(resp.data.message)
    //       this.getBookings()
    //       this.getTrips()
    //       this.getBooking()
    //     })
    //     .catch((error) => {
    //       if (error.response) {
    //         this.errorMessage(error.response.data.error)
    //       }
    //     })
    //     .finally(() => {
    //       this.allowingBooking = false
    //       this.allowingBookingId = -1
    //     })
    // },
    rejectPrivateTransfer() {
      this.acceptingOrRejecting = true;
      const data = {
        privateTransferId: this.privateTransfer.id,
      };
      axios({
        url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/rejectprivatetransfer`,
        method: "POST",
        headers: { Authorization: localStorage.getItem("Authorization") },
        data: JSON.stringify(data),
      })
        .then((resp) => {
          this.message(resp.data.message);
          this.getPrivateTransfers();
        })
        .catch((error) => {
          if (error.response) {
            this.errorMessage(error.response.data.error);
          }
        })
        .finally(() => {
          this.acceptingOrRejecting = false;
        });
    },
    // proceedCashPayment(booking, status) {
    //   this.proceedingCashPayment = true
    //   this.proceedingCashPaymentId = booking.id
    //   const data = {
    //     bookingId: booking.id,
    //     status,
    //   }
    //   axios({
    //     url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/proceedcashpayment`,
    //     method: "POST",
    //     headers: { Authorization: localStorage.getItem("Authorization") },
    //     data: JSON.stringify(data),
    //   })
    //     .then((resp) => {
    //       this.message(resp.data.message)
    //       this.getBookings()
    //       this.getTrips()
    //       this.getBooking()
    //     })
    //     .catch((error) => {
    //       if (error.response) {
    //         this.errorMessage(error.response.data.error)
    //       }
    //     })
    //     .finally(() => {
    //       this.proceedingCashPayment = false
    //       this.proceedingCashPaymentId = -1
    //     })
    // },
    // cancelAllowBooking(booking) {
    //   this.cancellingAllow = true
    //   this.cancellingBookingId = booking.id
    //   const data = {
    //     bookingId: booking.id,
    //   }
    //   axios({
    //     url: `${process.env.VUE_APP_BASE_URL ?? ""}/api/cancelallowbooking`,
    //     method: "POST",
    //     headers: { Authorization: localStorage.getItem("Authorization") },
    //     data: JSON.stringify(data),
    //   })
    //     .then((resp) => {
    //       this.message(resp.data.message)
    //       this.getBookings()
    //       this.getTrips()
    //       this.getBooking()
    //     })
    //     .catch((error) => {
    //       if (error.response) {
    //         this.errorMessage(error.response.data.error)
    //       }
    //     })
    //     .finally(() => {
    //       this.cancellingAllow = false
    //       this.cancellingBookingId = -1
    //     })
    // },
  },
  watch: {
    dialog: {
      handler: function (val) {
        // if (val) this.getBooking()
      },
    },
  },
};
</script>
